import React, { useContext, useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  DropdownMenu,
  DropdownToggle,

  UncontrolledDropdown,
  Modal,
  ModalBody,
  DropdownItem,
  Form,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
  PaginationComponent,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  TooltipComponent,
  RSelect,
} from "../../../components/Component";
import { filterRole, filterStatus, userData } from "./UserData";
import { bulkActionOptions, findUpper } from "../../../utils/Utils";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { UserContext } from "./UserContext";


import { useHistory } from "react-router-dom";
import { apiRequest, extractKey } from "../../../helpers/api";
import { generateSignature, decryptToken } from "../../../helpers/signature";
import { ALGORITHM_TYPE, EVVOPAY_PRIVATE_KEY, MERCHANT_MODULE, USER_MODULE } from "../../../constants";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Loader from "../../../helpers/Loader";
import { Spinner } from "react-bootstrap";
import { convertDateFormate, getAccessStatus } from '../../../helpers/common';
import ReactSelect from "react-select";

const UserListRegularPage = () => {

  const userDetails = JSON.parse(decryptToken(localStorage.getItem('accessToken')))
  const history = useHistory();
  const { contextData } = useContext(UserContext);
  const [data, setData] = contextData;
  const [orgData, setOrgData] = useState([]);

  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [editId, setEditedId] = useState();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    balance: "",
    phone: "",
    status: "Active",
  });
  const [actionText, setActionText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");
  const [userType, setUserType] = useState("");
  const [usersCount, setUsersCount] = useState(0);
  const [userData, setUserData] = useState(userDetails || null);
  const [status, setStatus] = useState("");
  const [filter, setFilter] = useState("");
  const [resetFilter, setResetFilter] = useState("");
  const [paymentProcessorFilter, setPaymentProcessorFilter] = useState("");
  const [merchantFilter, setMerchantFilter] = useState("");
  const [userRolesData, setUserRolesData] = useState([]);
  const [paymentProcessorArr, setPaymentProcessorArr] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filterStatusData, setFilterStatusData] = useState({
    roleStatus: "",
    userStatus: "",
  });
  const [writeStatus, setWriteStatus] = useState(true);
  const [readStatus, setReadStatus] = useState(true);
  const [deleteStatus, setDeleteStatus] = useState(true);
  const [merchantIds, setMerchantIds] = useState([]);
  const [merchantName, setMerchantName] = useState('');
  const [userID, setUserId] = useState('');
  const [defaultMerchant, setDefaultMerchant] = useState('');

  // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
    console.log("defaultData>>>", defaultData)
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.merchant_name.localeCompare(b.merchant_name));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.merchant_name.localeCompare(a.merchant_name));
      setData([...sortedData]);
    }
  };

  // fetching user records
  const getUserRecords = async () => {

    let userid
    if (userDetails.user_role === 'SUPERADMIN') {
      userid = userID
    } else {
      userid = userData.user_id
    }

    let data = {
      "userid": userid
    }
    let signature = generateSignature(data)

    if (data.userid) {

      try {
        let response = await apiRequest('post', `get-sub-user-list`, signature, data)
        if (response.status == 200 || response.status == 201) {
          //console.log("response.data.data.data>>>",response.data.data.data)
          //  let newData=[]
          if (response.data.data.data.length > 0) {
            setUsersCount(response.data.data.data.length)
            let modifiedData = await convertDateFormate(response.data.data.data)
            setData(modifiedData);
            setOrgData(modifiedData)
          }
          else {
            setUsersCount(0)
            setData([]);
            setOrgData([])
          }
          // const modifiedData = await convertDateFormate(newData)
          // setOrgData(modifiedData)
        }
        else {
          setUsersCount(0)
          setData([]);
        }
      }
      catch (error) {
        console.error('Error fetching data:', error);
      }
      finally {
        setIsLoading(false);
      }

    } else {
      setIsLoading(false);
    }
  }

  const fetchMerchantIds = async () => {
    let obj = { filter: 'all' };
    let signature = generateSignature(obj);
    try {
      if (signature) {
        const response = await apiRequest('get', `get-merchant-ids?filter=${obj.filter}`, signature);

        if (response.status === 200 || response.status === 201) {
          const responseData = response?.data?.data?.data;
          if (responseData) {
            const filterData = responseData.map((item) => ({
              value: item.merchant_id,
              label: item.merchant_name || item.merchant_id,
              id: item.id,
              userId: item.user_id
            }));


            // const userIds = filterData
            //   .filter(item => item.userId !== "") // Filter out objects with non-empty userId
            //   .map(item => item.userId); // Extract userId values

            // // console.log(userIds, '-<, userIds');
            // setDefaultUserId(userIds[0])
            const userWithUserId = filterData.find(item => item.userId !== '');

            const selectedUser = userWithUserId || data[0];
            setDefaultMerchant(selectedUser)

            setMerchantIds(filterData);
            setMerchantName(filterData);
          } else {
            setMerchantIds([]);
            setMerchantName([]);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching merchant IDs:', error);
    }
  };


  // console.log(isLoading, '-<, isLoading')
  // unselects the data on mount
  useEffect(async () => {
    await fetchMerchantIds()

    //  // setUserData(userDetails)
    //  console.log("userData>>",userData)
    //s console.log("userData>>>",userData)
    //let readStatus=getAccessStatus(userData,REPORTS_MODULE)
    let { readStatus, writeStatus, deleteStatus } = getAccessStatus(userData, USER_MODULE)
    if (!readStatus) {
      history.push(`${process.env.PUBLIC_URL}/errors/504-classic`);
    }
    setReadStatus(readStatus)
    setWriteStatus(writeStatus)
    setDeleteStatus(deleteStatus)

    //console.log("status>>>",readStatus,writeStatus,deleteStatus)
    // let readStatus=getAccessStatus(userData,REPORTS_MODULE)
    //console.log("accessStatus>>>",accessStatus)
    // if(!accessStatus){
    //   history.push(`${process.env.PUBLIC_URL}/errors/504-classic`);
    // }
    setUserType(userData.user_role)

    await getUserRoles()
    await getUserRecords()


  }, [userID]); // eslint-disable-line react-hooks/exhaustive-deps


  const getUserRoles = async () => {

    let data = {
      "type": "open"
    }
    let signature = generateSignature(data)

    let response = await apiRequest('get', `get-user-roles?type=open`, signature)
    //    console.log("responsesetPaymentProcessorData>>>",response.data.data.data)
    if (response.status == 200 || response.status == 201) {

      if (response.data.data.data.length > 0) {
        let filterData = response.data.data.data.map((item) => {

          //  console.log("item.user_role.toLowerCase()>>>,",item.user_role.toLowerCase())
          // if(item.user_role.toLowerCase()!='superadmin')
          return { 'value': item.id, 'label': item.user_role.toUpperCase() }
        })
        setUserRolesData(filterData)
      }


      else
        setUserRolesData([])

    }
    else {
      setUserRolesData([])
      return []

    }

  }
  // Changing state value when searching name
  useEffect(() => {

    if (onSearchText !== "") {
      const filteredObject = data.filter((item) => {

        return (
          (item.name && item.name.toLowerCase().includes(onSearchText.toLowerCase())) ||
          (item.email_id && item.email_id.toLowerCase().includes(onSearchText.toLowerCase())) ||
          (item.phone_no && item.phone_no.includes(onSearchText))
        )

        // if(userType=='SUPERADMIN'){
        //     return (
        //       (item.merchant_name &&  item.merchant_name.toLowerCase().includes(onSearchText.toLowerCase()) )||
        //       (item.merchant_id && item.merchant_id.toLowerCase().includes(onSearchText.toLowerCase())) ||
        //       (item.mcc_code && item.mcc_code.toLowerCase().includes(onSearchText.toLowerCase()))
        //     )
        // }
        // else{
        //   return (
        //     (item.merchant_name && item.merchant_name.toLowerCase().includes(onSearchText.toLowerCase())) ||
        //     (item.merchant_id && item.merchant_id.toLowerCase().includes(onSearchText.toLowerCase()))
        //   )
        // }


      });
      setData([...filteredObject]);
    } else {
      // await getUserRecords()
      setData([...orgData]);
    }
  }, [onSearchText, setData]);

  useEffect(() => {

    if (resetFilter === 'reset' || filter === 'all') {
      setData([...orgData]);
      setResetFilter(""); // Reset the resetFilter value after using it
      setFilter("");
      return; // Exit early to prevent further execution
    }

    if (filter !== "") {
      //console.log('here in filter', orgData)
      const filteredObject = orgData.filter((item) => {


        if (item.status != '' && item.status != null && item.role != '' && item.role != null) {
          if (filter.userStatus != "" && filter.roleStatus != "") {
            //  console.log("flag-1")
            return (
              item.status.toLowerCase() ==
              filter.userStatus.toLowerCase() &&

              item.role.toLowerCase() ==
              filter.roleStatus.toLowerCase()

            );
          } else if (filter.userStatus != "") {
            //  console.log("flag-2")
            return (
              item.status.toLowerCase() ==
              filter.userStatus.toLowerCase()
            )
            // (item.settlementStatus && item.settlementStatus.toLowerCase().includes(filter.settlementStatus.toLowerCase()))
            // )
          } else if (filter.roleStatus != "") {
            return (
              item.role.toLowerCase() ==
              filter.roleStatus.toLowerCase()
            )
          }
        }
      });
      setData([...filteredObject]);
    } else {
      setData([...orgData]);
    }
    //getPaymentProcessData();
  }, [filter, resetFilter, orgData, setData, userType]);

  // function to set the action to be taken in table header
  const onActionText = (e) => {
    setActionText(e.value);
  };

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };


  const handleFilter = (e) => {
    //  console.log("e>>",e)
    setFilter(e);
  };

  const handleResetFilter = (ev) => {
    ev.preventDefault();
    setResetFilter('reset');
  };

  const filterByPaymentProcessor = () => {
    // setFilter(paymentProcessorFilter.toLowerCase());
    setUserId(merchantFilter);
  };

  // const handleStatusChange = (e) => {
  //   setStatus(e.value);
  // };
  const handleStatusChange = (status, statusType) => {
    statusType == "us"
      ? setFilterStatusData({
        ...filterStatusData,
        userStatus: status.value,
      })
      : setFilterStatusData({
        ...filterStatusData,
        roleStatus: status.label,
      });
  };

  // function to change the selected property of an item
  const onSelectChange = (e, id) => {
    let newData = data;
    let index = newData.findIndex((item) => item.id === id);
    newData[index].checked = e.currentTarget.checked;
    setData([...newData]);
  };

  // function to reset the form
  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      balance: "",
      phone: "",
      status: "Active",
    });
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    resetForm();
  };

  // submit function to add a new item
  const onFormSubmit = (submitData) => {
    const { name, email, balance, phone } = submitData;
    let submittedData = {
      id: data.length + 1,
      avatarBg: "purple",
      name: name,
      role: "Customer",
      email: email,
      balance: balance,
      phone: phone,
      emailStatus: "success",
      kycStatus: "alert",
      lastLogin: "10 Feb 2020",
      status: formData.status,
      country: "Bangladesh",
    };
    setData([submittedData, ...data]);
    resetForm();
    setModal({ edit: false }, { add: false });
  };

  // submit function to update a new item
  const onEditSubmit = (submitData) => {
    const { name, email, phone } = submitData;
    let submittedData;
    let newitems = data;
    newitems.forEach((item) => {
      if (item.id === editId) {
        submittedData = {
          id: item.id,
          avatarBg: item.avatarBg,
          name: name,
          image: item.image,
          role: item.role,
          email: email,
          balance: formData.balance,
          phone: "+" + phone,
          emailStatus: item.emailStatus,
          kycStatus: item.kycStatus,
          lastLogin: item.lastLogin,
          status: formData.status,
          country: item.country,
        };
      }
    });
    let index = newitems.findIndex((item) => item.id === editId);
    newitems[index] = submittedData;
    setModal({ edit: false });
    resetForm();
  };

  // function that loads the want to editted data
  const onEditClick = (id, user_merchant_mapping_id) => {

    // history.push({
    //   `${process.env.PUBLIC_URL}/merchant-profile`
    // })
    console.log(id)
    history.push({
      pathname: `${process.env.PUBLIC_URL}/update-user`,
      state: { userid: id, user_merchant_mapping_id: user_merchant_mapping_id },
    });

  };

  // function to change to suspend or delete any record 
  const suspendUser = async (user_merchant_mapping_id, id) => {

    //let userid=userData.user_id;

    //  let signature="AorFkeqCC4827Q5slcmjx6ZxU15/j3GFhPMTt4xFYi/M7ahNIFHajyH6tQ+MeuTotH+qjwldqB8PVbCav4BbIH8Y5EJC+FLwIpWCpDXjhzAHZoRt6X2w84svIrIMssP+kEyk60PKfQX3CDXO5/YBlZM1zcrviiFlIejvH1EOCOg67MZwD/1W5/I5wNLq1X5C2pwgCKmAwlg5RLI9wfwUCIVJpBbDtx9x8PnPPYTe9GQkwcj6lndeKvRgqkr+SDNz3jcC99U5RcXjFVOe5bVcm+10+JY3SZfeThpJSWf/WaJV9c+VClj3dRJ+FZ5hKBmtWM35oA209h128aFT4NXOJXtLIr8GBvFCq9I1zr/n6UwN99+42Y18Jm/qR+Qq/5vbUPjHYt0GN2xb8fNtdmvsYMj0Wjz9TPbhEmNSEwbW3F3VXEu8bTUEU/aNJcnSeY8zawAwH12a6plEtOVKv3lgbPTwXHiGVVluRthc7TL3Qq5m11XUBvrmIPMkR5kvBPvgCaK7vvPW5d9nfwdLKfqT9Gg2tN6EwVFSCNvfhJjF6GSOmMwYuyHiWLmlpCupkiQeVHLDV7Q9ieVIs/fMh0oGAxeV57KsCbdlh7Zv4x5ra0R76EibGunZFa2Q6vsi5TVUSqvmaETu/UlzDHQOLhZsP/ID1W08MnnSVjvbHK5oWRI="
    let data = {
      "userid": id,
      "user_merchant_mapping_id": user_merchant_mapping_id
    }
    let signature = generateSignature(data)
    let response

    try {

      response = await apiRequest('post', `delete-sub-user`, signature, data)


      //  console.log("response>>>",response.data)
      if (response.status == 200 || response.status == 201) {

        await getUserRecords()
      }
      else {
        // return []
        console.log("error.data>>>", response)
      }
    }
    catch (error) {
      console.log("error.data>>>", error)
    }

  };



  // function to change the check property of an item
  const selectorCheck = (e) => {
    let newData;
    newData = data.map((item) => {
      item.checked = e.currentTarget.checked;
      return item;
    });
    setData([...newData]);
  };

  // function which fires on applying selected action
  const onActionClick = (e) => {
    if (actionText === "suspend") {
      let newData = data.map((item) => {
        if (item.checked === true) item.status = "Suspend";
        return item;
      });
      setData([...newData]);
    } else if (actionText === "delete") {
      let newData;
      newData = data.filter((item) => item.checked !== true);
      setData([...newData]);
    }
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;



  const currentItems = data.length > 0 ? data.slice(indexOfFirstItem, indexOfLastItem) : []


  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  }

  const { errors, register, handleSubmit } = useForm();

  const goToAddUser = () => {
    history.push(`${process.env.PUBLIC_URL}/add-user`)
  }

  const handleDownload = (data) => {
    //  console.log(data, '-<, inside data ')
    const modifiedData = data.map((item) => {

      let status = item.is_active ? 'Active' : 'Suspend'

      return {
        Name: item.name,
        Email: item.email_id,
        PhoneNumber: item.phone_no,
        Role: item.role,
        RegisterDate: item.register_date,
        Status: item.status
      };
    });

    // Convert data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(modifiedData);

    // Create workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate Excel file buffer
    const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });

    // Save the file using FileSaver.js
    const fileData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(fileData, 'Users List Sheet.xlsx');
  };

  const handleMerchantChange = (selectedUserId) => {
    setUserId(selectedUserId);
  };


  return (
    <React.Fragment>
      <Head title="User List - Regular"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                {
                  usersCount == 1 ? 'User Record' : 'User Lists'
                }

              </BlockTitle>

              {
                usersCount != 1 ?
                  <BlockDes className="text-soft">
                    <p>You have total {usersCount} users.</p>
                  </BlockDes>
                  : null
              }
              {/* <BlockDes className="text-soft">
                <p>You have total {usersCount} users.</p>
              </BlockDes> */}
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                {/* {
                  userData !=null && userData.user_role!='ADMIN'? */}
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    {
                      userData != null ?
                        <li>
                          {/* <Button color="light" outline className="btn-white"> */}
                          <Button color="light" outline className="btn-white" onClick={() => handleDownload(data)} >
                            <Icon name="download-cloud"></Icon>
                            <span>Export</span>
                          </Button>
                        </li>
                        :
                        null
                    }


                    {
                      writeStatus && (
                        <li className="nk-block-tools-opt">
                          <Button style={{ padding: "6px" }} color="primary" className="btn-icon" onClick={() => goToAddUser()}>

                            Add User

                          </Button>
                        </li>
                      )
                    }
                    {/* <li className="nk-block-tools-opt">
                      <Button  style={{padding:"6px"}} color="primary" className="btn-icon" onClick={() => goToAddUser()}>
                       
                        Add User
                        
                      </Button>
                    </li> */}
                  </ul>
                </div>

              </div >
            </BlockHeadContent >
          </BlockBetween >
        </BlockHead >

        <Block>
          <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle">

              <div className="card-title-group ">
                <div className="card-tools">
                  {
                    // userDetails.user_role === 'SUPERADMIN' && (
                    userDetails.user_role == 'SUPERADMIN' ?
                      <div className="form-inline flex-nowrap gx-3">
                        <div className="form-wrap">
                          <RSelect
                            options={merchantName}
                            className="w-130px"
                            placeholder="Select Merchant"
                            onChange={(selectedOption) => {
                              //console.log(selectedOption, '-<, selectionOptions')
                              setMerchantFilter(selectedOption.userId);
                            }}
                          />
                        </div>
                        <div className="btn-wrap">
                          <span className="d-none d-md-block">
                            <Button
                              color="light"
                              outline
                              className="btn-dim"
                              onClick={filterByPaymentProcessor}
                            >
                              Apply
                            </Button>
                          </span>
                          <span className="d-md-none">
                            <Button
                              color="light"
                              outline
                              disabled={actionText !== "" ? false : true}
                              className="btn-dim btn-icon"
                              onClick={(e) => onActionClick(e)}
                            >
                              <Icon name="arrow-right"></Icon>
                            </Button>
                          </span>
                        </div>
                      </div>
                      :
                      null
                  }
                </div>
                {/* // )} */}

                <div className="card-tools me-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                                <Icon name="arrow-left"></Icon>
                              </Button>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="a"
                                  className="btn btn-trigger btn-icon dropdown-toggle"
                                >
                                  <div className="dot dot-primary"></div>
                                  <Icon name="filter-alt"></Icon>
                                </DropdownToggle>
                                <DropdownMenu
                                  end
                                  className="filter-wg dropdown-menu-xl"
                                  style={{ overflow: "visible" }}
                                >
                                  <div className="dropdown-head">
                                    <span className="sub-title dropdown-title">
                                      Filter Users
                                    </span>
                                    <div className="dropdown">
                                      <a
                                        href="#more"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                        className="btn btn-sm btn-icon"
                                      >
                                        <Icon name="more-h"></Icon>
                                      </a>
                                    </div>
                                  </div>
                                  <div className="dropdown-body dropdown-body-rg">
                                    <Row className="gx-2 gy-2">

                                      <Col size="6">
                                        <div className="form-group">
                                          <label className="overline-title overline-title-alt">
                                            Status
                                          </label>
                                          <RSelect
                                            options={filterStatus}
                                            placeholder="Any Status"
                                            onChange={(status) =>
                                              handleStatusChange(status, "us")
                                            }
                                          />
                                        </div>
                                      </Col>

                                      <Col size="6">
                                        <div className="form-group">
                                          <label className="overline-title overline-title-alt">
                                            User Role
                                          </label>
                                          <RSelect
                                            options={userRolesData}
                                            placeholder="Any Status"
                                            // onChange={handleStatusChange}
                                            onChange={(status) =>
                                              handleStatusChange(status, "ur")
                                            }
                                          />
                                        </div>
                                      </Col>

                                      {/* <Col size="6">
                                        <div className="form-group">
                                          <label className="overline-title overline-title-alt">
                                            Transaction Status
                                          </label>
                                          <RSelect
                                            options={transactionStatusFilter}
                                            placeholder="Any Status"
                                            // onChange={handleStatusChange}
                                            onChange={(status) =>
                                              handleStatusChange(status, "ts")
                                            }
                                          />
                                        </div>
                                      </Col> */}
                                      <Col size="12">
                                        <div className="form-group">
                                          <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={() =>
                                              handleFilter(filterStatusData)
                                            }
                                          >
                                            Filter
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="dropdown-foot between">
                                    <a
                                      href="#reset"
                                      // onClick={(ev) => {
                                      //   ev.preventDefault();
                                      // }}
                                      onClick={handleResetFilter}
                                      className="clickable"
                                    >
                                      Reset Filter
                                    </a>
                                    {/* <a
                                      href="#save"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      Save Filter
                                    </a> */}
                                  </div>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle color="tranparent" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <Icon name="setting"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end className="dropdown-menu-xs">
                                  <ul className="link-check">
                                    <li>
                                      <span>Show</span>
                                    </li>
                                    <li className={itemPerPage === 10 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(10);
                                        }}
                                      >
                                        10
                                      </DropdownItem>
                                    </li>
                                    <li className={itemPerPage === 15 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(15);
                                        }}
                                      >
                                        15
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                  <ul className="link-check">
                                    <li>
                                      <span>Order</span>
                                    </li>
                                    <li className={sort === "dsc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("dsc");
                                          sortFunc("dsc");
                                        }}
                                      >
                                        DESC
                                      </DropdownItem>
                                    </li>
                                    <li className={sort === "asc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("asc");
                                          sortFunc("asc");
                                        }}
                                      >
                                        ASC
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>

                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by name,phone no or email"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <DataTableBody>
              <DataTableHead>
                <DataTableRow>
                  <span className="sub-text">Name</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Email</span>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">Phone no</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Role</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Register Date</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Status</span>
                </DataTableRow>

              </DataTableHead>
              {/*Head*/}

              {/* {console.log("currentItems>>>",currentItems)} */}
              {currentItems.length > 0
                ? currentItems.map((item) => {
                  return (
                    <DataTableItem key={item.id}>
                      <DataTableRow>
                        <span>{item.name}</span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span>{item.email_id}</span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span>{item.phone_no}</span>
                      </DataTableRow>

                      {/* <DataTableRow size="md">
                          <span>{item.paymentProcessorName}</span>
                        </DataTableRow> */}

                      <DataTableRow size="md">
                        <span>{item.role}</span>
                      </DataTableRow>

                      <DataTableRow size="md">
                        <span>{item.register_date}</span>
                      </DataTableRow>

                      <DataTableRow size="md">
                        <span
                          className={`tb-status text-${item.status === "Active" ? "success" : item.status === "Pending" ? "warning" : "danger"
                            }`}
                        >
                          {item.status}
                          {/* {item.is_active} */}
                        </span>
                      </DataTableRow>

                      {/* {
                          userType=='SUPERADMIN'? */}

                      {/* //   :
                        //   null
                        // } */}
                      <DataTableRow className="nk-tb-col-tools">
                        <ul className="nk-tb-actions gx-1">
                          {item.status !== 'Pending' && (
                            <li className="nk-tb-action-hidden" onClick={() => onEditClick(item.id, item.user_merchant_mapping_id)}>

                            </li>

                          )}


                          {item.status !== 'Pending' && item.role != 'OWNER' && (

                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end>
                                  <ul className="link-list-opt no-bdr">

                                    <li onClick={() => onEditClick(item.id, item.user_merchant_mapping_id)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="edit"></Icon>
                                        <span>Edit User</span>
                                      </DropdownItem>
                                    </li>
                                    {/* {console.log("item>>>",item)} */}
                                    {item.status !== "Suspend" && item.status !== "Pending" && deleteStatus && (
                                      <React.Fragment>
                                        <li className="divider"></li>
                                        <li onClick={() => suspendUser(item.user_merchant_mapping_id, item.id)}>
                                          <DropdownItem
                                            tag="a"
                                            href="#suspend"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                          >
                                            <Icon name="na"></Icon>
                                            <span>Suspend User</span>
                                          </DropdownItem>
                                        </li>
                                      </React.Fragment>
                                    )}
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>

                          )}

                        </ul>
                      </DataTableRow>
                    </DataTableItem>
                  );
                })
                : null}
            </DataTableBody>
            {/* <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={data.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div> */}
            <div className="card-inner">
              {isLoading ? (
                // <div className="text-center">
                //   <span className="text-silent">Loading...</span>
                // </div>
                <Loader />
                //   <MDBSpinner role='status'>
                //   <span className='visually-hidden'>Loading...</span>
                // </MDBSpinner>
                //   <div className="text-center">
                //   <Spinner animation="border" variant="primary" role="status" />
                //   <div className="mt-2">Processing</div>
                // </div>

              ) : currentItems.length > 0 ? (
                <React.Fragment>
                  {/* Your rendered data */}
                  <PaginationComponent
                    itemPerPage={itemPerPage}
                    totalItems={data.length}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                </React.Fragment>
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>
        <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#close"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Add User</h5>
              <div className="mt-4">
                <Form className="row gy-4" noValidate onSubmit={handleSubmit(onFormSubmit)}>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={formData.name}
                        placeholder="Enter name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Email </label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        defaultValue={formData.email}
                        placeholder="Enter email"
                        ref={register({
                          required: "This field is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                      />
                      {errors.email && <span className="invalid">{errors.email.message}</span>}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Balance</label>
                      <input
                        className="form-control"
                        type="number"
                        name="balance"
                        defaultValue={formData.balance}
                        placeholder="Balance"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.balance && <span className="invalid">{errors.balance.message}</span>}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Phone</label>
                      <input
                        className="form-control"
                        type="number"
                        name="phone"
                        defaultValue={formData.phone}
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.phone && <span className="invalid">{errors.phone.message}</span>}
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="form-group">
                      <label className="form-label">Status</label>
                      <div className="form-control-wrap">
                        <RSelect
                          options={filterStatus}
                          defaultValue={{ value: "Active", label: "Active" }}
                          onChange={(e) => setFormData({ ...formData, status: e.value })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Add User
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Update User</h5>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={handleSubmit(onEditSubmit)}>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={formData.name}
                        placeholder="Enter name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Email</label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        defaultValue={formData.email}
                        placeholder="Enter email"
                        ref={register({
                          required: "This field is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                      />
                      {errors.email && <span className="invalid">{errors.email.message}</span>}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Balance</label>
                      <input
                        className="form-control"
                        type="number"
                        name="balance"
                        disabled
                        //    defaultValue={parseFloat(formData.balance.replace(/,/g, ""))}
                        placeholder="Balance"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.balance && <span className="invalid">{errors.balance.message}</span>}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Phone</label>
                      <input
                        className="form-control"
                        type="number"
                        name="phone"
                        // defaultValue={Number(formData.phone)}
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.phone && <span className="invalid">{errors.phone.message}</span>}
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="form-group">
                      <label className="form-label">Status</label>
                      <div className="form-control-wrap">
                        <RSelect
                          options={filterStatus}
                        // defaultValue={{
                        //   value: formData.status,
                        //   label: formData.status,
                        // }}
                        //onChange={(e) => setFormData({ ...formData, status: e.value })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Update User
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content >
    </React.Fragment >
  );
};
export default UserListRegularPage;
