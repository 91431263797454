import React, {useState, useEffect, useContext } from "react";
import Logo from "../../images/evvopay_logo.png";
import LogoDark from "../../images/logo-dark.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Button, PreviewCard } from "../../components/Component";
import { Link } from "react-router-dom";
import { apiRequest } from "../../helpers/api";
import { generateSignature } from "../../helpers/signature";
import { Form, Spinner, Alert } from "reactstrap";
import {
  Icon
} from "../../components/Component";
import { useHistory } from "react-router-dom";
import { EmailContext } from '../../components/Context';

const ForgotPassword = () => {
  const [errorVal, setError] = useState("");
  const [Email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { setContextEmail } = useContext(EmailContext);
  const history = useHistory();

  const SearchMovies = async () => {
   // console.log(Email, '-<, title>>>>>>>.')
    let forgotPasswordForm = {
      emailid : Email
    }
    let signature=generateSignature(forgotPasswordForm)

    let response=await apiRequest('post','reset-password',signature,forgotPasswordForm)
    console.log(response, '-<, response')
    console.log(response?.data, '-<, response')

    
    setContextEmail(forgotPasswordForm.emailid);

    if(response.status==200 || response.status==201){
      console.log('success')
    setSuccessMessage(response?.data?.message);
    setError("")
    setTimeout(() => history.push(`${process.env.PUBLIC_URL}/update-password`,{ 'emailid': forgotPasswordForm.emailid}), 2000);
      }
      else if(response.status==500){
      setTimeout(() => {
        setError("Something went wrong,Kindly try again later")
      }, 2000);
      setSuccessMessage("");

    }else if(response.status==400){
      if(Array.isArray(response.data.data)){
        let errorMsgList=[]
        response.data.data.map((data)=>{
          errorMsgList.push(data.message)
        })
        setError(errorMsgList)
        console.log("fieldErrorMsg>>>",errorMsgList)
      }
      else{
        setError(response.data.data.data)
      }
      setSuccessMessage("");
    }else if(response.status==401){
      setError(response.data.message)
      setSuccessMessage("");
    } else{
      setError('Something went wrong,Kindly try again later')
      setSuccessMessage("");
    }
    
    // setEmail();
}

  return (
    <React.Fragment>
      <Head title="Forgot-Password" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={Logo} alt="logo-dark" />
            </Link>
            {/* <h1>Evvopay Admin</h1> */}
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h5">Reset password</BlockTitle>
                <BlockDes>
                  <p>If you forgot your password, well, then we’ll email you instructions to reset your password.</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {errorVal}
                </Alert>
              </div>
            )}
            <form onSubmit={(e)=>{e.preventDefault()}}>
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Email
                  </label>
                </div>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="default-01"
                  placeholder="Enter your email address"
                  value= {Email} 
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p style={{color:"green"}}>{successMessage||null}</p>
              </div>
              <div className="form-group">
                {/* <Button color="primary" size="lg" className="btn-block" onClick={(ev) => ev.preventDefault()}> */}
                <Button color="primary" size="lg" className="btn-block" onClick={() => SearchMovies()}>
                  Send Reset Link
                </Button>
              </div>
            </form>
            <div className="form-note-s2 text-center pt-4">
              <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
                <strong>Return to login</strong>
              </Link>
            </div>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default ForgotPassword;
