import React from "react";
import UserAvatar from "../../../user/UserAvatar";
import Icon from "../../../icon/Icon";
import { newUserData } from "./UserData";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown, CardTitle, DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";
import { activityData } from "../recent-activity/ActivityData";

const NewsUsers = (props) => {
  const { voidTransactions } = props;
  
  const DropdownTrans = () => {
    // return (
    //   <UncontrolledDropdown>
    //     <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger me-n1">
    //       <Icon name="more-h"></Icon>
    //     </DropdownToggle>
    //     <DropdownMenu end>
    //       <ul className="link-list-opt no-bdr">
    //         <li>
    //           <DropdownItem
    //             tag="a"
    //             href="#dropdownitem"
    //             onClick={(ev) => {
    //               ev.preventDefault();
    //             }}
    //           >
    //             <Icon name="setting"></Icon>
    //             <span>Action Settings</span>
    //           </DropdownItem>
    //         </li>
    //         <li>
    //           <DropdownItem
    //             tag="a"
    //             href="#dropdownitem"
    //             onClick={(ev) => {
    //               ev.preventDefault();
    //             }}
    //           >
    //             <Icon name="notify"></Icon>
    //             <span>Use Notification</span>
    //           </DropdownItem>
    //         </li>
    //       </ul>
    //     </DropdownMenu>
    //   </UncontrolledDropdown>
    // );
  };
  return (
    <div className="card-inner-group">
      <div className="card-inner">
        <div className="card-title-group">
          <CardTitle>
            <h6 className="title">Payment Cancel</h6>
          </CardTitle>
          {/* <div className="card-tools">
            <Link to={`${process.env.PUBLIC_URL}/user-list-regular`} className="link">
              View All
            </Link>
          </div> */}
        </div>
      </div>
      { voidTransactions!==null ?
       (
         voidTransactions.length > 0 ? (
      voidTransactions.slice(0, 5).map((item, idx) => {
        return (
          <div className="card-inner card-inner-md" key={idx}>
            <div className="user-card">
              <UserAvatar theme={item.theme} text={item.initial}></UserAvatar>
              {/* <div className="user-info">
                <span className="lead-text">{item.name}</span>
                <span className="sub-text">{item.email}</span>
              </div> */}
              <div className="nk-activity-data">
                    <div className="label"> <strong>Transaction ID : {item.orderid}</strong> </div>
                    <div className="label"> <strong>Transaction Amount : {item.amount ? item.amount : 0} {item.currency !== null ? item.currency : 'SGD'}</strong> </div>
                    <div className="label"> <strong>Transaction Date : </strong> { item.date}</div>
                    <div className="label"> <strong>Message : </strong> { item.message}</div>
                  </div>
              <div className="user-action">
                {/* <DropdownTrans /> */}
              </div>
            </div>
          </div>
        );
      }) 
      ): (
        <div className="card-inner">
          <div className="card-title-group">
            <span>NO RECORDS FOUND.</span>
          </div>
        </div>
      )) : null }
    </div>
  );
};
export default NewsUsers;
