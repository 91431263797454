import React, {useState, useEffect} from "react";
import { DropdownItem, DropdownToggle, DropdownMenu, UncontrolledDropdown } from "reactstrap";
import { Icon, RSelect, Col } from "../../../Component";
import { LineChart } from "../../charts/default/Charts";
import Loader from "../../../../helpers/Loader";

const SalesOverview = (props) => {
    const [selectedOption, setSelectedOption] = useState('');
const [merchantName, setMerchantName] = useState('');
const [filterBymerchantName, setFilterBymerchantName] = useState('');
const [filterByPaymentProcessor, setFilterByPaymentProcessor] = useState('');
const [defaultMerchant,setDefaultMerchant]=useState("")

const { apiCallCount, apiCallSum, message, merchantIds, endpointsCount, userDetails,defaultMerchantData} = props;

useEffect(() => {
  if (merchantIds) {
    let filterData = merchantIds.map((item) => {
      return { value: item.merchant_id, label: item.merchant_name ? item.merchant_name : item.merchant_id, id: item.id,};
    });
   

    //  console.log("filterData>>>",filterData)
    //  console.log("defaultMerchantData>>>",defaultMerchantData)
     if(Object.keys(defaultMerchantData).length>0){
    //  console.log("datathere")
      setDefaultMerchant(defaultMerchantData)
      //setDefaultMerchant(defaultMerchantData.label)
     }
     else{
      //console.log("datanotthere")
     // setDefaultMerchant({'value': 'Select', 'label': 'Select'})
       // setDefaultMerchant('Select2')
       setDefaultMerchant({label: "Select", value: 0 })
     }
    //setDefaultMerchant({'value': 'Select', 'label': 'Select'})
    setMerchantName(filterData);
  } else {
    setMerchantName([]);
  }
  //console.log("loaderValue>>>",loaderValue)
  if(filterBymerchantName){
    // console.log("filterBymerchantName>>>",filterBymerchantName)
    // console.log("filterBymerchantNameStatus>>>",loaderValue)
      // setIsLoaderStatus(true)
   //   setIsLoaderStatus(loaderValue)
   //    props.onTempLoaderValue('1')
        props.onStringChange(filterBymerchantName);
        props.onPaymentProcessorChange(filterByPaymentProcessor)
       
     
  }


}, [merchantIds, filterBymerchantName]); 


// const sendDataToParent = (e) => {
//   props.onStringChange(filterBymerchantName);
//   props.onPaymentProcessorChange(filterByPaymentProcessor)
// };

  return (
    <React.Fragment>
      <div className="card-title-group align-start gx-3 mb-3">
      <Col md="6">
        <div className="card-title">
          <h6 className="title">{message}</h6>
        </div>
        </Col>
        { userDetails.user_role == 'SUPERADMIN' &&(
        <Col md="6">
        <div className="card-tools">
          <Col md="6">
          <div className="form-group">
                      <label className="form-label">Merchants</label>
                      <div className="form-control-wrap">
                    
                        {
                          Object.keys(defaultMerchant).length>0?
                          <RSelect
                          defaultValue={defaultMerchant}
                          options={merchantName}
                          name="payment_processor"
                          onChange={(selectedOption) => {
                            const selectedId = selectedOption ? selectedOption.id : null;
                            const selectedValue = selectedOption ? selectedOption.value : null;
                            const selectedLabel = selectedOption ? selectedOption.label : null;
                          setFilterBymerchantName({ "merchant_mccCode": selectedValue, "merchantId" : selectedId,"merchant_label" : selectedLabel});

                          }}
                        />
                          :
                          null
                        }
                    
                      </div>
                    </div>
                    </Col>
        </div>
        </Col>
        )}
      </div>
      {/* <div className="nk-sale-data-group align-center justify-between gy-3 gx-5">
        <div className="nk-sale-data">
          <span className="amount">{endpointsCount} API's</span>
        </div>
        <div className="nk-sale-data">
          <span className="amount sm">
            {apiCallSum} <small> API hits</small>
          </span>
        </div>
      </div> */}
        {apiCallCount.length === 0 ? (
            <div className="no-records-container">
            <hr className="horizontal-line" />
            <div className="no-records-message">NO RECORDS FOUND.</div>
            </div>
          ):(
              // <div className="nk-sales-ck large pt-4">
              //    <LineChart apiCallCount={apiCallCount}/>
              //  </div>
              <div className="no-records-container">
              <hr className="horizontal-line" />
              {/* <div className="no-records-message">NO RECORDS FOUND.</div> */}
              </div>
            )
        }
      
    </React.Fragment>
  );
};
export default SalesOverview;
