import React, { useState, useEffect, useContext,useRef } from "react";
import Logo from "../../images/evvopay_logo.png";
import LogoDark from "../../images/logo-dark.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Form, Spinner, Alert } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { apiRequest } from "../../helpers/api";
import { useHistory } from "react-router-dom";
import { generateSignature, isVerify, encryptToken, decryptToken } from "../../helpers/signature";
import { ALGORITHM_TYPE, EVVOPAY_API, EVVOPAY_PRIVATE_KEY, EVVOPAY_PUBLIC_KEY } from "../../constants";
import { EmailContext } from '../../components/Context';

const UpdatePassword = () => {
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [errorVal, setError] = useState("");
  const [timer, setTimer] = useState(60); 
  const [successMessage, setSuccessMessage] = useState("");
  const [historyData, setHistoryData] = useState([]);
  const { email } = useContext(EmailContext);

  const history = useHistory();



  const OnFormSubmit = async (formData) => {
    setLoading(true);

  //  console.log("history?.location?.state?.emailid>>>",history?.location?.state?.emailid)
    let resetPasswordFormData = {
      emailid: history?.location?.state?.emailid || formData.email  ,
      otp : formData.name,
      password : formData.passcode
    }

 //   console.log("resetPasswordFormData>>>",resetPasswordFormData)
    let signature=generateSignature(resetPasswordFormData)
    let response = await apiRequest('post', 'reset-password', signature, resetPasswordFormData)

    
    if(response.status==200 || response.status==201){
     // console.log('updated successfully');
      setTimeout(() => history.push(`${process.env.PUBLIC_URL}/auth-login`), 2000);

    } else if(response.status==500){
      setTimeout(() => {
        setError("Something went wrong,Kindly try again later")
        setLoading(false);
      }, 2000);
    }else if(response.status==400){
      setLoading(false);
      if(Array.isArray(response.data.data)){
        let errorMsgList=[]
        response.data.data.map((data)=>{
          errorMsgList.push(data.message)
        })
        setError(errorMsgList)
        console.log("fieldErrorMsg>>>",errorMsgList)
      }
      else{
        setError(response.data.data.data)
      }
    }else if(response.status==401){
      setError(response.data.message)
      setLoading(false);
    } else{
      setLoading(false);
      setError('Something went wrong,Kindly try again later')
    }
    console.log(response, '-<< response >>??')
  };

  const handleResendOTP = async() => {
    // Add your logic here to resend the OTP
    // ...
    // const emailId = history?.location?.state?.emailid;
    // setHistoryData(emailId);
    // console.log(historyData, '-<< historyData', historyData);

    let resendOtpData = {
      emailid: email
      // emailid: "sasikumar@evvoiot.com"
    }
    console.log(resendOtpData, '-<< resendOtpData')
    let signature=generateSignature(resendOtpData)
    let response = await apiRequest('post', 'resend-otp', signature, resendOtpData)

    console.log(response, '-<, response ', response.data.data.data.message)
    
    if(response.status==200 || response.status==201){
    setSuccessMessage(response?.data?.data?.data?.message);
    setError("")

    }else if(response.status==400){
      if(Array.isArray(response?.data?.data)){
        let errorMsgList=[]
        response.data.data.map((data)=>{
          errorMsgList.push(data.message)
        })
        setError(errorMsgList)
        console.log("fieldErrorMsg>>>",errorMsgList)
      }
      else{
        setError(response.data.data.data)
      }
      setSuccessMessage("");
    }else if(response.status==401){
      setError(response.data.message)
      setSuccessMessage("");
    } else{
      setError('Something went wrong,Kindly try again later')
      setSuccessMessage("");
    }
    // Reset the timer back to its initial value
    setTimer(1);
  };


  const { errors, register, handleSubmit,watch } = useForm();
  const password_val =useRef()
  password_val.current=watch('passcode')


  return (
    <React.Fragment>
      <Head title="ResetPassword" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={Logo} alt="logo-dark" />
            </Link>
          {/* <h1>Evvopay Admin</h1> */}
          </div>

          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Reset-Password</BlockTitle>
                <BlockDes>
                  <p>Reset password with OTP.</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {errorVal}
                </Alert>
              </div>
            )}
            <Form className="is-alter" onSubmit={handleSubmit(OnFormSubmit)}>
            <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Email
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    disabled
                    type="text"
                    id="default-01"
                    name="email"
                    tabIndex="1"
                    value= {history?.location?.state?.emailid}
                    // ref={register({ required: "This field is required" })}
                    placeholder="Enter your mail"
                    className="form-control-lg form-control"
                  />
                  {/* {errors.name && <span className="invalid">{errors.name.message}</span>} */}
                </div>
              </div>
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    OTP
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="default-01"
                    name="name"
                    tabIndex="2"
                    ref={register({ required: "This field is required" })}
                    placeholder="Enter your OTP send to your mail."
                    className="form-control-lg form-control"
                  />
                  {errors.name && <span className="invalid">{errors.name.message}</span>}
                </div>
              </div>
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                    Password
                  </label>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassState(!passState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                    <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                  </a>
                  <input
                    type={passState ? "text" : "password"}
                    id="password"
                    name="passcode"
                    tabIndex="3"
                    ref={register({ required: true,
                      pattern: {
                        value: /^(?=.*[0-9])(?=.*[!@#$%^&*.,])[a-zA-Z0-9!@#$%^&*.,]{6,16}$/,
                        message: 'Password must contain atleast 6 characters,One Uppercase,One Lowercase,One Number and One Special Character',
                      }
                    })}
                    
                    // ref={register({ required: "This field is required" })}
                    placeholder="Enter your passcode"
                    className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                  />
                  {/* {errors.passcode && <span className="invalid">{errors.passcode.message}</span>} */}

                  {errors.passcode && errors.passcode.type==='required' && <p className="invalid">The field is required</p>}
                  {errors.passcode && errors.passcode.type==='pattern' && <p className="invalid">{errors.passcode.message}</p>}
                    {/* {console.log("errors>>>",errors)} */}
                </div>
                {
                  Object.keys(errors).length==0 && !errors.password_val ?<p >(Password should be 6 digits and alpha numerical)</p> 
                  :null
                }
              </div>
              <div className="form-group ">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                    Confirm Password
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder="Enter your password"
                    tabIndex="4"
                    className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                    ref={register({
                      required:true,
                      validate: value =>
                        value === password_val.current || ""
                    })}
                    // onChange={handleConfirmPasswordChange}
                    // onBlur={validateConfirmPassword}
                  />
                  {/* {errors.confirmPassword && <p>{errors.confirmPassword.message}</p>} */}
                  {errors.confirmPassword && errors.confirmPassword.type==='required' && <p className="invalid">The field is required</p>}

                  {errors.confirmPassword && errors.confirmPassword.type==='validate' && <p className="invalid">The passoword and confirm password should be same</p>}

                  {/* {errors.confirmPassword && errors.confirmPassword.type==='required' && <p className="invalid">The field is required</p>} */}

                  {/* {confimPasswordErrorMessage && <p>{confimPasswordErrorMessage}</p>} */}
                </div>
              </div>
              <div className="form-group">
                <Button size="lg" className="btn-block" type="submit" color="primary" tabIndex="4">
                  {loading ? <Spinner size="sm" color="light" /> : "Reset Password"}
                </Button>
              </div>
              <div style={{
                            textAlign: 'center',
                            // textDecoration: 'underline',
                          }}>
                {/* {timer === 0 ? (
                  <a href="#" onClick={handleResendOTP}>Resend OTP</a>
                ) : (
                  <div> */}
                  <a href="#" style={{ textAlign: 'center', textDecoration: 'underline', opacity: '1' }} onClick={handleResendOTP}>Resend OTP</a>

                    {/* <p>Resend OTP in: {timer} seconds</p> */}
                    {/* <a href="#" onClick={(e) => e.preventDefault()} style={{ textAlign: 'center', textDecoration: 'underline', opacity: '1' }}>Resend OTP</a> */}
                  {/* </div> */}
                {/* )} */}
                {/* {successMessage && <p>{successMessage}</p>} */}
              </div>
            </Form>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default UpdatePassword;
