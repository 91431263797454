import React, { useContext, useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  DropdownMenu,
  DropdownToggle,
  
  UncontrolledDropdown,
  Modal,
  ModalBody,
  DropdownItem,
  Form,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
  PaginationComponent,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  TooltipComponent,
  RSelect,
} from "../../../components/Component";
import { filterRole, filterStatus, userData } from "./UserData";
import { bulkActionOptions, findUpper } from "../../../utils/Utils";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { UserContext } from "./UserContext";


import { useHistory } from "react-router-dom";
import { apiRequest, extractKey } from "../../../helpers/api";
import { generateSignature, decryptToken } from "../../../helpers/signature";
import { ALGORITHM_TYPE, EVVOPAY_PRIVATE_KEY, MERCHANT_MODULE, SUPERADMIN } from "../../../constants";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Loader from "../../../helpers/Loader";
import { Spinner } from "react-bootstrap";
import {convertDateFormate, getAccessStatus} from '../../../helpers/common';

const UserListRegularPage = () => {

  const userDetails = JSON.parse(decryptToken(localStorage.getItem('accessToken')))
  const history = useHistory();
  const { contextData } = useContext(UserContext);
  const [data, setData] = contextData;
  const [orgData, setOrgData] = useState([]);

  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [editId, setEditedId] = useState();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    balance: "",
    phone: "",
    status: "Active",
  });
  const [actionText, setActionText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");
  const [userType, setUserType]=useState("");
  const [usersCount,setUsersCount]=useState(1);
  const [userData, setUserData] = useState(userDetails || null);
  const [status, setStatus]=useState("");
  const [filter, setFilter]=useState("");
  const [resetFilter, setResetFilter]=useState("");
  const [paymentProcessorFilter, setPaymentProcessorFilter]=useState("");
  const [paymentProcessorData, setPaymentProcessorData] = useState([]);
  const [paymentProcessorArr, setPaymentProcessorArr] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
   // console.log("defaultData>>>",defaultData)
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.merchant_name.localeCompare(b.merchant_name));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.merchant_name.localeCompare(a.merchant_name));
      setData([...sortedData]);
    }
  };

  // fetching user records
  const getUserRecords=async()=>{
    //console.log("userData>>>>",userData)
    try {
      let userid=userData.user_id

          let data={
            "userid":userid
          }
        let signature=generateSignature(data)

          let response=await apiRequest('post',`read-merchant-records`,signature,data)
            if(response.status==200 || response.status==201){

                let newData=[]
                newData = response.data.data.data.map((item) => {
                  item.checked = false;
                  return item;
                });
            
                setUsersCount(newData.length)
                setData([...newData]);
                const modifiedData = await convertDateFormate(newData)
                setOrgData(modifiedData)
            }
            else{
                console.log("errorResponse>>>",response)
                return []
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          } 
          finally {
            setIsLoading(false);
          }
   
  
  }
 // console.log(isLoading, '-<, isLoading')
  // unselects the data on mount
  useEffect(async() => {

    setUserType(userData.user_role)
    console.log("userData>>>",userData)
    let {readStatus,writeStatus,deleteStatus}=getAccessStatus(userData,MERCHANT_MODULE)
    if(!readStatus){
     history.push(`${process.env.PUBLIC_URL}/errors/504-classic`);
    }
    // console.log("userData>>>",userData)
    // //let readStatus=getAccessStatus(userData,REPORTS_MODULE)
    // // let readStatus=getAccessStatus(userData,REPORTS_MODULE)
    // // let readStatus=getAccessStatus(userData,REPORTS_MODULE)
    // console.log("accessStatus>>>",accessStatus)
    // if(!accessStatus){
    //   history.push(`${process.env.PUBLIC_URL}/errors/504-classic`);
    // }

    
    await getUserRecords()
 

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Changing state value when searching name
  useEffect(() => {

    if (onSearchText !== "") {
      const filteredObject = data.filter((item) => {
        if(userType=='SUPERADMIN'){
            return (
              (item.merchant_name &&  item.merchant_name.toLowerCase().includes(onSearchText.toLowerCase()) )||
              (item.merchant_id && item.merchant_id.toLowerCase().includes(onSearchText.toLowerCase())) ||
              (item.mcc_code && item.mcc_code.toLowerCase().includes(onSearchText.toLowerCase()))
            )
        }
        else{
          return (
            (item.merchant_name && item.merchant_name.toLowerCase().includes(onSearchText.toLowerCase())) ||
            (item.merchant_id && item.merchant_id.toLowerCase().includes(onSearchText.toLowerCase()))
          )
        }
       
        
      });
     setData([...filteredObject]);
    }else {
      // await getUserRecords()
     setData([...orgData]);
    }
  }, [onSearchText, setData]);

  useEffect(() => {
  
    if (resetFilter === 'reset') {
      setData([...orgData]);
      setResetFilter(""); // Reset the resetFilter value after using it
      setFilter("");
      return; // Exit early to prevent further execution
    }
    //console.log('filter',filter)
    if (filter !== "") {
   
      //  console.log('here in filter', filter, paymentProcessorArr)
      //  console.log('here in filter orgData',orgData)
      const filteredObject = orgData.filter((item) => {
        if(paymentProcessorArr.includes(filter)){
         // console.log("flag-1")
          return (
            (item.paymentProcessorName && item.paymentProcessorName.toLowerCase().includes(filter.toLowerCase()))
          )
        }
        else if(filter=='pending'){
          //console.log("flag-3")
          return item.paymentProcessorName==null
        }
        else if(filter=='all'){
          //console.log("flag-3")
          return item.paymentProcessorName!=null
        }
        // else if (userType === 'SUPERADMIN') {
        //   console.log("flag-2")
        //   return (
        //     (item.status && item.status.toLowerCase().includes(filter.toLowerCase()))
        //   )
        // }
      });
      setData([...filteredObject]);
    } else {
      setData([...orgData]);
    }
    getPaymentProcessData();
  }, [filter, resetFilter, orgData, setData, userType]);

  // function to set the action to be taken in table header
  const onActionText = (e) => {
    setActionText(e.value);
  };

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };


   const handleFilter = (e) => {
    setFilter(e);
  };

  const handleResetFilter = (ev) => {
    ev.preventDefault();
    setResetFilter('reset');
  };

  const filterByPaymentProcessor = () => {
    setFilter(paymentProcessorFilter.toLowerCase());
  };

  const handleStatusChange = (e) => {
    setStatus(e.value);
  };

  // function to change the selected property of an item
  const onSelectChange = (e, id) => {
    let newData = data;
    let index = newData.findIndex((item) => item.id === id);
    newData[index].checked = e.currentTarget.checked;
    setData([...newData]);
  };

  // function to reset the form
  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      balance: "",
      phone: "",
      status: "Active",
    });
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    resetForm();
  };

  // submit function to add a new item
  const onFormSubmit = (submitData) => {
    const { name, email, balance, phone } = submitData;
    let submittedData = {
      id: data.length + 1,
      avatarBg: "purple",
      name: name,
      role: "Customer",
      email: email,
      balance: balance,
      phone: phone,
      emailStatus: "success",
      kycStatus: "alert",
      lastLogin: "10 Feb 2020",
      status: formData.status,
      country: "Bangladesh",
    };
    setData([submittedData, ...data]);
    resetForm();
    setModal({ edit: false }, { add: false });
  };

  // submit function to update a new item
  const onEditSubmit = (submitData) => {
    const { name, email, phone } = submitData;
    let submittedData;
    let newitems = data;
    newitems.forEach((item) => {
      if (item.id === editId) {
        submittedData = {
          id: item.id,
          avatarBg: item.avatarBg,
          name: name,
          image: item.image,
          role: item.role,
          email: email,
          balance: formData.balance,
          phone: "+" + phone,
          emailStatus: item.emailStatus,
          kycStatus: item.kycStatus,
          lastLogin: item.lastLogin,
          status: formData.status,
          country: item.country,
        };
      }
    });
    let index = newitems.findIndex((item) => item.id === editId);
    newitems[index] = submittedData;
    setModal({ edit: false });
    resetForm();
  };

  // function that loads the want to editted data
  const onEditClick = (merchantid) => {

   //console.log("userid>>>>",userid,userData)

    history.push({
      pathname:  `${process.env.PUBLIC_URL}/merchant-profile`,
      state: { merchantid: merchantid },
    });

  };

  // function to change to suspend or delete any record 
  const suspendUser = async(merchantid) => {

 //   console.log("merchantid>>",merchantid)
   // let userid=userData.user_id;

  //  let signature="AorFkeqCC4827Q5slcmjx6ZxU15/j3GFhPMTt4xFYi/M7ahNIFHajyH6tQ+MeuTotH+qjwldqB8PVbCav4BbIH8Y5EJC+FLwIpWCpDXjhzAHZoRt6X2w84svIrIMssP+kEyk60PKfQX3CDXO5/YBlZM1zcrviiFlIejvH1EOCOg67MZwD/1W5/I5wNLq1X5C2pwgCKmAwlg5RLI9wfwUCIVJpBbDtx9x8PnPPYTe9GQkwcj6lndeKvRgqkr+SDNz3jcC99U5RcXjFVOe5bVcm+10+JY3SZfeThpJSWf/WaJV9c+VClj3dRJ+FZ5hKBmtWM35oA209h128aFT4NXOJXtLIr8GBvFCq9I1zr/n6UwN99+42Y18Jm/qR+Qq/5vbUPjHYt0GN2xb8fNtdmvsYMj0Wjz9TPbhEmNSEwbW3F3VXEu8bTUEU/aNJcnSeY8zawAwH12a6plEtOVKv3lgbPTwXHiGVVluRthc7TL3Qq5m11XUBvrmIPMkR5kvBPvgCaK7vvPW5d9nfwdLKfqT9Gg2tN6EwVFSCNvfhJjF6GSOmMwYuyHiWLmlpCupkiQeVHLDV7Q9ieVIs/fMh0oGAxeV57KsCbdlh7Zv4x5ra0R76EibGunZFa2Q6vsi5TVUSqvmaETu/UlzDHQOLhZsP/ID1W08MnnSVjvbHK5oWRI="
    let data={
      "merchantid": merchantid,
      "userid":userData.user_id || "",
    }
   let signature=generateSignature(data)
   let response
   if(data && signature){
     response=await apiRequest('post',`delete-merchant`,signature,data)
   }

   // console.log("response>>>",response.data)
    if(response.status==200 || response.status==201){

      await getUserRecords()
    }
    else{
      // return []
      console.log("error.data>>>",response)
    }
  
    // let newData = data;
    // let index = newData.findIndex((item) => item.id === id);
    // newData[index].status = "Suspend";
    // setData([...newData]);
  };

  // function to download keys
  const keys = async(u_id, key, merchantName,  merchantId) => {
    // console.log(u_id, key, '-<, u_id, key')
    let userid=userData.user_id;
    let data={
      "merchantid":u_id,
      "key": key,
      "userid":userid
    }

    let signature=generateSignature(data)
    let response=await apiRequest('post','get-keys',signature, data)
    let keys = await extractKey(response.data, key); 

    if(!keys){
      keys = `There are no keys available for this merchant. Please generate keys.`
    }

    function convertTextWithSpaces(text, merchant_id) {
      if (text && text.trim() !== '') {
        return text.replace(/ /g, '_');
      } else {
        return merchant_id;
      }
    }

    if(response.status==200 || response.status==201){
      try {
            const data = await JSON.stringify(keys);
            const convertedText =convertTextWithSpaces(merchantName, merchantId);
            const fileName = `${convertedText}_${key}.txt`;
            const element = document.createElement('a');
            const file = new Blob([data], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            element.download = fileName;
            document.body.appendChild(element);
            element.click();
      } catch (error) {
          console.error('Error fetching API response:', error);
        }
    }
    else{
      console.log("error.data>>>",response)
    }
  
  };

  // function to change the check property of an item
  const selectorCheck = (e) => {
    let newData;
    newData = data.map((item) => {
      item.checked = e.currentTarget.checked;
      return item;
    });
    setData([...newData]);
  };

  // function which fires on applying selected action
  const onActionClick = (e) => {
    if (actionText === "suspend") {
      let newData = data.map((item) => {
        if (item.checked === true) item.status = "Suspend";
        return item;
      });
      setData([...newData]);
    } else if (actionText === "delete") {
      let newData;
      newData = data.filter((item) => item.checked !== true);
      setData([...newData]);
    }
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;

  //console.log("data>>>>",data)
  // if(data.length>0){
  //   const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  // }

  const currentItems= data.length>0?data.slice(indexOfFirstItem, indexOfLastItem):[]
  
  // console.log("currentItems>>>",currentItems)
  // Change Page
  // const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const paginate = (pageNumber) => {
    // console.log("pageNumber>>>",pageNumber)
    setCurrentPage(pageNumber);
  }

  const { errors, register, handleSubmit } = useForm();

  const goToRegistration=()=>{
    history.push(`${process.env.PUBLIC_URL}/auth-register`)
  }

    const handleDownload = (data) => {
      // console.log(data, '-<, inside data ')
      const modifiedData = data.map((item) => {
        const {
          merchant_id,
          merchant_name,
          register_date,
          mcc_code,
          status,
          keyStatus,
          paymentProcessorName
        } = item;
        


        if(userType=='SUPERADMIN'){
          return {
            "Merchant id": merchant_id,
            Name: merchant_name,
            "Registration Date": register_date,
            "Mcc Code": mcc_code,
            "Payment Processor": paymentProcessorName,
            Status: status,
            keyStatus: keyStatus
          };
        }
        else{
          return {
            "Merchant id": merchant_id,
            Name: merchant_name,
            "Registration Date": register_date,
            "Payment Processor": paymentProcessorName,
            Status: status,
            keyStatus: keyStatus
          };
        }
    
      });

      let fileName=userType=='SUPERADMIN'?'Merchant List Sheet.xlsx':'Admin Sheet.xlsx'
      // Convert data to worksheet
      const worksheet = XLSX.utils.json_to_sheet(modifiedData);
  
      // Create workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      // Generate Excel file buffer
      const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });

      // Save the file using FileSaver.js
      const fileData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(fileData, fileName);
    };

     // fetching payment processor record
     const getPaymentProcessData=async()=>{
      let data={
        "type":"open"
      }
     let signature=generateSignature(data)
      let response=await apiRequest('get',`get-payment-processor?type=open`,signature)

      let array = [];
      if(response.status==200 || response.status==201){
         if(response.data.data.data.length>0){
           let filterData=response.data.data.data.map((item)=>{
          //  console.log(item.name, '-<, items names ')
              // setPaymentProcessorArr(item.name);
              array.push(item.name)
               return {'value':item.payment_processor_id,'label':item.name.toUpperCase()}
           })
        //   console.log(filterData, '-<< filterDate')
           filterData.unshift({'value': filterData.length + 1,'label': 'ALL' })
           filterData.push({'value': filterData.length + 1,'label': 'PENDING'})
          // filterData.push({'value': filterData.length + 1,'label': 'ALL Payment Processors' })
          // console.log(filterData, '-<< filterDate')

           setPaymentProcessorData(filterData)
         }
          else
            setPaymentProcessorData([])
      }
      else{
        return []
      }
      setPaymentProcessorArr(array);
    
    }

  return (
    <React.Fragment>
      <Head title="User List - Regular"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                {
                  usersCount==1?'Merchant Record':'Merchant Lists'
                }
                
              </BlockTitle>
              
              {
                  usersCount!=1?
                  <BlockDes className="text-soft">
                  <p>You have total {usersCount} users.</p>
                 </BlockDes>
                  :null
                }
              {/* <BlockDes className="text-soft">
                <p>You have total {usersCount} users.</p>
              </BlockDes> */}
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
               
                  <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      {/* <Button color="light" outline className="btn-white"> */}
                      <Button color="light" outline className="btn-white"  onClick={() => handleDownload(data) } >
                        <Icon name="download-cloud"></Icon>
                        <span>Export</span>
                      </Button>
                    </li>

                    {
                  // userData !=null && userData.user_role!='ADMIN'?
                  // userData !=null && userData.user_role!='OWNER'?
                  userData !=null && userData.user_role==SUPERADMIN?
                    <li className="nk-block-tools-opt">
                      {/* {onClick={() => setModal({ add: true })}} */}
                      <Button  style={{padding:"6px"}} color="primary" className="btn-icon" onClick={() => goToRegistration()}>
                        {/* <Icon name="plus"></Icon> */}
                        Add Merchant
                      </Button>
                    </li>
                    
                     :
                     null

                    }
                  </ul>
                </div>
                {/* // :
                // null */}
               
                
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="card-tools">

                  {
                    // userData !=null && userData.user_role!='ADMIN'?
                    userData !=null && userData.user_role==SUPERADMIN?
                    <div className="form-inline flex-nowrap gx-3">
                    <div className="form-wrap">
                      <RSelect
                        options={paymentProcessorData}
                        className="w-130px"
                        placeholder="Payment Processor"
                        // onChange={(e) => onActionText(e)}
                        onChange={(selectedOption) => {
                          //console.log(selectedOption, '-<, selectionOptions')
                          setPaymentProcessorFilter(selectedOption.label)
                        }}
                      />
                    </div>
                    <div className="btn-wrap">
                    <span className="d-none d-md-block">
                        <Button
                          // disabled={actionText !== '' ? false : true}
                          color="light"
                          outline
                          className="btn-dim"
                          onClick={filterByPaymentProcessor}
                        >
                          Apply
                        </Button>
                      </span>

                      {/* <span className="d-none d-md-block">
                        <Button
                          disabled={actionText !== "" ? false : true}
                          color="light"
                          outline
                          className="btn-dim"
                          // onClick={(e) => onActionClick(e)}
                          onClick={filterByPaymentProcessor}
                        >
                          Apply
                        </Button>
                      </span> */}
                      <span className="d-md-none">
                        <Button
                          color="light"
                          outline
                          disabled={actionText !== "" ? false : true}
                          className="btn-dim btn-icon"
                          onClick={(e) => onActionClick(e)}
                        >
                          <Icon name="arrow-right"></Icon>
                        </Button>
                      </span>
                    </div>
                  </div>
                  :
                  null
                  }
               
                </div>
                <div className="card-tools me-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                                <Icon name="arrow-left"></Icon>
                              </Button>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <div className="dot dot-primary"></div>
                                  <Icon name="filter-alt"></Icon>
                                </DropdownToggle>
                                <DropdownMenu
                                  end
                                  className="filter-wg dropdown-menu-xl"
                                  style={{ overflow: "visible" }}
                                >
                                  <div className="dropdown-head">
                                    <span className="sub-title dropdown-title">Filter Users</span>
                                    <div className="dropdown">
                                      <a
                                        href="#more"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                        className="btn btn-sm btn-icon"
                                      >
                                        <Icon name="more-h"></Icon>
                                      </a>
                                    </div>
                                  </div>
                                  <div className="dropdown-body dropdown-body-rg">
                                    <Row className="gx-6 gy-3">
                                      <Col size="6">
                                        <div className="form-group">
                                          <label className="overline-title overline-title-alt">Status</label>
                                          <RSelect options={filterStatus} placeholder="Any Status" 
                                          onChange={handleStatusChange}
                                          />
                                        </div>
                                      </Col>
                                      <Col size="12">
                                        <div className="form-group">
                                          <button type="button" className="btn btn-secondary"
                                         onClick={() => handleFilter(status)}
                                          >
                                            Filter
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="dropdown-foot between">
                                    <a
                                      href="#reset"
                                      // onClick={(ev) => {
                                      //   ev.preventDefault();
                                      // }}
                                      onClick={handleResetFilter}
                                      className="clickable"
                                    >
                                      Reset Filter
                                    </a>
                                    <a
                                      href="#save"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      Save Filter
                                    </a>
                                  </div>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle color="tranparent" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <Icon name="setting"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end className="dropdown-menu-xs">
                                  <ul className="link-check">
                                    <li>
                                      <span>Show</span>
                                    </li>
                                    <li className={itemPerPage === 10 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(10);
                                        }}
                                      >
                                        10
                                      </DropdownItem>
                                    </li>
                                    <li className={itemPerPage === 15 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(15);
                                        }}
                                      >
                                        15
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                  <ul className="link-check">
                                    <li>
                                      <span>Order</span>
                                    </li>
                                    <li className={sort === "dsc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("dsc");
                                          sortFunc("dsc");
                                        }}
                                      >
                                        DESC
                                      </DropdownItem>
                                    </li>
                                    <li className={sort === "asc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("asc");
                                          sortFunc("asc");
                                        }}
                                      >
                                        ASC
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by user or email"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <DataTableBody>
              <DataTableHead>
                {/* <DataTableRow className="nk-tb-col-check">
             
                </DataTableRow> */}
                <DataTableRow>
                  <span className="sub-text">Name</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Merchant id</span>
                </DataTableRow>
                {
                  userType=='SUPERADMIN'?
                  <DataTableRow size="mb">
                    <span className="sub-text">Mcc code</span>
                  </DataTableRow>
                  :
                  null
                }
           
                 <DataTableRow size="md">
                  <span className="sub-text">Registration date</span>
                </DataTableRow>
                {/* <DataTableRow size="md">
                  <span className="sub-text">Payment Processor</span>
                </DataTableRow> */}
                <DataTableRow size="md">
                  <span className="sub-text">Status</span>
                </DataTableRow>

                  <DataTableRow size="mb">
                    <span className="sub-text">Evvopay Key Status</span>
                  </DataTableRow>
                  {/* {
                  userType=='SUPERADMIN'?
                  <DataTableRow size="mb">
                     <span className="sub-text">Payment Processor</span>
                  </DataTableRow>
                  :
                  null
                } */}

                  <DataTableRow size="mb">
                     <span className="sub-text">Payment Processor</span>
                  </DataTableRow>
                <DataTableRow className="nk-tb-col-tools text-end">
                  {/* <UncontrolledDropdown>
                    <DropdownToggle
                      color="tranparent"
                      className="btn btn-xs btn-outline-light btn-icon dropdown-toggle"
                    >
                      <Icon name="plus"></Icon>
                    </DropdownToggle>
                    <DropdownMenu end className="dropdown-menu-xs">
                      <ul className="link-tidy sm no-bdr">
                        <li>
                          <div className="custom-control custom-control-sm custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="bl" />
                            <label className="custom-control-label" htmlFor="bl">
                              Balance
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-control-sm custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="ph" />
                            <label className="custom-control-label" htmlFor="ph">
                              Phone
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-control-sm custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="vri" />
                            <label className="custom-control-label" htmlFor="vri">
                              Verified
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-control-sm custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="st" />
                            <label className="custom-control-label" htmlFor="st">
                              Status
                            </label>
                          </div>
                        </li>
                      </ul>
                    </DropdownMenu>
                  </UncontrolledDropdown> */}
                </DataTableRow>
              </DataTableHead>
              {/*Head*/}

              {/* {console.log("currentItems>>>",currentItems)} */}
              {currentItems.length > 0
                ? currentItems.map((item) => {
                    return (
                      <DataTableItem key={item.id}>
                        {/* <DataTableRow className="nk-tb-col-check">
                   
                        </DataTableRow> */}
                        <DataTableRow>
                      
                            <span>{item.merchant_name}</span>
                        </DataTableRow>
                        {/* <DataTableRow size="mb">
                          <span className="tb-amount">
                            {item.merchant_id} <span className="currency"></span>
                          </span>
                        </DataTableRow> */}
                         <DataTableRow size="md">
                          <span>{item.merchant_id}</span>
                        </DataTableRow>
                        {
                          userType=='SUPERADMIN'?
                          <DataTableRow size="md">
                          <span>{item.mcc_code}</span>
                         </DataTableRow>
                          :
                          null
                        }
                        <DataTableRow size="md">
                          <span>{item.register_date}</span>
                        </DataTableRow>

                        {/* <DataTableRow size="md">
                          <span>{item.paymentProcessorName}</span>
                        </DataTableRow> */}
                  
                        <DataTableRow size="md">
                          <span
                            className={`tb-status text-${
                              item.status === "Active" ? "success" : item.status === "Pending" ? "warning" : "danger"
                            }`}
                          >
                            {item.status}
                          </span>
                        </DataTableRow>

                          <DataTableRow size="md">
                          <span>{item.keyStatus}</span>
                         </DataTableRow>
                        
                         {/* {
                          userType=='SUPERADMIN'? */}

                          <DataTableRow size="md">
                            {  item.paymentProcessorName==null?
                            <span>Pending</span>
                            :
                            <span>{item.paymentProcessorName}</span>
                          }
                          
                         </DataTableRow>
                        {/* //   :
                        //   null
                        // } */}
                        <DataTableRow className="nk-tb-col-tools">
                          <ul className="nk-tb-actions gx-1">
                            { item.status!=='Pending' && (
                              <li className="nk-tb-action-hidden" onClick={() => onEditClick(item.id)}>
                              {/* <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"edit" + item.id}
                                icon="edit-alt-fill"
                                direction="top"
                                text="Edit"
                              /> */}
                            </li>

                            )}
                            
                            { userType=='SUPERADMIN' &&  item.status !== "Suspend" && item.status !== "Pending" && (
                              <React.Fragment>
                                <li className="nk-tb-action-hidden" onClick={() => suspendUser(item.id)}>
                                  {/* <TooltipComponent
                                    tag="a"
                                    containerClassName="btn btn-trigger btn-icon"
                                    id={"suspend" + item.id}
                                    icon="user-cross-fill"
                                    direction="top"
                                    text="Suspend"
                                  /> */}
                                </li>
                              </React.Fragment>
                            )}
                            {/* { userType=='SUPERADMIN' &&  item.status !== "PrivateKey" && (
                              <React.Fragment>
                                <li className="nk-tb-action-hidden" onClick={() => suspendUser(item.userid,item.id)}>
                                  <TooltipComponent
                                    tag="a"
                                    containerClassName="btn btn-trigger btn-icon"
                                    id={"PrivateKey" + item.id}
                                    icon="user-cross-fill"
                                    direction="top"
                                    text="PrivateKey"
                                  />
                                </li>
                              </React.Fragment>
                            )} */}

                                { item.status!=='Pending' && (

                                    <li>
                                    <UncontrolledDropdown>
                                    <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                      <Icon name="more-h"></Icon>
                                    </DropdownToggle>
                                    <DropdownMenu end>
                                      <ul className="link-list-opt no-bdr">
                                      {
                                       // console.log("item>>>",item)
                                      }
                                        <li onClick={() => onEditClick(item.id)}>
                                          <DropdownItem
                                            tag="a"
                                            href="#edit"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                          >
                                            <Icon name="edit"></Icon>
                                            <span>Edit</span>
                                          </DropdownItem>
                                        </li>
                                        { userType=='SUPERADMIN' && item.status !== "Suspend" &&  item.status !== "Pending" && (
                                          <React.Fragment>
                                            <li className="divider"></li>
                                            <li onClick={() => suspendUser(item.id)}>
                                              <DropdownItem
                                                tag="a"
                                                href="#suspend"
                                                onClick={(ev) => {
                                                  ev.preventDefault();
                                                }}
                                              >
                                                <Icon name="na"></Icon>
                                                <span>Suspend User</span>
                                              </DropdownItem>
                                            </li>
                                          </React.Fragment>
                                        )}
                                        { userType=='SUPERADMIN' && item.status == "Active" && item.status !== "keys" && (
                                          <React.Fragment>
                                            <li className="divider"></li>
                                            <li onClick={() => keys(item.id, "privateKey", item.merchant_name, item.merchant_id)}>
                                              <DropdownItem
                                                tag="a"
                                                href="#key"
                                                onClick={(ev) => {
                                                  ev.preventDefault();
                                                }}
                                              >
                                                <Icon name="download"></Icon>
                                                <span>Private Key</span>
                                              </DropdownItem>
                                            </li>
                                          </React.Fragment>
                                        )}
                                          { userType=='SUPERADMIN' && item.status == "Active" && item.status !== "keys" && item.keyStatus=='Key issued' && (
                                          <React.Fragment>
                                            <li className="divider"></li>
                                            <li onClick={() => keys(item.id, "publicKey", item.merchant_name, item.merchant_id)}>
                                              <DropdownItem
                                                tag="a"
                                                href="#key"
                                                onClick={(ev) => {
                                                  ev.preventDefault();
                                                }}
                                              >
                                                <Icon name="download"></Icon>
                                                <span>Public Key</span>
                                              </DropdownItem>
                                            </li>
                                          </React.Fragment>
                                        )}
                                      </ul>
                                    </DropdownMenu>
                                    </UncontrolledDropdown>
                                    </li>
                                                                
                                )}
                          
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
        
            <div className="card-inner">
                {isLoading ? (
                  <Loader />

                ) : currentItems.length > 0 ? (
                  <React.Fragment>
                    {/* Your rendered data */}
                    <PaginationComponent
                      itemPerPage={itemPerPage}
                      totalItems={data.length}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  </React.Fragment>
                ) : (
                  <div className="text-center">
                    <span className="text-silent">No data found</span>
                  </div>
                )}
              </div>
          </DataTable>
        </Block>
        <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#close"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Add User</h5>
              <div className="mt-4">
                <Form className="row gy-4" noValidate onSubmit={handleSubmit(onFormSubmit)}>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={formData.name}
                        placeholder="Enter name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Email </label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        defaultValue={formData.email}
                        placeholder="Enter email"
                        ref={register({
                          required: "This field is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                      />
                      {errors.email && <span className="invalid">{errors.email.message}</span>}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Balance</label>
                      <input
                        className="form-control"
                        type="number"
                        name="balance"
                        defaultValue={formData.balance}
                        placeholder="Balance"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.balance && <span className="invalid">{errors.balance.message}</span>}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Phone</label>
                      <input
                        className="form-control"
                        type="number"
                        name="phone"
                        defaultValue={formData.phone}
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.phone && <span className="invalid">{errors.phone.message}</span>}
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="form-group">
                      <label className="form-label">Status</label>
                      <div className="form-control-wrap">
                        <RSelect
                          options={filterStatus}
                          defaultValue={{ value: "Active", label: "Active" }}
                          onChange={(e) => setFormData({ ...formData, status: e.value })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      {/* {

                      } */}
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Add User
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Update User</h5>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={handleSubmit(onEditSubmit)}>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={formData.name}
                        placeholder="Enter name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Email</label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        defaultValue={formData.email}
                        placeholder="Enter email"
                        ref={register({
                          required: "This field is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                      />
                      {errors.email && <span className="invalid">{errors.email.message}</span>}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Balance</label>
                      <input
                        className="form-control"
                        type="number"
                        name="balance"
                        disabled
                    //    defaultValue={parseFloat(formData.balance.replace(/,/g, ""))}
                        placeholder="Balance"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.balance && <span className="invalid">{errors.balance.message}</span>}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">Phone</label>
                      <input
                        className="form-control"
                        type="number"
                        name="phone"
                        // defaultValue={Number(formData.phone)}
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.phone && <span className="invalid">{errors.phone.message}</span>}
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="form-group">
                      <label className="form-label">Status</label>
                      <div className="form-control-wrap">
                        <RSelect
                          options={filterStatus}
                          // defaultValue={{
                          //   value: formData.status,
                          //   label: formData.status,
                          // }}
                          //onChange={(e) => setFormData({ ...formData, status: e.value })}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Update User
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default UserListRegularPage;
