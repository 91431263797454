import React, { useState, useEffect, useRef } from "react";
import Head from "../layout/head/Head";
import Content from "../layout/content/Content";
import SaleRevenue from "../components/partials/default/sale-revenue/SaleRevenue";
import ActiveSubscription from "../components/partials/default/active-subscription/ActiveSubscription";
import AvgSubscription from "../components/partials/default/avg-subscription/AvgSubscription";
import SalesOverview from "../components/partials/default/sales-overview/SalesOverview";
import TransactionTable from "../components/partials/default/transaction/Transaction";
import RecentActivity from "../components/partials/default/recent-activity/Activity";
import NewsUsers from "../components/partials/default/new-users/User";
import Support from "../components/partials/default/support-request/Support";
import Notifications from "../components/partials/default/notification/Notification";
import { DropdownToggle, DropdownMenu, Card, UncontrolledDropdown, DropdownItem } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewCard,
  PreviewAltCard,
  BlockBetween,
  RSelect,
} from "../components/Component";
import { generateSignature, decryptToken} from '../helpers/signature';
import { apiRequest } from '../helpers/api';
import { calculateTotalSumAmount, modifyDateFormat, restructerObj, restructerObjForPayment } from '../helpers/common';
import Loader from "../helpers/Loader";
import DatePicker from "react-datepicker";
import $ from "jquery";


let userDetails = JSON.parse(decryptToken(localStorage.getItem('accessToken')))
const Homepage = ({history}) => {
  const [sm, updateSm] = useState(false);
  const [sum, setSum] = useState();
  const [apiRequestData, setApiRequestData] = useState([]);
  const [activesum, setActiveSum] = useState();
  const [activeMerchant, setActiveMerchant] = useState([]);
  const [apiCallSum, setApiCallSum] = useState();
  const [apiCallCount, setApiCallCount] = useState([]);
  const [merchantIds, setMerchantIds] = useState([]);
  const [merchantid, setMerchantid] = useState([]);
  const [merchantPrimaryKey, setMerchantPrimaryKey] = useState([]);
  const [paymentProcessor, setPaymentProcessor] = useState([]);
  const [endpointsCount, setEndpointsCount] = useState([]);
  const [apiSum, setApiSum] = useState();
  const [apiCallCountAdmin, setApiCallCountAdmin] = useState([]);
  const [refundTransactions, setRefundTransactions] = useState([]);
  const [voidTransactions, setVoidTransactions] = useState([]);
  const [paymentTransactions, setPaymentTransactions] = useState([]);
  const [paymentWidget, setPaymentWidget] = useState([]);
  const [refundWidget, setRefundWidget] = useState([]);
  const [voidWidget, setVoidWidget] = useState([]);
  // const [selectedYear, setSelectedYear] = useState('year');
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(false);
  const [defaultMerchant,setDefaultMerchant]=useState({label: "Select", value: 0 })
  const [sumAmount, setSumAmount] = useState(true);
  // const [startDate, setStartDate] = useState(new Date());
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  //const [errorStatus, setErrorStatus] = useState(false);
  const [errorMsg, setErrorMsg] = useState({common:'',startDateError:'',endDateError:''});
  const [merchantName, setMerchantName] = useState('');
  const ref = useRef(null);
  const [userData, setUserData] = useState(userDetails || null);


 
useEffect(() => {



  // console.log("userData>>>",userData)
  
  // if(userData.acl==null && userData.user_role!="SUPERADMIN" && userData.user_role!="OWNER"){
  //   history.push(`${process.env.PUBLIC_URL}/errors/504-classic`);
  // }

  // let {readStatus,writeStatus,deleteStatus}=getAccessStatus(userData,DAS)
  // if(!readStatus){
  //  history.push(`${process.env.PUBLIC_URL}/errors/504-classic`);
  // }
  // const fetchMerchantCount = async () => {

  //   // getDefaultDate('sd')
  //   // getDefaultDate('ed')

  //   let getMerchantCount = {
  //       // year : 2023,
  //       year : selectedYear === 'year' ? 2023 : selectedYear
  //       // startDate : startDate==""?getDefaultDate('sd'):startDate,
  //       // endDate: endDate==""?getDefaultDate('ed'):endDate
  //     }
  //     console.log("getMerchantCount>>>",getMerchantCount)
  //   let signature=generateSignature(getMerchantCount)

  //   let response
  //   if(signature){
  //       response= await apiRequest('get', `get-merchant-count?year=${getMerchantCount.year}`, signature)
  //     //response= await apiRequest('get', `get-merchant-count?startDate=${getMerchantCount.startDate}&endDate=${getMerchantCount.endDate}`, signature)
  //   }
  //   if(response?.data?.data?.data){
  //     let fianlData = response?.data?.data?.data

  //     setApiRequestData(response?.data?.data?.data);
  //     const totalCount = fianlData.reduce((accumulator, currentObject) => accumulator + currentObject.count, 0);
  //       setSum(totalCount)

  //   }
  //     };


  // const fetchActiveMerchantCount = async () => {
  //  // setSelectedYear(new Date().getFullYear())
  //   // let getMerchantCount = {
  //   //     // year : 2023,
  //   //     year : selectedYear === 'year' ? 2023 : selectedYear, 
  //   //     status: 'active'
  //   //   }

  //   //console.log("selectedYear",selectedYear)
  //   let getMerchantCount = {
  //     // year : 2023,
  //     year : selectedYear,
  //     status: 'active'
  //   }


  //   let signature= await generateSignature(getMerchantCount)
  //   let response
  //     if(signature){
  //       response= await apiRequest('get', `get-merchant-count?year=${getMerchantCount.year}&status=${getMerchantCount.status}`, signature)
  //     }
  //   if(response?.data?.data?.data){
  //     let fianlData = response?.data?.data?.data
  //     setActiveMerchant(response?.data?.data?.data);
  //     const totalCount = fianlData.reduce((accumulator, currentObject) => accumulator + currentObject.count, 0);
  //       setActiveSum(totalCount)
  //       setIsLoading(false)
  //   }

      

  //   };


  const fetchApiCallsCount = async () => {
    //setIsLoading2(true)
   //console.log('here in api cal count.. ',merchantid)
        let getApiCallsReq = {
            // paymentProcessor : paymentProcessor.toLowerCase(),
            // merchantId: merchantid !== null && merchantid !== undefined ? merchantid : '1005776',
            merchantId: merchantid 
          }
        
     //console.log(getApiCallsReq, '-<, getApiCallsReq')
        let signature=generateSignature(getApiCallsReq)
        let response
        if(getApiCallsReq.merchantId){
          response= await apiRequest('get', `get-api-call-count?merchantId=${getApiCallsReq.merchantId}`, signature, getApiCallsReq)
        }
        if(response?.data?.data?.data){
         let payload =  response?.data?.data?.data
          let modifyPayload = await restructerObj(payload);
          if(modifyPayload){
           // console.log(modifyPayload, '-<, modified payloasd')
              setApiCallCount(modifyPayload);
              const totalCount = modifyPayload.reduce((accumulator, currentObject) => accumulator + currentObject.count, 0);
            setApiCallSum(totalCount)
            setEndpointsCount(modifyPayload.length)
            //console.log("isLoading2>>>",isLoading2)
            // if(isLoading2){
            //   scollToRef.current?.scrollIntoView({behavior: 'smooth'});
            // }
     //      setIsLoading2(false)
         //    setIsLoadingStatus('0')
          }
        }
        else{
        // setIsLoading2(false)
         //setIsLoadingStatus('0')
        }
    
    };
    

  const fetchMerchantIds = async () => {
    let obj = {filter : 'all'}
    let signature=generateSignature(obj)
    let response
    if(signature){
      response = await apiRequest('get', `get-merchant-ids?filter=${obj.filter}`, signature)

    }
    if(response?.data?.data?.data){
      setMerchantIds(response?.data?.data?.data);
      let filterData = response?.data?.data?.data.map((item) => {
        return { value: item.merchant_id, label: item.merchant_name ? item.merchant_name : item.merchant_id, id: item.id,};
      });

      setMerchantName(filterData);
    }
    else{
      setMerchantName([]);
    }
      };

  //   const fetchApiCallsCountByAdmin = async () => {
  //       let getApiCallsReq = {
  //           merchantId: userDetails.merchantid
  //         }
          
  //       //  console.log("getApiCallsReq>>>",getApiCallsReq)

  //       let signature=generateSignature(getApiCallsReq)
  //       let response
  //       if(getApiCallsReq.merchantId){
  //         response= await apiRequest('post', `get-api-call-count-admin`, signature, getApiCallsReq)
  //       }
  //     //  console.log("response?.data?.data?.data>>>",response?.data?.data?.data)
  //       if(response?.data?.data?.data){
  //        let payload =  response?.data?.data?.data
  //         let modifyPayload = await restructerObj(payload);
  //      //   console.log("modifyPayloadAdmin->",modifyPayload)
  //         if(modifyPayload){
  //           setApiCallCount(modifyPayload);
  //           let totalCount = modifyPayload.reduce((accumulator, currentObject) => accumulator + currentObject.count, 0);
  //             setApiCallSum(totalCount);
  //             setEndpointsCount(modifyPayload.length);
  //            // setIsLoading2(false)
  //         }
  //       }
  //       else{
  //      //   setIsLoading2(false)
  //       }
    
  //   };

    
  //   const fetchSumAmount = async () => {
  //     let getApiCallsReq = {
  //         merchantId: userDetails.merchantid
  //       }
  
  //     let signature=generateSignature(getApiCallsReq)
  //     let response
  //     console.log("getApiCallsReq>>>",getApiCallsReq)
  //     if(getApiCallsReq.merchantId){
  //       response= await apiRequest('post', `get-sum-amount`, signature, getApiCallsReq)
  //     }
  //     console.log("response?.data?.data?.data>>>",response?.data?.data?.data)
  //     if(response?.data?.data?.data){
  //      let payload =  response?.data?.data?.data
  //      let modifiedData = await calculateTotalSumAmount(payload);

  //      setSumAmount(modifiedData)
  //     }
  //     else{
  //       setIsLoading2(false)
  //     }
  
  // };

    const fetchRefundTransactions = async () => {

            let getApiCallsReq = {
                // merchantId: userDetails.merchantid, 
                merchantId : (userDetails.user_role == 'SUPERADMIN') ? merchantPrimaryKey : userDetails.merchantid
              }
        
            let signature=generateSignature(getApiCallsReq)
            let response
            if(getApiCallsReq.merchantId){
              response= await apiRequest('post', `get-refund-transactions`, signature, getApiCallsReq)
            }
            if(response?.data?.data?.data){
             let payload =  response?.data?.data?.data
             const modifiedData = await modifyDateFormat(payload);
             setRefundTransactions(modifiedData);
             setRefundWidget(payload);
            }
            else{
            //  setIsLoading2(false)
            }
    };
    const fetchVoidTransactions = async () => {
      let getApiCallsReq = {
          // merchantId: userDetails.merchantid
          merchantId : (userDetails.user_role == 'SUPERADMIN') ? merchantPrimaryKey : userDetails.merchantid
        }
  
      let signature=generateSignature(getApiCallsReq)
      let response
      if(getApiCallsReq.merchantId){
        response= await apiRequest('post', `get-void-transactions`, signature, getApiCallsReq)
      }
      if(response?.data?.data?.data){
       let payload =  response?.data?.data?.data
       const modifiedData = await modifyDateFormat(payload);
       setVoidTransactions(modifiedData);
       setVoidWidget(payload);
      }
      else{
      //  setIsLoading2(false)
      }
    };

  const fetchPaymentTransactions = async () => {
     //console.log("merchantPrimaryKey>>>",merchantPrimaryKey)
    // console.log("userDetails.merchantid>>>",userDetails.merchantid)
    let getApiCallsReq = {
        // merchantId: userDetails.merchantid
        merchantId : (userDetails.user_role == 'SUPERADMIN') ? merchantPrimaryKey : userDetails.merchantid
      }
      //console.log("getApiCallsReq>>>",getApiCallsReq)
    let signature=generateSignature(getApiCallsReq)
    let response
    if(getApiCallsReq.merchantId){
      response= await apiRequest('post', `get-payment-transactions`, signature, getApiCallsReq)
    }
    if(response?.data?.data?.data){
    let payload =  response?.data?.data?.data
   // console.log(payload, '-<<, payload>>>')
    const modifiedData = restructerObjForPayment(payload);
    setPaymentWidget(modifiedData);
    setPaymentTransactions(payload);
    setIsLoading2(false)
    
    }
    else{
      setIsLoading2(false)
    }
  };
    


if(userDetails.user_role == 'SUPERADMIN'){

  fetchMerchantCount(); 
  fetchActiveMerchantCount(); 

  fetchApiCallsCount(); 
  fetchMerchantIds(); 
  fetchRefundTransactions();
  fetchVoidTransactions();
  fetchPaymentTransactions();

  // console.log("isLoading2useeffect>>>",isLoading2)
  // console.log("isLoading2useeffect>>>", scollToRef.current)
  // if(isLoading2){
  //   scollToRef.current.scrollIntoView()
  // }
  // setIsLoading(false)

}else{
  fetchMerchantCount(); 
  fetchActiveMerchantCount(); 
  fetchApiCallsCountByAdmin();
  fetchRefundTransactions();
  fetchVoidTransactions();
  fetchPaymentTransactions();
  fetchSumAmount();
  //setIsLoading(false)
}
 
    
}, [merchantid, userDetails.merchantid, selectedYear,defaultMerchant]);

const fetchApiCallsCountByAdmin = async () => {
  let getApiCallsReq = {
      merchantId: userDetails.merchantid
    }
    
  //  console.log("getApiCallsReq>>>",getApiCallsReq)

  let signature=generateSignature(getApiCallsReq)
  let response
  if(getApiCallsReq.merchantId){
    response= await apiRequest('post', `get-api-call-count-admin`, signature, getApiCallsReq)
  }
//  console.log("response?.data?.data?.data>>>",response?.data?.data?.data)
  if(response?.data?.data?.data){
   let payload =  response?.data?.data?.data
    let modifyPayload = await restructerObj(payload);
 //   console.log("modifyPayloadAdmin->",modifyPayload)
    if(modifyPayload){
      setApiCallCount(modifyPayload);
      let totalCount = modifyPayload.reduce((accumulator, currentObject) => accumulator + currentObject.count, 0);
        setApiCallSum(totalCount);
        setEndpointsCount(modifyPayload.length);
       // setIsLoading2(false)
    }
  }
  else{
 //   setIsLoading2(false)
  }

};


    const fetchSumAmount = async () => {
    // let getApiCallsReq = {
    //     merchantId: userDetails.merchantid
    //   }
        let getApiCallsReq = {
          startDate : startDate==""?getDefaultDate('sd'):startDate,
          endDate: endDate==""?getDefaultDate('ed'):endDate,
          merchantId: userDetails.merchantid
        }
        let signature=generateSignature(getApiCallsReq)
        let response
        // console.log("getApiCallsReq>>>",getApiCallsReq)
        if(getApiCallsReq.merchantId){
          response= await apiRequest('post', `get-sum-amount`, signature, getApiCallsReq)
        }
        // console.log("response?.data?.data?.data>>>",response?.data?.data?.data)
        if(response?.data?.data?.data){
        let payload =  response?.data?.data?.data
        let modifiedData = await calculateTotalSumAmount(payload);

        setSumAmount(modifiedData)
        setIsLoading(false)
        
    }
      else{
        setIsLoading(false)
      }

};
const getDefaultDate=(type)=>{
  let date = new Date();
  if(type=='sd'){
    let d=new Date(date.getFullYear(), 0, 1)
    return d.getFullYear()+'-'+d.getMonth()+1+'-'+d.getDay()+1
  }
  else{
  //  console.log(date)
    // let day = date.getDate()+1;
    let day =   ('0' + (date.getDate())).slice(-2) 
 //   let month = date.getMonth() + 1;
    let month =   ('0' + (date.getMonth()+1)).slice(-2) 
    let year = date.getFullYear();
    
    return `${year}-${month}-${day}`;
  }
}

// const handleStringChange = (childComponent) => {

//   setIsLoading(true)
//   setDefaultMerchant({'value': childComponent.merchantId, 'label': childComponent.merchant_label})
//   setMerchantid(childComponent.merchant_mccCode)
//   setMerchantPrimaryKey(childComponent.merchantId)
//   setIsLoading2(true)
// };

const handleMerchantFilter=(selectedValue,selectedId,selectedLabel)=>{
  setIsLoading(true)
  setDefaultMerchant({'value': selectedId, 'label': selectedLabel})
  setMerchantid(selectedValue)
  setMerchantPrimaryKey(selectedId)
  setIsLoading2(true)
}

const fetchMerchantCount = async () => {

  // getDefaultDate('sd')
  // getDefaultDate('ed')

  let getMerchantCount = {
      // year : 2023,
      // year : selectedYear === 'year' ? 2023 : selectedYear
      startDate : startDate==""?getDefaultDate('sd'):startDate,
      endDate: endDate==""?getDefaultDate('ed'):endDate
    }
 //   console.log("getMerchantCount>>>",getMerchantCount)
  let signature=generateSignature(getMerchantCount)

  let response
  if(signature){
      // response= await apiRequest('get', `get-merchant-count?year=${getMerchantCount.year}`, signature)
    response= await apiRequest('get', `get-merchant-count?startDate=${getMerchantCount.startDate}&endDate=${getMerchantCount.endDate}`, signature)
  }
  // console.log("response?.data?.data?.data>>",response?.data?.data?.data)
  if(response?.data?.data?.data){
    let fianlData = response?.data?.data?.data

    setApiRequestData(response?.data?.data?.data);
    const totalCount = fianlData.reduce((accumulator, currentObject) => accumulator + currentObject.count, 0);
      setSum(totalCount)

  }
 
    };


const fetchActiveMerchantCount = async () => {
 // console.log("Date???",startDate,endDate)
  let getMerchantCount = {
    startDate : startDate==""?getDefaultDate('sd'):startDate,
    endDate: endDate==""?getDefaultDate('ed'):endDate,
    status: 'active'
  }
 // console.log("getMerchantCount???",getMerchantCount)
  setStartDate(getMerchantCount.startDate)
  setEndDate(getMerchantCount.endDate)

 // console.log("getMerchantCount???",getMerchantCount)

  let signature= await generateSignature(getMerchantCount)
  let response
    if(signature){
      // response= await apiRequest('get', `get-merchant-count?year=${getMerchantCount.year}&status=${getMerchantCount.status}`, signature)
      response= await apiRequest('get', `get-merchant-count?startDate=${getMerchantCount.startDate}&endDate=${getMerchantCount.endDate}&status=${getMerchantCount.status}`, signature)

    }
  if(response?.data?.data?.data){
    let fianlData = response?.data?.data?.data
    setActiveMerchant(response?.data?.data?.data);
    const totalCount = fianlData.reduce((accumulator, currentObject) => accumulator + currentObject.count, 0);
      setActiveSum(totalCount)
      setIsLoading(false)
    //console.log("merchantid>>>",merchantid)
    if(merchantid!=null && merchantid.length!=0){
      //console.log("merchantid2>>>",merchantid)
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
    // ref={ref}
  }
  else{
    setIsLoading(false)
  }

    

  };


// const handlePaymentProcessor = (paymentProcessor) => {
//   console.log("paymentProcessor>>",paymentProcessor)
//   setPaymentProcessor(paymentProcessor)
// };

const getWidgetForCollection = async (collection) => {
  switch (collection) {
    case "Payment":
      // return paymentWidget;
      return paymentTransactions;
    case "Refunds":
      return paymentTransactions;
    case "Payment Cancel":
      return paymentTransactions;
    case "Capture":
      return paymentTransactions;
    case "Preauth":
      return paymentTransactions;

      default:
        return paymentTransactions;
      }
};
  const handleDateChange=(e,type)=>{
   // console.log("date>>>",e.target.value)
    if(type=='sd')
      setStartDate(e.target.value)
    else  
      setEndDate(e.target.value)  
  }

  const getDetailsByDate=(user_role)=>{
     //console.log('hi>>',user_role)

     let myStartDate = startDate.split("-");
     let myEndDate = endDate.split("-");
     let  startDateVal = new Date( myStartDate[0], myStartDate[1] - 1, myStartDate[2]);
     let  endDateVal = new Date( myEndDate[0], myEndDate[1] - 1, myEndDate[2]);
     let startDateTimeStamp=Math.floor(startDateVal.getTime())
     let endtDateTimeStamp=Math.floor(endDateVal.getTime())
     let currentTimeStamp=Date.now()

     if(startDate!="" && endDate !=""){
     
      if(startDateTimeStamp>endtDateTimeStamp){
       // setErrorMsg('Date is invalid')
        // setErrorMsg({
        //   ...errorMsg,
        //   common:'Date is invalid'
        // })
        // return

        if(startDateTimeStamp>currentTimeStamp && endtDateTimeStamp>currentTimeStamp){
            setErrorMsg({
          //  ...errorMsg,
            common:'',
            startDateError:'Future Date is disallowed',
            endDateError:'Future Date is disallowed'
          })
        }
        else if(startDateTimeStamp>currentTimeStamp){
          setErrorMsg({
            ...errorMsg,
            common:'',
            startDateError:'Future Date is disallowed'
          })
       //   console.log("flag-2")
        }
        else if(endtDateTimeStamp>currentTimeStamp){
          setErrorMsg({
            ...errorMsg,
            common:'',
            endDateError:'Future Date is disallowed'
          })
        //  console.log("flag-3")
        }

        else {
          setErrorMsg({
            endDateError:'',
            startDateError:'',
            common:'Date is invalid'
          })
        }
        return 
      }
      else{
          if(startDateTimeStamp>currentTimeStamp && endtDateTimeStamp>currentTimeStamp){
            setErrorMsg({
            ...errorMsg,
            startDateError:'Future Date is disallowed',
            endDateError:'Future Date is disallowed'
          })
          return
        }
        else if(startDateTimeStamp>currentTimeStamp){
          setErrorMsg({
            ...errorMsg,
            endDateError:'',
            startDateError:'Future Date is disallowed'
          })
          return
        }
        else if(endtDateTimeStamp>currentTimeStamp){
          setErrorMsg({
            ...errorMsg,
            startDateError:'',
            endDateError:'Future Date is disallowed'
          })
          return
        }
        
      }
  
     }
    else if(startDate!=""){
      if(startDateTimeStamp>currentTimeStamp){
        setErrorMsg({
          common:'',
          startDateError:'Future Date is disallowed',
          endDateError:''
        })
     //   console.log("flag-2")
      }
      return 
    }
    else if(endDate!=""){
      if(endtDateTimeStamp>currentTimeStamp){
        setErrorMsg({
          ...errorMsg,
          startDateError:'',
          endDateError:'Future Date is disallowed'
        })
    }
    return 
   }
  
    if(user_role=='SUPERADMIN'){
      //console.log('hi>>',user_role)
      setErrorMsg({common:'',startDateError:'',endDateError:''})
      setIsLoading(true)
      fetchMerchantCount()
      fetchActiveMerchantCount()
    }
    else{
      setIsLoading(true)
      fetchSumAmount()
    }
    
  }
  return (
    <React.Fragment>
      <Head title="Homepage"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                Dashboard
              </BlockTitle>
            </BlockHeadContent>


            <BlockHeadContent>
           
            </BlockHeadContent>

            {/* <Col sm="6"> */}
                <div className="form-group" style={{marginRight:"-172px"}}>
                  {/* <label className="form-label">Start Date</label> */}
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="default-01">
                    Start Date
                    </label>
                </div>
                  {/* <Row className="gx-2"> */}
                    <div className="w-62">
                      <div className="form-control-wrap">
                        {/* <DatePicker
                          // selected={startDate}
                          // onChange={(date)=>setStartDate(date)}
                          // // onChange={(date) => updateEvent({ ...event, start: setDateForPicker(date) })}
                          // className="form-control date-picker"
                          // dateFormat="yyyy/MM/dd"
                          name="startDate"
                          className="form-control date-picker"
                          type="text"
                          size="sm"
                          placeholder=""
                          selected={startDate}
                          minDate={new Date()}
                          // value={date}
                          onChange={(date)=>setStartDate(date)}
                          dateFormat="yyyy/MM/dd"
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                        /> */}
                          {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} /> */}

                          <input
                          type="date"
                          className="form-control-lg form-control"
                          onChange={(date)=>handleDateChange(date,'sd')}
                          value={startDate}
                          // value="2013-01-08"
                          // ref={dateInputRef}
                        />
                      </div>
                      {
                 
                        errorMsg.common!=''?<span style={{color:'red',position: 'absolute',fontSize: '12px'}} className="invalid">{`* `+errorMsg.common}</span>
                        :null
                      }

                      {
                                      
                        errorMsg.startDateError!=''?<span style={{color:'red',position: 'absolute',fontSize: '12px'}} className="invalid">{`* `+ errorMsg.startDateError}</span>
                        :null
                      }
                    </div>
                  {/* </Row> */}
                </div>
              
                <div className="form-group"  style={{marginRight:"-142px"}}>
                  {/* <label className="form-label">Start Date</label> */}
                  <div className="form-label-group">
                    <label className="form-label" htmlFor="default-01">
                    End Date
                    </label>
                </div>
                  {/* <Row className="gx-2"> */}
                    <div className="w-62">
                      <div className="form-control-wrap">
                        {/* <DatePicker
                          // selected={new Date(event.start)}
                          selected={startDate}
                          // onChange={(date) => updateEvent({ ...event, start: setDateForPicker(date) })}
                          className="form-control date-picker"
                          header =""
                        /> */}
                        {/* <input
                          type="date"
                          className="form-control-lg form-control"
                          // onChange={handleChange}
                          // ref={dateInputRef}
                        /> */}
                         <input
                          type="date"
                          className="form-control-lg form-control"
                          onChange={(date)=>handleDateChange(date,'ed')}
                          value={endDate}
                          // value="2013-01-08"
                          // ref={dateInputRef}
                        />
                      </div>
                      {
                 
                        errorMsg.common!=''?<span style={{color:'red',position: 'absolute',fontSize: '12px'}} className="invalid">{`* `+ errorMsg.common}</span>
                        :null
                      }
                       {
                 
                          errorMsg.endDateError!=''?<span style={{color:'red',position: 'absolute',fontSize: '12px'}} className="invalid">{`* `+ errorMsg.endDateError}</span>
                          :null
                        }
                    </div>
                  {/* </Row> */}
                </div>
                {/* {
                 
                 errorMsg!=''?<span style={{color:'red'}} className="invalid">{`* `+errorMsg}</span>
                 :null
               } */}
                {/* <Button color="primary" size="sm" className="btn-block" onClick={() => getDetailsByDate()}>
                  
                </Button> */}
                <Button  style={{"fontSize":"15px",marginLeft:"-36px","height":"36px",marginTop:"7px"}} disabled={startDate==''} color="primary" size="md" type="submit" onClick={() => getDetailsByDate(userDetails.user_role)} >
                    Submit
              </Button>
              {/* </Col> */}
          </BlockBetween>
        </BlockHead>
        <Block>
       
          {
            isLoading ?(
              <Loader/>
            )
              :
              <>
                    <Row className="g-gs graphCards">
        {userDetails.user_role === 'SUPERADMIN' && (

                <Col lg="12" xxl="12"> {/* Adjusted the column size */}
                  <Row className="g-gs">
                    <Col sm="12" lg="6" xxl="6"> {/* Adjusted the column size */}
                      <PreviewAltCard>
                      {/* {console.log("apiRequestData>>>",apiRequestData)} */}
                        {/* <ActiveSubscription activeMerchant={apiRequestData} sum={sum} message="Merchant Register Count" showBarChat="true" /> */}
                        <ActiveSubscription   activeMerchant={apiRequestData} sum={sum} message="Merchant Register Count" showBarChat="true" user_role={userDetails}/>

                      </PreviewAltCard>
                    </Col>
                    <Col sm="12" lg="6" xxl="6"> {/* Adjusted the column size */}
                      <PreviewAltCard>
                        {/* <ActiveSuion activeMerchant={activeMerchant} sum={activesum}  message="Active Merchant Register Count" showBarChat="true"/> */}
                        <ActiveSubscription activeMerchant={activeMerchant} sum={activesum}  message="Active Merchant Register Count" showBarChat="true" user_role={userDetails}/>

                      </PreviewAltCard>
                    </Col>
                  </Row>
                </Col>
                  )}
              </Row>
              <Row className="g-gs">
                {/* {userDetails.user_role === 'ADMIN' && ( */}
                {userDetails.user_role === 'OWNER' && (
      
                  <Row className="g-gs blueLi">
                  {Array.isArray(sumAmount) &&
                    sumAmount.map((item, index) => (
                      item.successSumAmount !== 0 &&
                      item.successSumAmount !== null &&
                      (item.collection === "Payment" ||
                        item.collection === "Refunds" ||
                        item.collection === "Payment Cancel") && (
                          <Col sm="6" lg="4" xxl="3" key={index}>
                            <PreviewAltCard>
                              <ActiveSubscription activeMerchant={activeMerchant} sum={item.successSumAmount} message={item.collection} showBarChat="false" paymentTransactions={paymentTransactions} user_role={userDetails} />
                            </PreviewAltCard>
                          </Col>
                        )
                    ))}
                </Row>
                
                )}
            {
            //  console.log("isLoading2>>>",isLoading2)
            }

            {/* {
              isLoading2 ?(
                <Loader/>
              )
              : */}
              {/* <div ref={scollToRef} >

              </div> */}
              <Row className="g-gs"  >
         
              { 
              userDetails.user_role == 'SUPERADMIN' &&(
        <Col md="4 offset-lg-8">
              {/* <div className="card-tools"> */}
                {/* <Col md="6"> */}
                <div className="form-group">
                            <label className="form-label">Merchants</label>
                            <div className="form-control-wrap">
                          
                              {
                                Object.keys(defaultMerchant).length>0?
                                <RSelect
                                defaultValue={defaultMerchant}
                                options={merchantName}
                                name="payment_processor"
                                onChange={(selectedOption) => {
                                  const selectedId = selectedOption ? selectedOption.id : null;
                                  const selectedValue = selectedOption ? selectedOption.value : null;
                                  const selectedLabel = selectedOption ? selectedOption.label : null;
                                // setFilterBymerchantName({ "merchant_mccCode": selectedValue, "merchantId" : selectedId,"merchant_label" : selectedLabel});
                                handleMerchantFilter(selectedValue,selectedId,selectedLabel)

                                }}
                              />
                                :
                                null
                              }
                          
                            </div>
                          </div>
                  {/* </Col> */}
              {/* </div> */}
              </Col>
              )}

              {
                isLoading2 ?(
                <Loader/>
                )
                :
                <>
                   <Col xxl="8"  ref={ref}>
                  <Card className="card-bordered card-full">
                    <TransactionTable paymentTransactions = {paymentTransactions}/>
                  </Card>
                </Col>
                <Col xxl="4" md="6">
                  <Card className="card-bordered card-full">
                    <RecentActivity refundTransactions= {refundTransactions} />
                  </Card>
                </Col>
                <Col xxl="4" md="6">
                  <Card className="card-bordered card-full">
                    <NewsUsers voidTransactions={voidTransactions}/>
                  </Card>
                </Col>
                </>
              }                  
              {/* <Col xxl="8">
                <Card className="card-bordered card-full">
                  <TransactionTable paymentTransactions = {paymentTransactions}/>
                </Card>
              </Col>
              <Col xxl="4" md="6">
                <Card className="card-bordered card-full">
                  <RecentActivity refundTransactions= {refundTransactions} />
                </Card>
              </Col>
              <Col xxl="4" md="6">
                <Card className="card-bordered card-full">
                  <NewsUsers voidTransactions={voidTransactions}/>
                </Card>
              </Col> */}
              {/* <Col lg="6" xxl="4">
                <Card className="card-bordered h-100">
                  <Support />
                </Card>
              </Col>
              <Col lg="6" xxl="4">
                <Card className="card-bordered h-100">
                  <Notifications />
                </Card>
              </Col> */}
              </Row>
            {/* // } */}
         
          </Row>
              </>
            
          }
    
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default Homepage;
