import React, { useEffect, useState } from "react";
import PageContainer from "../../../layout/page-container/PageContainer";
import Head from "../../../layout/head/Head";
import AuthFooter from "../../../pages/auth/AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
  Col,
  RSelect,
} from "../../../components/Component";
import {
  DropdownMenu,
  DropdownToggle,
  
  UncontrolledDropdown,
  Modal,
  ModalBody,
  DropdownItem,
  Form,
} from "reactstrap";
import { Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link,useParams,useLocation } from "react-router-dom";
import { filterRole, filterStatus, userData } from "../../../pages/pre-built/user-manage/UserData";
import { generateSignature, decryptToken } from "../../../helpers/signature";
import { apiRequest } from "../../../helpers/api";
import { computeSmallestCellWidth } from "@fullcalendar/react";
import Logo from "../../../images/evvopay_logo.png";
import Loader from "../../../helpers/Loader";

const MerchantProfile = () => {

  const userDetails = JSON.parse(decryptToken(localStorage.getItem('accessToken')))

  const updateFormFormat={
    country_code:'',
    country_name:'',
    currency:'',
    symbol:'',
    merchant_name:'',
    mcc_code:'',
   // business_website:'',
    business_scope:'',
    company_legal_name:'',
    company_registration_no:'',
    company_address:'',
    callback_url:'',
    business_email:'',
    payment_mode:'',
    processing_currency:'',
    settlement_currency:'',
    telephone_no:'',
    merchant_userid:'',
    merchant_password:'',
    connection_string:'',
    director_name:'',
    id_no:'',
    identity_type:'',
    nationality:'',
    residential_address:'',
    director_dob:'',
    is_active:'',
    payment_processor_id:'',
    postal_code:'',
    private_key:'',
    public_key:''

 //   merchantid:'',

    // email_id:'',
    // payment_mode:'',
    // processing_currency:'',
    // settlement_currency:'',
    // password:''
  }
  const userStatusValue=[
    {'value':'1','label':'Activate'},{'value':'0','label':'Suspend'}
  ]
    
  
  const [updateFormData,setUpdateFormData]=useState(updateFormFormat)
  const [statusValue,setStatusValue]=useState(null)
  const [paymentProcStatusValue,setPaymentProcStatusValue]=useState("")
  const [paymentProcessorData, setPaymentProcessorData] = useState([]);
  const [paymentProcessorStatus, setPaymentProcessorStatus] = useState(false);
  const [userStatus, setUserStatus] = useState(userStatusValue);
  const [passState, setPassState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [fieldErrorMsg, setFieldErrorMsg] = useState([]);
  const [userData, setUserData] = useState(userDetails || null);

  const { errors, register, handleSubmit,setError } = useForm();

  const location = useLocation();
//   const [userUid, setUid] = useState(location.state.userid);
  const [userUid, setUid] = useState(29);
  const [successMsg,setSuccessMsg]=useState(null);
  const [paymentProcessor, setPaymentProcessor] = useState("");

  const [files, setFiles] = useState(null);
  const [uploadKeyStatus, setUploadKeyStatus] = useState(false);
  const [uploadKeyStatus2, setUploadKeyStatus2] = useState(false);
  const [fileErrorStatus, setFileErrorStatus] = useState(false);
  const [fileErrorStatus2, setFileErrorStatus2] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // const accessToken=JSON.parse(localStorage.getItem('accessToken'))
  // const decrypt = decryptToken(localStorage.getItem('accessToken'))

  //const accessToken = decrypt;
  useEffect(()=>{
      getMerchantsData()
     getPaymentProcessData()
  },[])

    // fetching merchant record
    const getMerchantsData=async()=>{
     
       let data={
         "userid":userUid
       }
      let signature=generateSignature(data)
   
       let response=await apiRequest('get',`get-merchant-record?userid=${userUid}`,signature)
       
      //  console.log(response.data.data.data, '-<, response ??')
       let responseArr = response.data.data.data;

      //  let paymentProcessorName = responseArr.map((el) => el.paymentProcessorData?.[0]?.processor_name);
      let paymentProcessorName = responseArr.map((el) => el.paymentProcessorData?.[0]?.processor_name)
                                            .filter((name) => typeof name === 'string').join(', ').toUpperCase()
      //  console.log(paymentProcessorName, '-<< paymentProcessorName')
        setPaymentProcessor(paymentProcessorName);

       if(response.status==200 || response.status==201){

          let data=response.data.data.data

          //console.log("responseData>>",data)
          updateFormData.merchant_name=data[0].merchantData.merchant_name
          updateFormData.merchantid=data[0].merchantData.merchantid
          updateFormData.mcc_code=data[0].merchantData.mcc_code
          updateFormData.business_scope=data[0].merchantData.business_scope
          updateFormData.company_legal_name=data[0].merchantData.company_legal_name
          updateFormData.company_registration_no=data[0].merchantData.company_registration_no
          updateFormData.company_address=data[0].merchantData.company_address
          updateFormData.callback_url=data[0].merchantData.callback_url
          updateFormData.business_email=data[0].merchantData.business_email
          updateFormData.processing_currency=data[0].merchantData.processing_currency
          updateFormData.settlement_currency=data[0].merchantData.settlement_currency
          updateFormData.telephone_no=data[0].merchantData.telephone_no
          updateFormData.merchant_userid=data[0].merchantData.merchant_userid
          updateFormData.merchant_password=data[0].merchantData.merchant_password
          updateFormData.connection_string=data[0].merchantData.connection_string
          updateFormData.is_active=data[0].merchantData.is_active
          
          
          if(data[1].merchantDirectorData!=null){
          //  console.log("data[1].merchantDirectorData>>",data[1].merchantDirectorData)
            updateFormData.director_name=data[1].merchantDirectorData.director_name
            updateFormData.id_no=data[1].merchantDirectorData.id_no
            updateFormData.identity_type=data[1].merchantDirectorData.identity_type
            updateFormData.residential_address=data[1].merchantDirectorData.residential_address
            updateFormData.director_dob=data[1].merchantDirectorData.director_dob
            updateFormData.nationality=data[1].merchantDirectorData.nationality
          }
          if(data[2].merchantCountryData!=null){
           // console.log("data[3].merchantCountryData>>",data[2].merchantCountryData)
            updateFormData.country_code=data[2].merchantCountryData[0].country_code
            updateFormData.country_name=data[2].merchantCountryData[0].country_name
            updateFormData.currency=data[2].merchantCountryData[0].currency
            updateFormData.symbol=data[2].merchantCountryData[0].symbol
            updateFormData.postal_code=data[2].merchantCountryData[0].postal_code
          }
          // console.log("updateFormData>>>",updateFormData)
          if(data[3].paymentProcessorData!=null){
          //  console.log("data[3].paymentProcessorData>>",data[3].paymentProcessorData[0])
            updateFormData.payment_processor_id=data[3].paymentProcessorData[0].payment_processor_id
            updateFormData.public_key = data[3].paymentProcessorData[0].publicKey == 0 ? null : data[3].paymentProcessorData[0].publicKey
            updateFormData.private_key = data[3].paymentProcessorData[0].privateKey == 0 ? null : data[3].paymentProcessorData[0].privateKey
          }
         // console.log("updateFormDatapaymentProcessorData>>>",updateFormData)
        //  let paymentProcStatusValue= data[3].paymentProcessorData!=null?
        //  {'value': data[3].paymentProcessorData[0].payment_processor_id, 'label': data[3].paymentProcessorData[0].processor_name}
        //    :
        //  {'value': '', 'label': 'Select'}

        let paymentProcStatusValue= data[3].paymentProcessorData!=null?data[3].paymentProcessorData[0].payment_processor_id:""
    


         let statusValue=updateFormData.is_active?{value: '1', label: 'Activate'}:{value: '0', label: 'Suspend'}
         updateFormData.is_active=updateFormData.is_active?'1':'0'
         setPaymentProcStatusValue(paymentProcStatusValue)
         setStatusValue(statusValue)
          setUpdateFormData(updateFormData)
          setIsLoading(false);
          //console.log("updateFormData>>>",updateFormData)
        //  setUpdateFormData(updateFormData)

       }
       else{

       // console.log("responseError>>",response.data)
         setIsLoading(false);
         return []
       }
     
     }

      // fetching payment processor record
    const getPaymentProcessData=async()=>{
 
       let data={
         "type":"open"
       }
      let signature=generateSignature(data)
   
       let response=await apiRequest('get',`get-payment-processor?type=open`,signature)
   //    console.log("responsesetPaymentProcessorData>>>",response.data.data.data)
       if(response.status==200 || response.status==201){

          if(response.data.data.data.length>0){
            let filterData=response.data.data.data.map((item)=>{
                return {'value':item.payment_processor_id,'label':item.name.toUpperCase()}
            })
            setPaymentProcessorData(filterData)
          }
            

           else
             setPaymentProcessorData([])
      
       }
       else{
         return []
       }
     
     }

  const handleFormSubmit = async() => {

    
   // console.log("updateFormData.payment_processor_id>>",updateFormData.payment_processor_id)
    // if(userData.user_role=='SUPERADMIN' && updateFormData.payment_processor_id==''){
    //   console.log("s1")
    //   setPaymentProcessorStatus(true)
    //   return
    // }
    if(userData.user_role=='SUPERADMIN' && fileErrorStatus || fileErrorStatus2){
      //setLoading(false);
    //  console.log("s2")
      setPaymentProcessorStatus(true)
      return
    }
    
   // setLoading(true);


    setLoading(true);
     setErrorMsg('')
     setFieldErrorMsg([])
     setErrorStatus(false)
     setSuccessMsg(null)
     setUploadKeyStatus(false)
     setUploadKeyStatus2(false)
     //console.log(files)

    let formData=null
    let userid=null
    userid=userData.user_id
    formData={
      ...updateFormData,
      userid:userid
    }
    // console.log("formDatabefore>>>",formData)
    // console.log("userUid>>>",userUid)

    //formData.is_active=='1'?true:false
    if(userData.user_role=='SUPERADMIN'){
      formData.u_id=userUid
    }
    else{
      delete formData.mcc_code
      delete formData.merchant_userid
      delete formData.merchant_password
      delete formData.is_active
      delete formData.payment_processor_id
      delete formData.private_key
      delete formData.public_key
   //   delete formData.userid
      //userid=userUid
    }

    //formData.userid=
    let signature=generateSignature(formData)
    
   
    //console.log("formDataforapi>>",formData)
    let response=await apiRequest('put',`update-merchant-records?userid=${formData.userid}`,signature,formData)
 //  console.log("responseData>>>>",response)
    if(response.status==200 || response.status==201){
      
      setLoading(false)
      setErrorStatus(false)
      setErrorMsg('')
      setFieldErrorMsg([])
      
      getMerchantsData()
     
      if( userData.user_role=='SUPERADMIN'){
     //   console.log("formDataresponse>>",formData)
        getPaymentProcessData()
      //  setSuccessMsg('Records Updated successfully ')
        // if(formData.is_active=='1' && formData.payment_processor_id!=''  && formData.mcc_code!='' && formData.merchant_userid!='' && formData.merchant_password!=''){
        //   setSuccessMsg('Records Updated successfully and mail has been sent to your registered user mail ')
        // }
        // else{
        //   setSuccessMsg('Records Updated successfully ')

        // }
      }
      setSuccessMsg('Records Updated successfully ')
      // else{
      //   setSuccessMsg('Records Updated successfully ')
      // }
     //  setTimeout(() => history.push(`${process.env.PUBLIC_URL}/auth-success`), 2000);
    }
    else if(response.status==500){
      setSuccessMsg(null)
      setLoading(false);
      setErrorStatus(true)
      setErrorMsg("Something went wrong,Kindly try again later")
    }
    else if (response.status==400){
      setSuccessMsg(null)
      setLoading(false);
      setErrorStatus(false)
      setErrorStatus(true)
    //  console.log("response.data.data.data>>>",response.data.data)
      if(Array.isArray(response.data.data)){
        let errorMsgList=[]
        response.data.data.map((data)=>{
          errorMsgList.push(data.message)
        })
        setFieldErrorMsg(errorMsgList)
      }
      else{
        setErrorMsg(response.data.data.data)
      }
    }
    else if (response.status==401){
      setSuccessMsg(null)
      setLoading(false);
      setErrorStatus(true)
      setErrorMsg(response.data.message)
    }
   else{
    setSuccessMsg(null)
    setLoading(false);
    setErrorStatus(true)
    setErrorMsg('Something went wrong,Kindly try again later')
   }
   };


   const readFile=(file,type)=>{

    const fileReader = new FileReader();
    fileReader.readAsText(file.target.files[0], "UTF-8");
       

    fileReader.onload = e => {
     // setFiles(e.target.result);

      //console.log("fileReader>>>",type)

      if(type=='pr_k'){
       // updateFormData.private_key=e.target.result

        if(file.target.files[0].type!='text/plain'){
          setError("private_key",{
            type:"manual",
            message:"Only text file allowed"
          })
          updateFormData.private_key=''
         setFileErrorStatus2(true)
        
        }
        else{
          updateFormData.private_key=e.target.result
          setFileErrorStatus2(false)
         
        }

        setUpdateFormData(updateFormData)
      }
      
      else{

        if(file.target.files[0].type!='text/plain'){
          setError("public_key",{
            type:"manual",
            message:"Only text file allowed"
          })
          updateFormData.public_key=''
         setFileErrorStatus(true)
        }
        else{
          updateFormData.public_key=e.target.result
          setFileErrorStatus(false)
        
        }
        setUpdateFormData(updateFormData)
        
      }
       
      

      //console.log("loading>>",loading)
     // setUpdateFormData(updateFormData)
    };
   
   }

   const setKeyField=(type)=>{

    // setUploadKeyStatus(true)
    
    if(type=='pr_k'){
      setUploadKeyStatus(true)
      updateFormData.private_key=''
      setUpdateFormData(updateFormData)
    }
     
    else{
      setUploadKeyStatus2(true)
      updateFormData.public_key=''
      setUpdateFormData(updateFormData)
    }
      
   }

   const setPaymentProcessorOption=(e)=>{

    let index = e.nativeEvent.target.selectedIndex;
    let label = e.nativeEvent.target[index].text;
    setPaymentProcessor(label)
    setUpdateFormData({...updateFormData,payment_processor_id:e.target.value})
        // onChange={(e) => {
                            //  setPaymentProcessor(e.label)
                            //  console.log("e>>>",e)
                            // // setPaymentProcessorStatus(false)
                            // setUpdateFormData((prevFormData) => ({
                            // ...prevFormData,
                            // payment_processor_id: e.target.value,
                            // }));
                            // }}
   }

   const handlePaymentProcessorSelect=(e)=>{
    let index = e.nativeEvent.target.selectedIndex;
    let label = e.nativeEvent.target[index].text;
   // console.log("data>>>",e.target.value)
   // console.log("data>>>",label)
    setPaymentProcessor(label);
   

  //  setPaymentProcStatusValue({'value': null, 'label': null})
  setPaymentProcStatusValue(e.target.value)
    setUpdateFormData((prevFormData) => ({
      ...prevFormData,
      payment_processor_id: e.target.value,
    }));
   }
  return (


    <React.Fragment>
      <Head title="Register" />
    
     
        <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center" style ={{marginTop:'60px'}}>
            <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg"  src={Logo} alt="logo-dark" />
            </Link>
          </div>
          {
               isLoading ?(
                <Loader />
              )
              :
              <PreviewCard  className="card-bordered" bodyClass="card-inner-lg">
              <BlockHead>
                <BlockContent>
                  <BlockTitle tag="h4">Register User</BlockTitle>
                </BlockContent>
              </BlockHead>
              <Form className="row gy-4" onSubmit={handleSubmit(handleFormSubmit)}>
            
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="merchant_name"
                          defaultValue={updateFormData.merchant_name}
                          placeholder="Enter name"
                          onChange={(e)=>setUpdateFormData(currValue=>({
                            ...currValue,
                            merchant_name:e.target.value
                          }))}
                        />
                     
                        {/* {errors.merchant_name && <span className="invalid">{errors.merchant_name.message}</span>} */}
                      </div>
                    </Col>
                                       
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Email</label>
                        <input
                          className="form-control"
                          type="text"
                          name="email"
                          defaultValue={updateFormData.business_email}
                          onChange={(e)=>setUpdateFormData(currValue=>({
                           ...currValue,
                           business_email:e.target.value.toLowerCase()
                         }))}
                      //    defaultValue={formData.email}
                          placeholder="Enter email"
                          // ref={register({
                          //   required: "This field is required",
                          //   pattern: {
                          //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          //     message: "invalid email address",
                          //   },
                          // })}
                        />
                        {/* {errors.email && <span className="invalid">{errors.email.message}</span>} */}
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Password</label>
                        <input
                          className="form-control"
                          type="text"
                          name="password"
                          defaultValue={updateFormData.business_email}
                          onChange={(e)=>setUpdateFormData(currValue=>({
                           ...currValue,
                           business_email:e.target.value.toLowerCase()
                         }))}
                      //    defaultValue={formData.email}
                          placeholder="Enter your password"
                          // ref={register({
                          //   required: "This field is required",
                          //   pattern: {
                          //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          //     message: "invalid email address",
                          //   },
                          // })}
                        />
                        {/* {errors.email && <span className="invalid">{errors.email.message}</span>} */}
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Phone Number</label>
                        <input
                          className="form-control"
                          type="text"
                          name="telephone_no"
                        //  defaultValue={updateFormData.telephone_no}
                         defaultValue= "90000000000"
                         onChange={(e)=>setUpdateFormData(currValue=>({
                          ...currValue,
                          telephone_no:e.target.value
                        }))}
                          placeholder="Enter telephone no"
                          // ref={register({ required: "This field is required" })}
                        />
                        {/* {errors.callbackurl && <span className="invalid">{errors.callbackurl.message}</span>} */}
                      </div>
                    </Col>
                    {/* {
                      userData != null && userData.user_role === 'SUPERADMIN' ? ( */}
                        {/* <> */}
                          {statusValue != null ? (
                            <Col md="6">
                              <div className="form-group">
                                <label className="form-label">Role</label>
                                <div className="form-control-wrap">
                                  <RSelect
                                    options={[
                                      { value: 'ADMIN', label: 'ADMIN' },
                                      { value: 'SUB ADMIN', label: 'SUB ADMIN' }
                                    ]}
                                    defaultValue={{
                                      value: 'ADMIN',
                                      label: 'ADMIN',
                                    }}
                                    name="user_role"
                                    onChange={(e) =>
                                      setUpdateFormData((currValue) => ({
                                        ...currValue,
                                        user_role: e.value,
                                      }))
                                    }
                                    isDisabled={userData.user_role !== 'SUPERADMIN'} // Disable for non-SUPERADMIN users
                                  />
                                </div>
                              </div>
                            </Col>
                          ) : null}
                        {/* </>
                      ) : null */}
                     {/* } */}

                    {
                      userData != null && userData.user_role === 'SUPERADMIN' ? (
                        <>
                          {statusValue != null ? (
                            <Col md="6">
                              <div className="form-group">
                                <label className="form-label">Status</label>
                                <div className="form-control-wrap">
                                  <RSelect
                                    options={userStatus}
                                    defaultValue={{
                                      value: statusValue.value,
                                      label: statusValue.label,
                                    }}
                                    name="is_active"
                                    onChange={(e) =>
                                      setUpdateFormData((currValue) => ({
                                        ...currValue,
                                        is_active: e.value,
                                      }))
                                    }
                                    isDisabled={userData.user_role !== 'SUPERADMIN'} // Disable for non-SUPERADMIN users
                                  />
                                </div>
                              </div>
                            </Col>
                          ) : null}
                        </>
                      ) : null
                    }
                    {
                      errorStatus==true?
                      errorMsg!=''?<span style={{color:'red'}} className="invalid">{`* `+errorMsg}</span>
                      :null
                      :null
                    }
  
                  {
                  fieldErrorMsg.length>0?
                  fieldErrorMsg.map((msg,key)=>{
                    return <div  key ={key} style={{color:'red'}}><span  className="invalid">{`* `+msg}</span></div>
                  })
                  :null
                }
                {successMsg && 
               <div  style={{color:'green'}}><span  >{successMsg}</span></div>
               }
  
         
  
                    <Col size="12">
                      <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                          <Button  style={errorStatus?{marginTop:'10px'}:{}} color="primary" size="md" type="submit"
                          >
                               { loading ? <Spinner size="sm" color="light" /> : "Register User"}
                          </Button>
                        </li>
                   
                      </ul>
                    </Col>
                  </Form>
         
            </PreviewCard>
          }
         
        </Block>
        {/* <AuthFooter /> */}
      </PageContainer>
      
    
    </React.Fragment>
  );
};
export default MerchantProfile;
