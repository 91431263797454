import React, { useEffect, useState } from "react";
import PageContainer from "../../../layout/page-container/PageContainer";
import Head from "../../../layout/head/Head";
import AuthFooter from "../../../pages/auth/AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
  Col,
  RSelect,
} from "../../../components/Component";
import {
  DropdownMenu,
  DropdownToggle,

  UncontrolledDropdown,
  Modal,
  ModalBody,
  DropdownItem,
  Form,
} from "reactstrap";
import { Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link, useParams, useLocation } from "react-router-dom";
import { filterRole, filterStatus, userData } from "../../../pages/pre-built/user-manage/UserData";
import { generateSignature, decryptToken } from "../../../helpers/signature";
import { apiRequest } from "../../../helpers/api";
import { computeSmallestCellWidth } from "@fullcalendar/react";
import Logo from "../../../images/evvopay_logo.png";
import Loader from "../../../helpers/Loader";
import { USER_MODULE } from "../../../constants";
import { getAccessStatus } from "../../../helpers/common";

const AddUser = ({ history }) => {

  const userDetails = JSON.parse(decryptToken(localStorage.getItem('accessToken')))

  const registerForm = {
    name: '',
    email_id: '',
    telephone_no: '',
    // password:'',
    user_role_id: ''
  }
  const [registerFormData, setRegisterFormData] = useState(registerForm)
  const [passState, setPassState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [fieldErrorMsg, setFieldErrorMsg] = useState([]);
  const [submitSuccessMsg, setSubmitSuccessMsg] = useState(null);
  const { watch, errors, register, handleSubmit } = useForm();
  const [merchant, setMerchant] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confimPasswordErrorMessage, setConformPasswordErrorMessage] = useState('');
  const [phoneNoErrorMessage, setPhoneNoErrorMessage] = useState('');
  const [userRolesData, setUserRolesData] = useState([]);
  const [userData, setUserData] = useState(userDetails || null);
  const [merchantIds, setMerchantIds] = useState([]);
  const [merchantName, setMerchantName] = useState('');

  //  const password_val =useRef()
  //  password_val.current=watch('password')

  const handleMerchantChange = (event) => {
    setMerchant(event.target.value);
    setRegisterFormData(currValue => ({
      ...currValue,
      merchant_name: event.target.value
    }))
  };

  const handlePhoneNoChange = (event) => {
    setPhoneNo(event.target.value);
    setRegisterFormData(currValue => ({
      ...currValue,
      telephone_no: event.target.value
    }))
  };

  const handlePasswordChange = (event) => {
    //setPassword(event.target.value);
    setRegisterFormData(currValue => ({
      ...currValue,
      password: event.target.value
    }))
  };


  const handleRoleChange = (status) => {


    setRegisterFormData(({
      ...registerFormData,
      user_role_id: status.value
    }))

  };


  const validateConfirmPassword = () => {
    if (password !== confirmPassword) {
      setConformPasswordErrorMessage('Passwords do not match');
      return;
    } else {
      setConformPasswordErrorMessage('');
    }
  };

  const fetchMerchantIds = async () => {
    let obj = { filter: 'all' };
    let signature = generateSignature(obj);
    try {
      if (signature) {
        const response = await apiRequest('get', `get-merchant-ids?filter=${obj.filter}`, signature);

        if (response.status === 200 || response.status === 201) {
          const responseData = response?.data?.data?.data;
          if (responseData) {
            const filterData = responseData.map((item) => ({
              value: item.merchant_id,
              label: item.merchant_name || item.merchant_id,
              id: item.id,
              userId: item.user_id
            }));

            setMerchantIds(filterData);
            setMerchantName(filterData);
          } else {
            setMerchantIds([]);
            setMerchantName([]);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching merchant IDs:', error);
    }
  };


  useEffect(async () => {

    // console.log("userData>>>",userData)
    let { readStatus, writeStatus, deleteStatus } = getAccessStatus(userData, USER_MODULE)
    if (!readStatus) {
      history.push(`${process.env.PUBLIC_URL}/errors/504-classic`);
    }

    setErrorMsg('')
    setFieldErrorMsg([])
    setErrorStatus(false)
    await getUserRoles()
    fetchMerchantIds()
  }, [])

  const getUserRoles = async () => {

    let data = {
      "type": "open"
    }
    let signature = generateSignature(data)

    let response = await apiRequest('get', `get-user-roles?type=open`, signature)
    if (response.status == 200 || response.status == 201) {

      if (response.data.data.data.length > 0) {
        setUserRolesData(response.data.data.data)
      }


      else
        setUserRolesData([])

    }
    else {
      setUserRolesData([])
      return []

    }

  }

  const callEndpoint = async (data) => {

    let signature = generateSignature(data);

    let response = await apiRequest('post', 'add-sub-user', signature, data);
    if (response.status == 200 || response.status == 201) {
      setErrorStatus(false)
      setErrorMsg('')
      setFieldErrorMsg([])
      setSubmitSuccessMsg("User created successfully")
      setTimeout(() => history.push(`${process.env.PUBLIC_URL}/user-list`), 2000);
    }
    else if (response.status == 500) {
      setLoading(false);
      setErrorStatus(true)
      setErrorMsg("Something went wrong,Kindly try again later")
      setSubmitSuccessMsg(null)
    }
    else if (response.status == 400) {
      setLoading(false);
      setErrorStatus(false)
      setErrorStatus(true)
      setSubmitSuccessMsg(null)
      //  console.log("response.data.data.data>>>",response.data.data)
      if (Array.isArray(response.data.data)) {
        let errorMsgList = []
        response.data.data.map((data) => {
          errorMsgList.push(data.message)
        })
        setFieldErrorMsg(errorMsgList)
        // console.log("fieldErrorMsg>>>",errorMsgList)
      }
      else {
        setErrorMsg(response.data.data.data)
      }
    }
    else if (response.status == 401) {
      setLoading(false);
      setErrorStatus(true)
      setErrorMsg(response.data.message)
      setSubmitSuccessMsg(null)
    }
    else {
      setLoading(false);
      setErrorStatus(true)
      setErrorMsg('Something went wrong,Kindly try again later')
      setSubmitSuccessMsg(null)
    }
  };


  const handleFormSubmit = async () => {
    setLoading(true);
    setErrorMsg('')
    setFieldErrorMsg([])
    setErrorStatus(false)



    let data;
    if (userDetails.user_role === 'SUPERADMIN') {
      data = {
        ...registerFormData
      }
    } else {
      data = {
        ...registerFormData,
        userid: userData.user_id
      }

    }

    if (userDetails.user_role === 'SUPERADMIN' && data) {
      data.userid = data.userId
      delete data.userId;
      if (data.userid == '' || data.userid == null) {
        // setLoading(false);
        setErrorStatus(true)
        setErrorMsg("ImProper Merchant.")
        setSubmitSuccessMsg(null)
      } else {
        await callEndpoint(data);
      }
    } else {
      await callEndpoint(data);
    }



    // console.log("dataFormData>>", data)

    // let signature = generateSignature(data)

    // let response = await apiRequest('post', 'add-sub-user', signature, data)
    // // console.log("registerFormDataresponse>>",response)
    // if (response.status == 200 || response.status == 201) {
    //   setErrorStatus(false)
    //   setErrorMsg('')
    //   setFieldErrorMsg([])
    //   setSubmitSuccessMsg("User created successfully")
    //   setTimeout(() => history.push(`${process.env.PUBLIC_URL}/user-list`), 2000);
    // }
    // else if (response.status == 500) {
    //   setLoading(false);
    //   setErrorStatus(true)
    //   setErrorMsg("Something went wrong,Kindly try again later")
    //   setSubmitSuccessMsg(null)
    // }
    // else if (response.status == 400) {
    //   setLoading(false);
    //   setErrorStatus(false)
    //   setErrorStatus(true)
    //   setSubmitSuccessMsg(null)
    //   //  console.log("response.data.data.data>>>",response.data.data)
    //   if (Array.isArray(response.data.data)) {
    //     let errorMsgList = []
    //     response.data.data.map((data) => {
    //       errorMsgList.push(data.message)
    //     })
    //     setFieldErrorMsg(errorMsgList)
    //     // console.log("fieldErrorMsg>>>",errorMsgList)
    //   }
    //   else {
    //     setErrorMsg(response.data.data.data)
    //   }
    // }
    // else if (response.status == 401) {
    //   setLoading(false);
    //   setErrorStatus(true)
    //   setErrorMsg(response.data.message)
    //   setSubmitSuccessMsg(null)
    // }
    // else {
    //   setLoading(false);
    //   setErrorStatus(true)
    //   setErrorMsg('Something went wrong,Kindly try again later')
    //   setSubmitSuccessMsg(null)
    // }

  };


  const isFormValid = !confimPasswordErrorMessage && !passwordErrorMessage

  return (


    <React.Fragment>
      <Head title="Register" />


      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center" style={{ marginTop: '60px' }}>
            <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={Logo} alt="logo-dark" />
            </Link>
          </div>

          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4"> Add User</BlockTitle>
                <BlockDes>
                  <p>Add New Evvopay User </p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            <form className="is-alter row" onSubmit={handleSubmit(handleFormSubmit)}>
              <div className="form-group ">

                {userDetails.user_role === 'SUPERADMIN' && (
                  <div>
                    <label className="form-label">Merchants</label>
                    <div className="form-control-wrap">
                      <RSelect
                        placeholder="Please select any merchant"
                        options={merchantName}
                        name="MerchantName"
                        onChange={(selectedOption) => {
                          const selectedId = selectedOption ? selectedOption.id : null;
                          const selectedValue = selectedOption ? selectedOption.value : null;
                          const selectedLabel = selectedOption ? selectedOption.label : null;
                          const userId = selectedOption ? selectedOption.userId : null;
                          // handleMerchantFilter(selectedValue, selectedId, selectedLabel)
                          // setRegisterFormData({ selectedValue: selectedValue, selectedId: selectedId, selectedLabel: selectedLabel })
                          console.log({ merchantId: selectedId, userId: userId }, '-<, { merchantId: selectedId, userid: userid }')
                          setRegisterFormData({ merchantId: selectedId, userId: userId })
                          // setRegisterFormData({ userId: userId })
                        }}
                        rules={{ required: true }}
                      />
                      {errors.MerchantName && <p className="invalid">Merchants field is required</p>}
                    </div>
                  </div>
                )}


                <label className="form-label" htmlFor="name">
                  Name
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter your name"
                    tabIndex="1"
                    ref={register({
                      required: true,
                      maxLength: 500,
                      //message: "Only 500 characters are allowed"
                    })}
                    className="form-control-lg form-control"
                    // onChange={(e)=>setRegisterFormData(currValue=>({
                    //   ...currValue,
                    //   merchant_name:e.target.value
                    // }))}
                    // onChange={handleMerchantChange}
                    // onBlur={validateMerchant}
                    onChange={(e) => setRegisterFormData(currValue => ({
                      ...currValue,
                      name: e.target.value
                    }))}
                  />
                  {/* {errorMessage && <p>{errorMessage}</p>} */}
                  {/* {console.log(errors)} */}
                  {/* {errors.merchant_name && <p className="invalid">{errors.merchant_name.message}</p>} */}
                  {errors.name && errors.name.type === 'required' && <p className="invalid">The field is required</p>}
                  {errors.name && errors.name.type === 'maxLength' && <p className="invalid">Only 500 characters are allowed</p>}

                </div>
              </div>
              <div className="form-group ">
                <label className="form-label" htmlFor="name">
                  Phone No
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="telephone_no"
                    name="telephone_no"
                    placeholder="Enter your no"
                    tabIndex="2"
                    ref={register({
                      required: 'The field is required',
                      pattern: {
                        value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        message: 'Field value is invalid',
                      }

                    })}
                    className="form-control-lg form-control"
                    // onChange={(e)=>setRegisterFormData(currValue=>({
                    //   ...currValue,
                    //   telephone_no:e.target.value
                    // }))}
                    // onChange={handlePhoneNoChange}
                    // onBlur={validatePhoneNo}
                    onChange={(e) => setRegisterFormData(currValue => ({
                      ...currValue,
                      telephone_no: e.target.value
                    }))}
                  />
                  {errors.telephone_no && <p className="invalid">{errors.telephone_no.message}</p>}
                  {/* {phoneNoErrorMessage && <p>{phoneNoErrorMessage}</p>} */}
                </div>
              </div>

              <div className="form-group ">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Email
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    bssize="lg"
                    id="default-01"
                    name="email_id"
                    tabIndex="3"
                    ref={register({
                      required: 'The field is required',
                      pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'Please enter a valid email',
                      }

                    })}
                    className="form-control-lg form-control"
                    placeholder="Enter your email"
                    onChange={(e) => setRegisterFormData(currValue => ({
                      ...currValue,
                      email_id: e.target.value.toLowerCase()
                    }))}
                  />
                  {errors.email_id && <p className="invalid">{errors.email_id.message}</p>}

                </div>
              </div>

              <div className="form-group ">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    User Role
                  </label>
                </div>
                <div className="form-control-wrap">
                  {/* <RSelect
                  options={userRolesData}
                  placeholder="Any Status"
                  // onChange={handleStatusChange}
                  onChange={(status) =>
                    handleRoleChange(status)
                  } */}
                  <select

                    className="form-control form-select"
                    name="user_role_id"
                    // onChange={e => handleRoleChange(e)}
                    onChange={(e) => setRegisterFormData(currValue => ({
                      ...currValue,
                      user_role_id: e.target.value
                    }))}
                    // value={paymentProcStatusValue}
                    ref={register({ required: "This field is required" })}
                    style={{ backgroundPosition: 'right 10px center', backgroundSize: '8px 9px' }}
                  >
                    <option value="">SELECT</option>
                    {userRolesData.map((data, key) => (
                      <option key={key} value={data.id}>
                        {data.user_role}
                      </option>
                    ))}
                  </select>

                  {errors.user_role_id && <p className="invalid">{errors.user_role_id.message}</p>}

                </div>
              </div>


              {
                errorStatus == true ?
                  errorMsg != '' ? <span style={{ color: 'red' }} className="invalid">{`* ` + errorMsg}</span>
                    : null
                  : null
              }
              {
                fieldErrorMsg.length > 0 ?
                  fieldErrorMsg.map((msg, key) => {
                    return <div key={key} style={{ color: 'red' }}><span className="invalid">{`* ` + msg}</span></div>
                  })
                  : null
              }

              {submitSuccessMsg &&
                <div style={{ color: 'green' }}><span  >{submitSuccessMsg}</span></div>
              }

              <div className="form-group">
                {/* <Button style={errorStatus ? { marginTop: '10px' } : {}} type="submit" disabled={!isFormValid} color="primary" size="lg" className="btn-block" tabIndex="5">
                  {loading ? <Spinner size="sm" color="light" /> : "Add"}
                </Button> */}
                <Button style={errorStatus ? { marginTop: '10px' } : {}} type="submit" disabled={!isFormValid} color="primary" size="lg" className="btn-block" tabIndex="5">
                  {loading ? <Spinner size="sm" color="light" /> : "Add"}
                </Button>
              </div>
            </form>
          </PreviewCard>


        </Block>
        {/* <AuthFooter /> */}
      </PageContainer>


    </React.Fragment>
  );
};
export default AddUser;
