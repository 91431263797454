import React, { useContext, useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  DropdownItem,
  Form,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
  PaginationComponent,
  Button,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  TooltipComponent,
  RSelect,
} from "../../../components/Component";
import {
  asiapayPaymentStatus,
  filterRole,
  filterStatus,
  paymentProcessorTransStatus,
  settlementStatus,
  transactionTypeData,
  uqpayPaymentStatus,
  userData,
} from "./UserData";
import { bulkActionOptions, findUpper } from "../../../utils/Utils";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { UserContext } from "./UserContext";

import { useHistory } from "react-router-dom";
import { apiRequest, extractKey } from "../../../helpers/api";
import { generateSignature, decryptToken } from "../../../helpers/signature";
import { ALGORITHM_TYPE, EVVOPAY_PRIVATE_KEY, MERCHANT_MODULE, MODULES, REPORTS_MODULE } from "../../../constants";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Loader from "../../../helpers/Loader";
import { Spinner } from "react-bootstrap";
import { convertDateFormate, getAccessStatus } from "../../../helpers/common";

const TransactionReportsList = () => {
  const userDetails = JSON.parse(
    decryptToken(localStorage.getItem("accessToken"))
  );
  const history = useHistory();
  const { contextData } = useContext(UserContext);
  const [data, setData] = contextData;
  const [orgData, setOrgData] = useState([]);

  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [editId, setEditedId] = useState();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    balance: "",
    phone: "",
    status: "Active",
  });
  const [actionText, setActionText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");
  const [userType, setUserType] = useState("");
  const [merchantCount, setMerchantCount] = useState(1);
  const [userData, setUserData] = useState(userDetails || null);
  const [status, setStatus] = useState("");
  const [filter, setFilter] = useState("");
  const [resetFilter, setResetFilter] = useState("");
  const [paymentProcessorFilter, setPaymentProcessorFilter] = useState("");
  const [paymentProcessorData, setPaymentProcessorData] = useState([]);
  const [paymentProcessorArr, setPaymentProcessorArr] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [transactionRecords, setTransactionRecord] = useState([]);
  const [merchantData, setMerchantData] = useState([]);
  const [selectedMerchantData, setSelectedMerchantData] = useState("");
  const [selectedTransactionType, setTransationType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [transactionStatusFilter, setTransactionStatusFilter] = useState([]);
  const [filterStatusData, setFilterStatusData] = useState({
    settlementStatus: "",
    transactionStatus: "",
  });
  const [errorMsg, setErrorMsg] = useState({common:'',startDateError:'',endDateError:''});

  // const transactionTypeData=[
  //   {value:0,label:"Payment"},
  //   {value:1,label:"Void"},
  //   {value:2,label:"Refund"},
  //   {value:3,label:"Preauth"},
  //   {value:4,label:"Capture"}

  // ]
  // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
    // console.log("defaultData>>>",defaultData)
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) =>
        a.merchant_name.localeCompare(b.merchant_name)
      );
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) =>
        b.merchant_name.localeCompare(a.merchant_name)
      );
      setData([...sortedData]);
    }
  };

  // fetching all merchant id and names
  const getMerchantIds = async () => {
    let obj = { filter: "all" };
    let signature = generateSignature(obj);
    //let response
    try {
      let response = await apiRequest(
        "get",
        `get-merchant-ids?filter=${obj.filter}`,
        signature
      );
      //  console.log("getMerchantIds-api-response>>>",response.data.status)
      if (response.data.status) {
        let filterData = response.data.data.data.map((item) => {
          return {
            value: item.merchant_id,
            label: item.merchant_name ? item.merchant_name : item.merchant_id,
            id: item.id,
          };
        });
        setMerchantData(filterData);
      } else {
        setMerchantData([]);
      }
    } catch (error) {
      console.log("getMerchantIds-api-error>>>", error);
      setMerchantData([]);
    }
  };

  const handleDateChange = (e, type) => {
    console.log("e.target.value>>>",e.target.value)
    if (type == "sd") setStartDate(e.target.value);
    else setEndDate(e.target.value);
  };

  useEffect(async () => {


    //console.log("userData>>>",userData)
    let {readStatus}=getAccessStatus(userData,REPORTS_MODULE)
  //  console.log("accessStatus>>>",accessStatus)
    if(!readStatus){
      history.push(`${process.env.PUBLIC_URL}/errors/504-classic`);
    }
    let sd=startDate == "" ? getDefaultDate("sd") : ""
    let ed=endDate == "" ? getDefaultDate("ed") : ""

    setStartDate(sd)
    setEndDate(ed)

    userData.merchantid == undefined && userData.merchantid == null
      ? null
      : setSelectedMerchantData(userData.merchantid);

    setUserType(userData.user_role);
  
    setTransactionStatusFilter(paymentProcessorTransStatus)
    await getMerchantIds();
    //  await getUserRecords()
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = transactionRecords.filter((item) => {
       // console.log("item>>>", item);
        if (userType == "SUPERADMIN") {
          return (
            (item.merchantName &&
              item.merchantName
                .toLowerCase()
                .includes(onSearchText.toLowerCase())) ||
            (item.transactionId &&
              item.transactionId
                .toLowerCase()
                .includes(onSearchText.toLowerCase())) ||
            (item.transactionDate &&
              item.transactionDate
                .toLowerCase()
                .includes(onSearchText.toLowerCase())) ||
            (item.settlementDate &&
              item.settlementDate
                .toLowerCase()
                .includes(onSearchText.toLowerCase()))
          );
        } else {
          return (
            (item.merchantName &&
              item.merchantName
                .toLowerCase()
                .includes(onSearchText.toLowerCase())) ||
            (item.transactionId &&
              item.transactionId
                .toLowerCase()
                .includes(onSearchText.toLowerCase())) ||
            (item.transactionDate &&
              item.transactionDate
                .toLowerCase()
                .includes(onSearchText.toLowerCase())) ||
            (item.settlementDate &&
              item.settlementDate
                .toLowerCase()
                .includes(onSearchText.toLowerCase()))
          );
        }
      });
      setTransactionRecord([...filteredObject]);
    } else {
      // await getUserRecords()
      setTransactionRecord([...orgData]);
    }
  }, [onSearchText, setData]);

  useEffect(() => {
    if (resetFilter === "reset") {
      setTransactionRecord([...orgData]);
      setResetFilter(""); // Reset the resetFilter value after using it
      setFilter("");
      setFilterStatusData({ settlementStatus: "", transactionStatus: "" });

      return; // Exit early to prevent further execution
    }

    if (filter !== "") {
      // console.log("here in filter orgData>>before", orgData);
      const filteredObject = orgData.filter((item) => {

        if(item.settlementStatus!='' && item.settlementStatus!=null && item.transactionStatus!='' && item.transactionStatus!=null){
          if (filter.settlementStatus != "" && filter.transactionStatus != "") {
            // console.log("flag-1")
            return (
              item.transactionStatus &&
              item.transactionStatus
                .toLowerCase()
                .includes(filter.transactionStatus.toLowerCase()) &&
              // (item.settlementStatus && item.settlementStatus.toLowerCase().includes(filter.settlementStatus.toLowerCase()))
              item.settlementStatus.toLowerCase() ==
                filter.settlementStatus.toLowerCase()
            );
          } else if (filter.settlementStatus != "") {
            //console.log("flag-2")
            return (
              item.settlementStatus.toLowerCase() ==
              filter.settlementStatus.toLowerCase()
            );
  
            // (item.settlementStatus && item.settlementStatus.toLowerCase().includes(filter.settlementStatus.toLowerCase()))
            // )
          } else if (filter.transactionStatus != "") {
            //   console.log("flag-3")
            return (
              item.transactionStatus &&
              item.transactionStatus
                .toLowerCase()
                .includes(filter.transactionStatus.toLowerCase())
            );
          }
        }
        // if (filter.settlementStatus != "" && filter.transactionStatus != "") {
        //   // console.log("flag-1")
        //   return (
        //     item.transactionStatus &&
        //     item.transactionStatus
        //       .toLowerCase()
        //       .includes(filter.transactionStatus.toLowerCase()) &&
        //     // (item.settlementStatus && item.settlementStatus.toLowerCase().includes(filter.settlementStatus.toLowerCase()))
        //     item.settlementStatus.toLowerCase() ==
        //       filter.settlementStatus.toLowerCase()
        //   );
        // } else if (filter.settlementStatus != "") {
        //   //console.log("flag-2")
        //   return (
        //     item.settlementStatus.toLowerCase() ==
        //     filter.settlementStatus.toLowerCase()
        //   );

        //   // (item.settlementStatus && item.settlementStatus.toLowerCase().includes(filter.settlementStatus.toLowerCase()))
        //   // )
        // } else if (filter.transactionStatus != "") {
        //   //   console.log("flag-3")
        //   return (
        //     item.transactionStatus &&
        //     item.transactionStatus
        //       .toLowerCase()
        //       .includes(filter.transactionStatus.toLowerCase())
        //   );
        // }
      });
     // console.log("here in filter orgData filteredObject", filteredObject);
      setTransactionRecord([...filteredObject]);
    } else {
      setTransactionRecord([...orgData]);
    }
  }, [filter, resetFilter, orgData, setData, userType]);

  // function to set the action to be taken in table header
  // const onActionText = (e) => {
  //   setActionText(e.value);
  // };

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleFilter = (e) => {
    //console.log("filter<<<",e)
    setFilter(e);
  };

  const handleResetFilter = (ev) => {
    ev.preventDefault();
    setResetFilter("reset");
  };


  const validateDate=async()=>{

    // let status=true
    let myStartDate = startDate.split("-");
    let myEndDate = endDate.split("-");
    let  startDateVal = new Date( myStartDate[0], myStartDate[1] - 1, myStartDate[2]);
    let  endDateVal = new Date( myEndDate[0], myEndDate[1] - 1, myEndDate[2]);
    let startDateTimeStamp=Math.floor(startDateVal.getTime())
    let endtDateTimeStamp=Math.floor(endDateVal.getTime())
    
    let currentTimeStamp=Date.now()

    if(startDate!="" && endDate !=""){
    
     if(startDateTimeStamp>endtDateTimeStamp){
       if(startDateTimeStamp>currentTimeStamp && endtDateTimeStamp>currentTimeStamp){
           setErrorMsg({
         //  ...errorMsg,
           common:'',
           startDateError:'Future Date is disallowed',
           endDateError:'Future Date is disallowed'
         })
         
       }
       else if(startDateTimeStamp>currentTimeStamp){
         setErrorMsg({
           ...errorMsg,
           common:'',
           startDateError:'Future Date is disallowed'
         })
      //   console.log("flag-2")
       }
       else if(endtDateTimeStamp>currentTimeStamp){
         setErrorMsg({
           ...errorMsg,
           common:'',
           endDateError:'Future Date is disallowed'
         })
       //  console.log("flag-3")
       }

       else {
         setErrorMsg({
           endDateError:'',
           startDateError:'',
           common:'Date is invalid'
         })
       }
       return false
     }
     else{
         if(startDateTimeStamp>currentTimeStamp && endtDateTimeStamp>currentTimeStamp){
           setErrorMsg({
           ...errorMsg,
           startDateError:'Future Date is disallowed',
           endDateError:'Future Date is disallowed'
         })
         return  false
       }
       else if(startDateTimeStamp>currentTimeStamp){
         setErrorMsg({
           ...errorMsg,
           endDateError:'',
           startDateError:'Future Date is disallowed'
         })
         return false
       }
       else if(endtDateTimeStamp>currentTimeStamp){
         setErrorMsg({
           ...errorMsg,
           startDateError:'',
           endDateError:'Future Date is disallowed'
         })
         return false
       }
       
       else{
        return true
       }
     }
 
    }
   else if(startDate!=""){
     if(startDateTimeStamp>currentTimeStamp){
       setErrorMsg({
         common:'',
         startDateError:'Future Date is disallowed',
         endDateError:''
       })
    //   console.log("flag-2")
         return false 
     }
     
   }
   else if(endDate!=""){
     if(endtDateTimeStamp>currentTimeStamp){
       setErrorMsg({
         ...errorMsg,
         startDateError:'',
         endDateError:'Future Date is disallowed'
       })

       return false 
   }
  
  }
  else{
    return true 
  }
 
  }
  const getTransactionReports = async () => {
    let reportStatus=await validateDate()
  //  let todaysDate = new Date();
    if(reportStatus){

      setErrorMsg({common:'',startDateError:'',endDateError:''})
      setIsLoading(true);
      //console.log(getDefaultDate("end"))
      let sd=startDate == "" ? getDefaultDate("sd") : startDate
      let ed=endDate == "" ? getDefaultDate("ed") : endDate

      const startTime = '00:00:00';
      const endTime = '23:59:00';

      const startDateWithTime = `${sd} ${startTime}`;
      const endDateWithTime = `${ed} ${endTime}`;

      // let startDateTimeStampVal=Math.floor(new Date(sd).getTime())
      // let endDateTimeStampVal=Math.floor(new Date(ed).getTime())

      let startDateTimeStampVal=Math.floor(new Date(startDateWithTime).getTime())
      let endDateTimeStampVal=Math.floor(new Date(endDateWithTime).getTime())

    //  console.log("endDateTimeStampVal>>",endDateTimeStampVal)


      // let  inputDate = new Date(ed);
      // if(inputDate.setHours(0,0,0,0) == todaysDate.setHours(0,0,0,0)) 
      //     endDateTimeStampVal= Math.floor(Date.now())

      let reqBody = {
        userid: userData.user_id,
        merchantId: selectedMerchantData,
        transactionType: selectedTransactionType,
        startDate: startDateTimeStampVal,
        endDate: endDateTimeStampVal,
      };
   //   console.log("reqBody>>>",reqBody)
  
      let signature = generateSignature(reqBody);
      try {
        let response = await apiRequest(
          "post",
          `get-transaction-reports`,
          signature,
          reqBody
        );
        //console.log("getTransactionReports-api-response>>>",response.data.status)
        // console.log(
        //   "getTransactionReports-api-response>>>",
        //   response.data.data.data
        // );
        if (response.data.status) {
          if (response.data.data.data.length > 0) {
            // response.data.data.data[0].paymentProcessor = "uqpay"
            //   ? setTransactionStatusFilter(uqpayPaymentStatus)
            //   : setTransactionStatusFilter(asiapayPaymentStatus);
           
            setTransactionRecord(response.data.data.data);
            setMerchantCount(response.data.data.data.length);
            setOrgData(response.data.data.data);
            setIsLoading(false);
          } else {
            setTransactionRecord([]);
            setMerchantCount(1);
            setOrgData([]);
            setIsLoading(false);
          }
        } else {
          setTransactionRecord([]);
          setIsLoading(false);
        }
      } catch (error) {
        console.log("getTransactionReports-api-error>>>", error);
        setTransactionRecord([]);
        setIsLoading(false);
      }
    }
   
  };

  const getTimeSTampVal=async(date)=>{

  }
  const handleStatusChange = (status, statusType) => {
    statusType == "ps"
      ? setFilterStatusData({
          ...filterStatusData,
          settlementStatus: status.value,
        })
      : setFilterStatusData({
          ...filterStatusData,
          transactionStatus: status.value,
        });
  };
  const getDefaultDate = (type) => {
    let date = new Date();
    if (type == "sd") {
      let d = new Date(date.getFullYear(), 0, 1);
      return d.getFullYear() + "-" + d.getMonth() + 1 + "-" + d.getDay() + 1;
    } else {
      //  console.log(date)
    //  let day = date.getDate();
      let day =   ('0' + (date.getDate())).slice(-2) 
      //let month =   date.getMonth() + 1;
      let month =   ('0' + (date.getMonth()+1)).slice(-2) 
      let year = date.getFullYear();

      return `${year}-${month}-${day}`;
    }
  };
  // function to change the selected property of an item
  const onSelectChange = (e, id) => {
    let newData = data;
    let index = newData.findIndex((item) => item.id === id);
    newData[index].checked = e.currentTarget.checked;
    setData([...newData]);
  };

  // function to reset the form
  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      balance: "",
      phone: "",
      status: "Active",
    });
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;

  //console.log("data>>>>",data)
  // if(data.length>0){
  //   const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  // }

  // console.log("transactionRecords>>",transactionRecords)
  const currentItems =
    transactionRecords.length > 0
      ? transactionRecords.slice(indexOfFirstItem, indexOfLastItem)
      : [];
  //console.log("currentItems>>",currentItems)
  // console.log("currentItems>>>",currentItems)
  // Change Page
  // const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const paginate = (pageNumber) => {
    // console.log("pageNumber>>>",pageNumber)
    setCurrentPage(pageNumber);
  };

  const { errors, register, handleSubmit } = useForm();

  const goToRegistration = () => {
    history.push(`${process.env.PUBLIC_URL}/auth-register`);
  };

  const handleDownload = () => {
    // console.log(data, '-<, inside data ')
    const modifiedData = transactionRecords.map((item) => {
      const {
        merchantName,
        transactionId,
        transactionDate,
        currency,
        transactionAmount,
        payMethod,
        gatewayFee,
        mdrFee,
        settlementDate,
        transactionTotal,
        settlementStatus,
        transactionStatus,
        paymentProcessor
      } = item;

      if (userType == "SUPERADMIN") {
        return {
          "Merchant Name": merchantName,
          "Payment Processor": paymentProcessor,
          "Transaction Id": transactionId,
          "Transaction Date": transactionDate,
          Currency: currency,
          "Transaction Amount": transactionAmount,
          "Pay Method": payMethod,
          "Gateway Fee": gatewayFee,
          "MDR %": mdrFee,
          "settlement Date": settlementDate,
          "Transaction Total": transactionTotal,
          "settlement Status": settlementStatus,
          "Transaction Status": transactionStatus,
        };
      } else {
        return {
          "Merchant Name": merchantName,
          "Payment Processor": paymentProcessor,
          "Transaction Id": transactionId,
          "Transaction Date": transactionDate,
          Currency: currency,
          "Transaction Amount": transactionAmount,
          "Pay Method": payMethod,
          "settlement Date": settlementDate,
          "Transaction Total": transactionTotal,
          "settlement Status": settlementStatus,
          "Transaction Status": transactionStatus,
        };
      }
    });

    // Convert data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(modifiedData);

    // Create workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate Excel file buffer
    const excelBuffer = XLSX.write(workbook, {
      type: "array",
      bookType: "xlsx",
    });

    // Save the file using FileSaver.js
    const fileData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(fileData, "Transaction_Report_List Sheet.xlsx");
  };

  // fetching payment processor record
  const getPaymentProcessData = async () => {
    let data = {
      type: "open",
    };
    let signature = generateSignature(data);
    let response = await apiRequest(
      "get",
      `get-payment-processor?type=open`,
      signature
    );

    let array = [];
    if (response.status == 200 || response.status == 201) {
      if (response.data.data.data.length > 0) {
        let filterData = response.data.data.data.map((item) => {
          //  console.log(item.name, '-<, items names ')
          // setPaymentProcessorArr(item.name);
          array.push(item.name);
          return {
            value: item.payment_processor_id,
            label: item.name.toUpperCase(),
          };
        });
        //   console.log(filterData, '-<< filterDate')
        filterData.unshift({ value: filterData.length + 1, label: "ALL" });
        filterData.push({ value: filterData.length + 1, label: "PENDING" });
        // filterData.push({'value': filterData.length + 1,'label': 'ALL Payment Processors' })
        // console.log(filterData, '-<< filterDate')

        setPaymentProcessorData(filterData);
      } else setPaymentProcessorData([]);
    } else {
      return [];
    }
    setPaymentProcessorArr(array);
  };

  return (
    <React.Fragment>
      <Head title="User List - Regular"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Transaction Records
              </BlockTitle>

              {merchantCount != 1 ? (
                <BlockDes className="text-soft">
                  <p>You have total {merchantCount} records.</p>
                </BlockDes>
              ) : null}
              {/* <BlockDes className="text-soft">
                <p>You have total {usersCount} users.</p>
              </BlockDes> */}
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${
                    sm ? "active" : ""
                  }`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                {/* {
                  userData !=null && userData.user_role!='ADMIN'? */}
                <div
                  className="toggle-expand-content"
                  style={{ display: sm ? "block" : "none" }}
                >
                  <ul className="nk-block-tools g-3">
                    <li>
                      {/* <Button color="light" outline className="btn-white"> */}
                      <Button
                        color="light"
                        outline
                        className="btn-white"
                        onClick={() => handleDownload()}
                      >
                        <Icon name="download-cloud"></Icon>
                        <span>Export</span>
                      </Button>
                    </li>
                  </ul>
                </div>
                {/* // :
                // null
                // } */}
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="card-tools">
                  {/* {
                    merchantData.length>0? */}
                  {/* <div className="form-inline flex-nowrap gx-3"> */}
                  <div className="form-inline flex-nowrap gx-3 align-end">
                    {userType == "SUPERADMIN" ? (
                      <Col md="2">
                        <div className="form-group">
                          <label className="form-label">Merchants</label>
                          <div className="form-control-wrap merchSelect">
                            <RSelect
                              //   defaultValue={defaultMerchant}
                              options={merchantData}
                              name="merchant_data"
                              onChange={(selectedOption) => {
                                setSelectedMerchantData(selectedOption.id);
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    ) : null}

                    <Col md="2">
                      <div className="form-group">
                        <label className="form-label">Transaction</label>
                        <div className="form-control-wrap">
                          {
                            //  console.log("defaultMerchant.>>", Object.keys(defaultMerchant).length)
                            // console.log("defaultMerchant.>>", defaultMerchant)
                            // console.log("selectedTransactionType>>", selectedTransactionType)
                          }

                          <RSelect
                            //   defaultValue={defaultMerchant}
                            options={transactionTypeData}
                            name="transaction_type"
                            onChange={(selectedOption) => {
                              setTransationType(selectedOption.label);
                            }}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md="4">
                      <div className="form-group">
                        <label className="form-label">Start Date</label>
                        {/* <div className="w-62"> */}
                        <div className="form-control-wrap">
                          <input
                            type="date"
                            className="form-control-lg form-control"
                            onChange={(date) => handleDateChange(date, "sd")}
                            value={startDate}
                            style={{height:"38px",padding:"0px 12px",minHeight:"inherit"}}
                            // value="2013-01-08"
                            // ref={dateInputRef}
                          />
                        </div>
                        {
                 
                        errorMsg.common!=''?<span style={{color:'red',position: 'absolute',fontSize: '12px'}} className="invalid">{`* `+errorMsg.common}</span>
                        :null
                      }

                        {
                                      
                        errorMsg.startDateError!=''?<span style={{color:'red',position: 'absolute',fontSize: '12px'}} className="invalid">{`* `+ errorMsg.startDateError}</span>
                        :null
                      }
                        {/* </div> */}
                        {/* </Row> */}
                      </div>
                    </Col>

                    <Col md="4">
                      <div className="form-group">
                        <label className="form-label">End Date</label>
                        {/* <div className="w-62"> */}
                        <div className="form-control-wrap">
                          <input
                            type="date"
                            className="form-control-lg form-control"
                            onChange={(date) => handleDateChange(date, "ed")}
                            value={endDate}
                            style={{height:"38px",padding:"0px 12px",minHeight:"inherit"}}
                            // value="2013-01-08"
                            // ref={dateInputRef}
                          />
                        </div>
                        {
                 
                        errorMsg.common!=''?<span style={{color:'red',position: 'absolute',fontSize: '12px'}} className="invalid">{`* `+errorMsg.common}</span>
                        :null
                      } 

                         {
                                      
                        errorMsg.endDateError!=''?<span style={{color:'red',position: 'absolute',fontSize: '12px'}} className="invalid">{`* `+ errorMsg.endDateError}</span>
                        :null
                      }
                        {/* </div> */}
                        {/* </Row> */}
                      </div>
                    </Col>
                    <div className="btn-wrap">
                      <span className="d-none d-md-block">
                        {/* <Button
                           disabled={ Object.keys(selectedMerchantData).length!=0 && selectedTransactionType !== '' ? false : true}
                           color="primary"
                          outline
                          className="btn-dim"
                          onClick={getTransactionReports}
                        >
                          Submit
                        </Button> */}
                        <Button
                          color="primary"
                          size="md"
                          type="button"
                          onClick={getTransactionReports}
                          disabled={
                            selectedMerchantData != "" &&
                            selectedTransactionType !== ""
                              ? false
                              : true
                          }
                          // disabled={ userType=='SUPERADMIN'?selectedMerchantData!='' && selectedTransactionType !== '' ? false : true
                          // :
                          // selectedTransactionType !== '' ? false : true
                          // }
                        >
                          Submit
                        </Button>
                      </span>

                      {/* <span className="d-md-none">
                        <Button
                          color="light"
                          outline
                          disabled={actionText !== "" ? false : true}
                          className="btn-dim btn-icon"
                          onClick={(e) => onActionClick(e)}
                        >
                          <Icon name="arrow-right"></Icon>
                        </Button>
                      </span> */}
                    </div>
                  </div>
                  {/* //   :
                //   null
                //   } */}
                </div>
                <div className="card-tools me-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${
                            tablesm ? "active" : ""
                          }`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div
                          className={`toggle-content ${
                            tablesm ? "content-active" : ""
                          }`}
                        >
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <Button
                                className="btn-icon btn-trigger toggle"
                                onClick={() => updateTableSm(false)}
                              >
                                <Icon name="arrow-left"></Icon>
                              </Button>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="a"
                                  className="btn btn-trigger btn-icon dropdown-toggle"
                                >
                                  <div className="dot dot-primary"></div>
                                  <Icon name="filter-alt"></Icon>
                                </DropdownToggle>
                                <DropdownMenu
                                  end
                                  className="filter-wg dropdown-menu-xl"
                                  style={{ overflow: "visible" }}
                                >
                                  <div className="dropdown-head">
                                    <span className="sub-title dropdown-title">
                                      Filter Merchants
                                    </span>
                                    <div className="dropdown">
                                      <a
                                        href="#more"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                        className="btn btn-sm btn-icon"
                                      >
                                        <Icon name="more-h"></Icon>
                                      </a>
                                    </div>
                                  </div>
                                  <div className="dropdown-body dropdown-body-rg">
                                    <Row className="gx-2 gy-2">
                               
                                      <Col size="6">
                                        <div className="form-group">
                                          <label className="overline-title overline-title-alt">
                                            Payment Status
                                          </label>
                                          <RSelect
                                            options={settlementStatus}
                                            placeholder="Any Status"
                                            onChange={(status) =>
                                              handleStatusChange(status, "ps")
                                            }
                                          />
                                        </div>
                                      </Col>

                                      <Col size="6">
                                        <div className="form-group">
                                          <label className="overline-title overline-title-alt">
                                            Transaction Status
                                          </label>
                                          <RSelect
                                            options={transactionStatusFilter}
                                            placeholder="Any Status"
                                            // onChange={handleStatusChange}
                                            onChange={(status) =>
                                              handleStatusChange(status, "ts")
                                            }
                                          />
                                        </div>
                                      </Col>
                                      <Col size="12">
                                        <div className="form-group">
                                          <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={() =>
                                              handleFilter(filterStatusData)
                                            }
                                          >
                                            Filter
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="dropdown-foot between">
                                    <a
                                      href="#reset"
                                      // onClick={(ev) => {
                                      //   ev.preventDefault();
                                      // }}
                                      onClick={handleResetFilter}
                                      className="clickable"
                                    >
                                      Reset Filter
                                    </a>
                                    {/* <a
                                      href="#save"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      Save Filter
                                    </a> */}
                                  </div>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  color="tranparent"
                                  className="btn btn-trigger btn-icon dropdown-toggle"
                                >
                                  <Icon name="setting"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end className="dropdown-menu-xs">
                                  <ul className="link-check">
                                    <li>
                                      <span>Show</span>
                                    </li>
                                    <li
                                      className={
                                        itemPerPage === 10 ? "active" : ""
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(10);
                                        }}
                                      >
                                        10
                                      </DropdownItem>
                                    </li>
                                    <li
                                      className={
                                        itemPerPage === 15 ? "active" : ""
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(15);
                                        }}
                                      >
                                        15
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                  {/* <ul className="link-check">
                                    <li>
                                      <span>Order</span>
                                    </li>
                                    <li className={sort === "dsc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("dsc");
                                          sortFunc("dsc");
                                        }}
                                      >
                                        DESC
                                      </DropdownItem>
                                    </li>
                                    <li className={sort === "asc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("asc");
                                          sortFunc("asc");
                                        }}
                                      >
                                        ASC
                                      </DropdownItem>
                                    </li>
                                  </ul> */}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className={`card-search search-wrap ${!onSearch && "active"}`}
              >
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by user or email"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            {isLoading ? (
              <Loader />
            ) : (
              <>
                <DataTableBody>
                  <DataTableHead>
                    <DataTableRow>
                      <span className="sub-text">Merchant Name</span>
                    </DataTableRow>

                    <DataTableRow>
                      <span className="sub-text">Payment Processor</span>
                    </DataTableRow>

                    <DataTableRow size="mb">
                      <span className="sub-text">Transaction Id</span>
                    </DataTableRow>

                    <DataTableRow size="md">
                      <span className="sub-text">Transaction date</span>
                    </DataTableRow>

                    <DataTableRow size="md">
                      <span className="sub-text">Currency</span>
                    </DataTableRow>
                    <DataTableRow size="mb">
                      <span className="sub-text">Transaction Amount</span>
                    </DataTableRow>
                    <DataTableRow size="mb">
                      <span className="sub-text">Payment Method</span>
                    </DataTableRow>

                    {userType == "SUPERADMIN" ? (
                      <DataTableRow size="mb">
                        <span className="sub-text">Gateway Fee</span>
                      </DataTableRow>
                    ) : null}

                    {userType == "SUPERADMIN" ? (
                      <DataTableRow size="mb">
                        <span className="sub-text">MDR %</span>
                      </DataTableRow>
                    ) : null}

                    <DataTableRow size="mb">
                      <span className="sub-text">Settlement Date</span>
                    </DataTableRow>

                    <DataTableRow size="mb">
                      <span className="sub-text">Transaction Total</span>
                    </DataTableRow>

                    <DataTableRow size="mb">
                      <span className="sub-text">Status of the Payment</span>
                    </DataTableRow>

                    <DataTableRow size="mb">
                      <span className="sub-text">
                        Status of the Transaction
                      </span>
                    </DataTableRow>
                  </DataTableHead>
                  {/*Head*/}

                  {
                    // isLoading?(
                    //   <Loader />
                    // )
                    // :
                    currentItems.length > 0
                      ? currentItems.map((item, index) => {
                          return (
                            <DataTableItem key={index}>
                              <DataTableRow>
                                <span>{item.merchantName}</span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span>{item.paymentProcessor}</span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span>{item.transactionId}</span>
                              </DataTableRow>

                              <DataTableRow size="md">
                                <span>{item.transactionDate}</span>
                              </DataTableRow>

                              <DataTableRow size="md">
                                <span>{item.currency}</span>
                              </DataTableRow>

                              <DataTableRow size="md">
                                <span>{item.transactionAmount}</span>
                              </DataTableRow>

                              <DataTableRow size="md">
                                <span>{item.payMethod}</span>
                              </DataTableRow>

                              {userType == "SUPERADMIN" ? (
                                <DataTableRow size="md">
                                  <span>{item.gatewayFee}</span>
                                </DataTableRow>
                              ) : null}

                              {userType == "SUPERADMIN" ? (
                                <DataTableRow size="md">
                                  <span>{item.mdrFee}</span>
                                </DataTableRow>
                              ) : null}

                              <DataTableRow size="md">
                                <span>{item.settlementDate}</span>
                              </DataTableRow>

                              <DataTableRow size="md">
                                <span>{item.transactionTotal}</span>
                              </DataTableRow>

                              <DataTableRow size="md">
                                <span>{item.settlementStatus}</span>
                              </DataTableRow>

                              <DataTableRow size="md">
                                <span>{item.transactionStatus}</span>
                              </DataTableRow>
                            </DataTableItem>
                          );
                        })
                      : null
                  }
                  {/* {} */}
                </DataTableBody>
                <div className="card-inner">
                  {
                    // isLoading ? (
                    //   <Loader />

                    // ) :
                    // currentItems.length > 0 ? (
                    //   <React.Fragment>
                    //     {/* Your rendered data */}
                    //     <PaginationComponent
                    //       itemPerPage={itemPerPage}
                    //       totalItems={transactionRecords.length}
                    //       paginate={paginate}
                    //       currentPage={currentPage}
                    //     />
                    //   </React.Fragment>
                    // ) : (
                    //   <div className="text-center">
                    //     <span className="text-silent">No data found</span>
                    //   </div>
                    // )

                    currentItems.length > 0 ? (
                      <React.Fragment>
                        {/* Your rendered data */}
                        <PaginationComponent
                          itemPerPage={itemPerPage}
                          totalItems={transactionRecords.length}
                          paginate={paginate}
                          currentPage={currentPage}
                        />
                      </React.Fragment>
                    ) : (
                      <div className="text-center">
                        <span className="text-silent">No data found</span>
                      </div>
                    )
                  }
                </div>
              </>
            )}
          </DataTable>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default TransactionReportsList;
