import React, { useState,useEffect } from "react";
import Logo from "../../images/evvopay_logo.png";
import LogoDark from "../../images/logo-dark.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle } from "../../components/Component";
import { Link,useHistory } from "react-router-dom";
import { generateSignature } from "../../helpers/signature";
import { apiRequest } from "../../helpers/api";


// console.log("location.state.userid>>",location)
const UpdationSuccess = ({ match }) => {

const id = match.params.id;
const [statusUpdateId,setStatusUpdateId]=useState(id)
const [errorStatus,setErrorStatus]=useState(false)
const [message,setMessage]=useState('')
const history = useHistory();
// const queryParameters = new URLSearchParams(window.location.pathname)
// console.log("location.state.userid>>",queryParameters.get("id"))
// const type = queryParameters.get("type")

// console.log("location.state.userid>>",searchParams.get("id"))

useEffect(()=>{
    updateUserStatus()
},[])
const updateUserStatus=async()=>{
    
  //console.log("statusUpdateId>>>>",statusUpdateId)

  let data={
    "userid":statusUpdateId
  }
 let signature=generateSignature(data)

  let response=await apiRequest('put',`update-active-user-status?userid=${statusUpdateId}`,signature)
  
  console.log("response>>>>",response)
  if(response.status==200 || response.status==201){

    setErrorStatus(true)
    setMessage('Congratulations !! your account has been activated')

  }
  else if(response.status==401 ){

    setErrorStatus(true)
    setMessage('The user is already activated')


  }
  else{
   // return []
   setErrorStatus(false)
    history.push(`${process.env.PUBLIC_URL}/auth-login`)
  }

}
  return (
    <React.Fragment>
      <Head title="Success" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body">
          <div className="brand-logo pb-5">
            <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={Logo} alt="logo-dark" />
            </Link>
            {/* <h3>Evvopay Admin</h3> */}
          </div>
          <BlockHead>
            {
              errorStatus==true?
              <BlockContent>
              <BlockTitle tag="h5">{message}</BlockTitle>
              {/* <BlockDes className="text-success">
                <p>You can now sign in with your new password</p>
                
              </BlockDes> */}
            </BlockContent>
            : null
            }


         
            {/* <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
                <strong>Sign in</strong>
               </Link> */}
          </BlockHead>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default UpdationSuccess;
