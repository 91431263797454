import React, { useState } from "react";
import UserAvatar from "../../../user/UserAvatar";
import { activityData } from "./ActivityData";
import { CardTitle } from "reactstrap";

const RecentActivity = (props) => {
  const [recentUser, setRecentUser] = useState("");
  const { refundTransactions } = props;
  return (
    <React.Fragment>
      <div className="card-inner border-bottom">
        <div className="card-title-group">
          <CardTitle>
            <h6 className="title">Refund Transactions</h6>
          </CardTitle>
          {/* <div className="card-tools">
            <ul className="card-tools-nav">
              <li className={recentUser === "Cancel" ? "active" : ""} onClick={() => setRecentUser("Cancel")}>
                <a
                  href="#cancel"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <span>Cancel</span>
                </a>
              </li>
              <li className={recentUser === "" ? "active" : ""} onClick={() => setRecentUser("")}>
                <a
                  href="#all"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <span>All</span>
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
      {/* <ul className="nk-activity">
        {recentUser === "Cancel"
          ? activityData.slice(0, 3).map((item) => {
              return (
                <li className="nk-activity-item" key={item.name}>
                  <UserAvatar
                    className="nk-activity-media"
                    theme={item.theme}
                    image={item.img}
                    text={item.initial}
                  ></UserAvatar>
                  <div className="nk-activity-data">
                    <div className="label">{item.name + " " + item.activity}</div>
                    <span className="time">{item.time}</span>
                  </div>
                </li>
              );
            })
          : activityData.map((item) => {
              return (
                <li className="nk-activity-item" key={item.name}>
                  <UserAvatar
                    className="nk-activity-media"
                    theme={item.theme}
                    image={item.img}
                    text={item.initial}
                  ></UserAvatar>
                  <div className="nk-activity-data">
                    <div className="label">{item.name + " " + item.activity}</div>
                    <span className="time">{item.time}</span>
                  </div>
                </li>
              );
            })}
      </ul> */}
      {/* <ul className="nk-activity">
        {refundTransactions !== null
          ? refundTransactions.slice(0, 5).map((item, idx) => {
            console.log(item ,'-<,item')
              return (
                <li className="nk-activity-item" key={idx}>
                  {/* <UserAvatar
                    className="nk-activity-media"
                    theme={item.theme}
                    image={item.img}
                    text={item.initial}
                  ></UserAvatar> 
                  <div className="nk-activity-data">
                    <div className="label"> <strong>Transaction ID : {item.orderid}</strong> </div>
                    <div className="label"> <strong>Transaction Amount : {item.amount ? item.amount : 0}</strong> </div>
                    <div className="label"> <strong>Transaction Date : </strong> {item.date}</div>
                    <div className="label"> <strong>Message : </strong> { item.message}</div>
                  </div>
                </li>
              );
            })
          : null }
      </ul> */}
      {/* { refundTransactions!==null ? 
        refundTransactions.slice(0, 5).map((item, idx) => {
          return (
            <div className="card-inner card-inner-md" key={idx}>
              <div className="user-card">
                <UserAvatar theme={item.theme} text={item.initial}></UserAvatar>
                {/* <div className="user-info">
                  <span className="lead-text">{item.name}</span>
                  <span className="sub-text">{item.email}</span>
                </div> 
                <div className="nk-activity-data">
                      <div className="label"> <strong>Transaction ID : {item.orderid}</strong> </div>
                      <div className="label"> <strong>Transaction Amount : {item.amount ? item.amount : 0} {item.currency !== null ? item.currency : 'SGD'}</strong> </div>
                      <div className="label"> <strong>Transaction Date : </strong> { item.date}</div>
                      <div className="label"> <strong>Message : </strong> { item.message}</div>
                    </div>
                <div className="user-action">
                  {/* <DropdownTrans /> 
                </div>
              </div>
            </div>
          );
        }) : null }     */}
          {refundTransactions !== null ? (
         refundTransactions.length > 0 ? (
          refundTransactions.slice(0, 5).map((item, idx) => (
            <div className="card-inner card-inner-md" key={idx}>
              <div className="user-card">
                <UserAvatar theme={item.theme} text={item.initial}></UserAvatar>
                <div className="nk-activity-data">
                  <div className="label">
                    <strong>Transaction ID: {item.orderid}</strong>
                  </div>
                  <div className="label">
                    <strong>
                      Transaction Amount: {item.amount ? item.amount : 0}{" "}
                      {item.currency !== null ? item.currency : "SGD"}
                    </strong>
                  </div>
                  <div className="label">
                    <strong>Transaction Date: </strong> {item.date}
                  </div>
                  <div className="label">
                    <strong>Message: </strong> {item.message}
                  </div>
                </div>
                <div className="user-action">{/* <DropdownTrans /> */}</div>
              </div>
            </div>
          ))
        ) : (
          <div className="card-inner">
            <div className="card-title-group">
              {/* <CardTitle> */}
              <span>NO RECORDS FOUND.</span>
              {/* </CardTitle> */}
            </div>
          </div>
        )
      ) : null}
        </React.Fragment>
  );
};
export default RecentActivity;
