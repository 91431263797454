import React, { useState, useEffect } from "react";
// import Logo from "../../images/logo.png";
import Logo from "../../images/evvopay_logo.png";
import LogoDark from "../../images/logo-dark.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Form, Spinner, Alert } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { apiRequest } from "../../helpers/api";
import { useHistory } from "react-router-dom";
import { generateSignature, isVerify, encryptToken, decryptToken } from "../../helpers/signature";
import { ALGORITHM_TYPE, EVVOPAY_API, EVVOPAY_PRIVATE_KEY, EVVOPAY_PUBLIC_KEY } from "../../constants";
const Login = () => {
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [errorVal, setError] = useState("");
  const history = useHistory();

  const onFormSubmit = async (formData) => {
    setLoading(true);
    let loginFormData = {
      userid : formData.email !== null && formData.email !==undefined ? formData.email.toLowerCase().trim() : '',
      password : formData.passcode
    }
// merchantid !== null && merchantid !== undefined ? merchantid : '1005776',
    // let loginFormData = {
    //   userid : 'sasikumar@evvoiot.com',
    //   password : 'Sasi@123'
    // }

    let signature=generateSignature(loginFormData)
    let response = await apiRequest('post', 'login', signature, loginFormData)

    if(response.status==200 || response.status==201){

    //  console.log("response>>>>",response)
    let token = JSON.stringify(response?.data?.data?.data);
   // console.log("token>>>>",token)
    const encrypt = encryptToken(token);
//    console.log("encrypt>>>>",encrypt)
      localStorage.setItem('accessToken', `${encrypt}`)
      // localStorage.setItem("accessToken", "token");
      //  history.push(`${process.env.PUBLIC_URL}/`)
      // window.location.reload();
      setTimeout(() => {

        //    window.history.pushState(`${process.env.PUBLIC_URL}/`)
            window.history.pushState(
              `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
              "auth-login",
              `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
            );
           window.location.reload();
          }, 2000);
    } else if(response.status==500){
      setTimeout(() => {
        setError("Something went wrong,Kindly try again later")
        setLoading(false);
      }, 2000);
    }else if(response.status==400){
      setLoading(false);
      if(Array.isArray(response.data.data)){
        let errorMsgList=[]
        response.data.data.map((data)=>{
          errorMsgList.push(data.message)
        })
        setError(errorMsgList)
      //  console.log("fieldErrorMsg>>>",errorMsgList)
      }
      else{
        setError(response.data.data.data)
      }
    }else if(response.status==401){
      setError(response.data.message)
      setLoading(false);
    } else{
      setLoading(false);
      setError('Something went wrong,Kindly try again later')
    }
    //console.log(response, '-<< response >>??')

  };

  const { errors, register, handleSubmit } = useForm();

  return (
    <React.Fragment>
      <Head title="Login" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={Logo} alt="logo-dark" />
            </Link>
          {/* <h1>Evvopay Admin</h1> */}
          </div>

          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Sign-In</BlockTitle>
                <BlockDes>
                  <p>Access Evvopay using your Email and Password.</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {errorVal}
                </Alert>
              </div>
            )}
            <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Email
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="default-01"
                    name="email"
                    tabIndex="1"
                    ref={register({ required: "This field is required" })}
                    placeholder="Enter your email address"
                    className="form-control-lg form-control"
                  />
                  {errors.email && <span className="invalid">{errors.email.message}</span>}
                </div>
              </div>
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                    Password
                  </label>
                  <Link className="link link-primary link-sm" to={`${process.env.PUBLIC_URL}/auth-reset`}>
                    Forgot Password?
                  </Link>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassState(!passState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                    <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                  </a>
                  <input
                    type={passState ? "text" : "password"}
                    id="password"
                    name="passcode"
                    tabIndex="2" 
                    ref={register({ required: "This field is required" })}
                    placeholder="Enter your passcode"
                    className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                  />
                  {errors.passcode && <span className="invalid">{errors.passcode.message}</span>}
                </div>
              </div>
              <div className="form-group">
                <Button size="lg" className="btn-block" type="submit" color="primary" tabIndex="3">
                  {loading ? <Spinner size="sm" color="light" /> : "Sign in"}
                </Button>
              </div>
            </Form>
            <div className="form-note-s2 text-center pt-4">
              {" "}
              New on our platform? <Link to={`${process.env.PUBLIC_URL}/auth-register`}>Create an account</Link>
            </div>
            <div className="text-center pt-4 pb-3">
              {/* <h6 className="overline-title overline-title-sap">
                <span>OR</span>
              </h6> */}
            </div>
            {/* <ul className="nav justify-center gx-4">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#socials"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  Facebook
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#socials"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  Google
                </a>
              </li>
            </ul> */}
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default Login;
