import React, { useEffect, useState } from "react";
import PageContainer from "../../../layout/page-container/PageContainer";
import Head from "../../../layout/head/Head";
import AuthFooter from "../../../pages/auth/AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
  Col,
  RSelect,
} from "../../../components/Component";
import Select from 'react-select';
import {
  DropdownMenu,
  DropdownToggle,
  
  UncontrolledDropdown,
  Modal,
  ModalBody,
  DropdownItem,
  Form,
} from "reactstrap";
import { Spinner } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { Link,useParams,useLocation } from "react-router-dom";
import { filterRole, filterStatus, permissionOption, userData } from "../../../pages/pre-built/user-manage/UserData";
import { generateSignature, decryptToken } from "../../../helpers/signature";
import { apiRequest } from "../../../helpers/api";
import { computeSmallestCellWidth } from "@fullcalendar/react";
import Logo from "../../../images/evvopay_logo.png";
import Loader from "../../../helpers/Loader";

const AddAccessControl = ({ history }) => {

    const userDetails = JSON.parse(decryptToken(localStorage.getItem('accessToken')))

    const registerForm={
    resource_id:'',  
    role_id:'',
    permission:'',
     // password:'',
     // user_role_id:''
    }
    const [registerFormData,setRegisterFormData]=useState(registerForm)
    const [passState, setPassState] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorStatus, setErrorStatus] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [fieldErrorMsg, setFieldErrorMsg] = useState([]);
    const { watch,errors, register, handleSubmit,control } = useForm();
    const [merchant, setMerchant] = useState('');
    const [password, setPassword] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confimPasswordErrorMessage, setConformPasswordErrorMessage] = useState('');
    const [phoneNoErrorMessage, setPhoneNoErrorMessage] = useState('');
    const [userRolesData, setUserRolesData] = useState([]);
    const [resourceList, setResourceList] = useState([]);
    const [userData, setUserData] = useState(userDetails || null);
  
   
  
  const handlePermissionChange = (selectedOption) => {

    console.log("selectedOption>>>",selectedOption)

    let dataArray=selectedOption.map(data=>data.value)
    console.log("dataArray>>>",dataArray.toString(","))

    setRegisterFormData((data)=>({
        ...data,
        permission: dataArray.toString(",")
    }))

    //   setMerchant(event.target.value);
    //   setRegisterFormData(currValue => ({
    //     ...currValue,
    //     merchant_name: event.target.value
    //   }))
    };
  
    const handlePhoneNoChange = (event) => {
      setPhoneNo(event.target.value);
      setRegisterFormData(currValue => ({
        ...currValue,
        telephone_no: event.target.value
      }))
    };
  
  const handlePasswordChange = (event) => {
    //setPassword(event.target.value);
    setRegisterFormData(currValue => ({
      ...currValue,
      password: event.target.value
    }))
    };
  

    const handleRoleChange = (status) => {
  
  
          setRegisterFormData(({
            ...registerFormData,
            user_role_id: status.value
          }))
    
    };
  
  
   
  
    useEffect(async ()=>{
      
     // console.log("userData>>>",userData)
      setErrorMsg('')
      setFieldErrorMsg([])
      setErrorStatus(false)
      await getUserRoles()
      await getResourceList()
    },[])
  
    const getUserRoles=async()=>{
   
      let data={
        "type":"open"
      }
     let signature=generateSignature(data)
  
      let response=await apiRequest('get',`get-user-roles?type=open`,signature)
     console.log("responsesetPaymentProcessorData>>>",response.data.data.data)
      if(response.status==200 || response.status==201){
  
         if(response.data.data.data.length>0){
          setUserRolesData(response.data.data.data)
         }
           
  
          else
          setUserRolesData([])
     
      }
      else{
        setUserRolesData([])
        return []
        
      }
    
    }
    const getResourceList=async()=>{
   
        let data={
          "type":"open"
        }
       let signature=generateSignature(data)
    
        let response=await apiRequest('get',`get-resource-list?type=open`,signature)
       console.log("getResourceList>>>",response.data.data.data)
        if(response.status==200 || response.status==201){
    
           if(response.data.data.data.length>0){
            setResourceList(response.data.data.data)
           }
             
    
            else
            setResourceList([])
       
        }
        else{
            setResourceList([])
          return []
          
        }
      
      }
    const handleFormSubmit = async (e) => {

      //  console.log("submit>>>",e)

     setLoading(true);
      setErrorMsg('')
      setFieldErrorMsg([])
      setErrorStatus(false)
  

      let dataArray=e.permission.map(data=>data.value)
      
      let data={
        ...registerFormData,
        userid:userData.user_id,
        permission:dataArray.toString(",")

      }

     // console.log("dataFormData>>",data)
      let signature=generateSignature(data)

      let response=await apiRequest('post','add-access-control-record',signature,data)
      // console.log("registerFormDataresponse>>",response)
      if(response.status==200 || response.status==201){
        setErrorStatus(false)
        setErrorMsg('')
        setFieldErrorMsg([])
        setTimeout(() => history.push(`${process.env.PUBLIC_URL}/access-control-list`), 2000);
      }
      else if(response.status==500){
        setLoading(false);
        setErrorStatus(true)
        setErrorMsg("Something went wrong,Kindly try again later")
      }
      else if (response.status==400){
        setLoading(false);
        setErrorStatus(false)
        setErrorStatus(true)
      //  console.log("response.data.data.data>>>",response.data.data)
        if(Array.isArray(response.data.data)){
          let errorMsgList=[]
          response.data.data.map((data)=>{
            errorMsgList.push(data.message)
          })
          setFieldErrorMsg(errorMsgList)
         // console.log("fieldErrorMsg>>>",errorMsgList)
        }
        else{
          setErrorMsg(response.data.data.data)
        }
      }
      else if (response.status==401){
        setLoading(false);
        setErrorStatus(true)
        setErrorMsg(response.data.message)
      }
     else{
      setLoading(false);
      setErrorStatus(true)
      setErrorMsg('Something went wrong,Kindly try again later')
     }
  
    };
  

    const isFormValid = !confimPasswordErrorMessage && !passwordErrorMessage

  return (


    <React.Fragment>
      <Head title="Register" />
    
     
        <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center" style ={{marginTop:'60px'}}>
            <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg"  src={Logo} alt="logo-dark" />
            </Link>
          </div>
    
              <PreviewCard  className="card-bordered" bodyClass="card-inner-lg">
              <BlockHead>
                <BlockContent>
                  <BlockTitle tag="h4"> Add Access Control</BlockTitle>
                  <BlockDes>
                  <p>Add New Access Control </p>
                </BlockDes>
                </BlockContent>
              </BlockHead>
              <form className="is-alter row" onSubmit={handleSubmit(handleFormSubmit)}>
            
              <div className="form-group ">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Resource name
                  </label>
                </div>
                <div className="form-control-wrap">
              <select
               
                className="form-control form-select"
                name="resource_id"
                // onChange={e => handleRoleChange(e)}
                onChange={(e)=>setRegisterFormData(currValue=>({
                  ...currValue,
                  resource_id:e.target.value
                }))}
                // value={paymentProcStatusValue}
                ref={register({ required: "This field is required" })}
                style={{backgroundPosition: 'right 10px center',backgroundSize:'8px 9px'}}
              >
                <option value="">SELECT</option>
                {resourceList.map((data, key) => (
                  <option key={key} value={data.resource_id}>
                    {data.resource_name}
                  </option>
                ))}
              </select>
                
                  {errors.resource_id && <p className="invalid">{errors.resource_id.message}</p>}
                  
                </div>
              </div>
           <div className="form-group ">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    User Role
                  </label>
                </div>
                <div className="form-control-wrap">
              <select
               
                className="form-control form-select"
                name="user_role_id"
                // onChange={e => handleRoleChange(e)}
                onChange={(e)=>setRegisterFormData(currValue=>({
                  ...currValue,
                  role_id:e.target.value
                }))}
                // value={paymentProcStatusValue}
                ref={register({ required: "This field is required" })}
                style={{backgroundPosition: 'right 10px center',backgroundSize:'8px 9px'}}
              >
                <option value="">SELECT</option>
                {userRolesData.map((data, key) => (
                  <option key={key} value={data.id}>
                    {data.user_role}
                  </option>
                ))}
              </select>
                
                  {errors.user_role_id && <p className="invalid">{errors.user_role_id.message}</p>}
                  
                </div>
              </div>
              <div className="form-group ">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Permission
                  </label>
                </div>
                <div className="form-control-wrap">
                {/* <Select
                    classNamePrefix="react-select"
                    className={`react-select-container`}
                   // value={selectedOption}
                    onChange={handlePermissionChange}
                    options={permissionOption}
                    isMulti
                    name="permission"
                    ref={register({ required: "This field is required" })}
                /> */}

                <Controller
                name="permission"
                as={Select}
                defaultValue=""
                options={[ ...permissionOption]}
                control={control}
                rules={{ required: true }}
              //  onChange={(data)=>{console.log("hellow")}}
                isMulti
              />
                {
                //    console.log(errors)
                }
                  {errors.permission && <p className="invalid">The field is required</p>}
                  
                </div>
              </div>
              
              {
                errorStatus==true?
                 errorMsg!=''?<span style={{color:'red'}} className="invalid">{`* `+errorMsg}</span>
                 :null
                :null
              }
              {
                fieldErrorMsg.length>0?
                fieldErrorMsg.map((msg,key)=>{
                  return <div  key ={key} style={{color:'red'}}><span  className="invalid">{`* `+msg}</span></div>
                })
                :null
              }
              {/* {
                console.log("fieldErrorMsg>>>>",fieldErrorMsg.length)
              } */}
              <div className="form-group">
                <Button  style={errorStatus?{marginTop:'10px'}:{}} type="submit" disabled={!isFormValid} color="primary" size="lg" className="btn-block" tabIndex="5">
                  {loading ? <Spinner size="sm" color="light" /> : "Add"}
                </Button>
              </div>
            </form>
            </PreviewCard>
        
         
        </Block>
        {/* <AuthFooter /> */}
      </PageContainer>
      
    
    </React.Fragment>
  );
};
export default AddAccessControl;
