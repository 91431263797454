import React from "react";
import Logo from "../../images/evvopay_logo.png";
import LogoDark from "../../images/logo-dark.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Block, Button, BlockContent, BlockDes, BlockHead, BlockTitle } from "../../components/Component";
import { Link } from "react-router-dom";

const Success = () => {
  return (
    <React.Fragment>
      <Head title="Success" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body">
          <div className="brand-logo pb-2">
            <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={Logo} alt="logo-dark" />
            </Link>
            {/* <h1>Evvopay Admin</h1> */}
          </div>
          <BlockHead>
            <BlockContent>
              {/* <BlockTitle tag="h4">Thank you for submitting form</BlockTitle> */}
              <BlockDes className="text-success">
                <p>Thank you for registering with Evvopay. Please click the "Sign In" button below.</p>
                
              </BlockDes>
            </BlockContent>
            <br />
            <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
                {/* <strong>Sign in</strong> */}
                <Button size="sm" color="primary"> Sign in</Button>
               </Link>
          </BlockHead>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default Success;
