import React, { useEffect, useRef, useState } from "react";
import Logo from "../../images/evvopay_logo.png";
import LogoDark from "../../images/logo-dark.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
  RSelect,
} from "../../components/Component";
import { Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import axios from "axios";
import qs from "qs"
import { decryptToken, generateSignature } from "../../helpers/signature";
import { ALGORITHM_TYPE, EVVOPAY_API, EVVOPAY_PRIVATE_KEY } from "../../constants";
import { apiRequest } from "../../helpers/api";

const Register = ({ history }) => {

  const userDetails = JSON.parse(decryptToken(localStorage.getItem('accessToken')))

  const registerForm={
    name:'',  
    email_id:'',
    telephone_no:'',
   // password:'',
    user_role_id:''
  }
  const [registerFormData,setRegisterFormData]=useState(registerForm)
  const [passState, setPassState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [fieldErrorMsg, setFieldErrorMsg] = useState([]);
  const { watch,errors, register, handleSubmit } = useForm();
  const [merchant, setMerchant] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confimPasswordErrorMessage, setConformPasswordErrorMessage] = useState('');
  const [phoneNoErrorMessage, setPhoneNoErrorMessage] = useState('');
  const [userRolesData, setUserRolesData] = useState([]);
  const [userData, setUserData] = useState(userDetails || null);

  //  const password_val =useRef()
  //  password_val.current=watch('password')

const handleMerchantChange = (event) => {
    setMerchant(event.target.value);
    setRegisterFormData(currValue => ({
      ...currValue,
      merchant_name: event.target.value
    }))
  };

  const handlePhoneNoChange = (event) => {
    setPhoneNo(event.target.value);
    setRegisterFormData(currValue => ({
      ...currValue,
      telephone_no: event.target.value
    }))
  };

const handlePasswordChange = (event) => {
  //setPassword(event.target.value);
  setRegisterFormData(currValue => ({
    ...currValue,
    password: event.target.value
  }))
  };

const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

const validateMerchant = (event) => {
    const letterRegex = /^[A-Za-z ]+$/i;

    if (!merchant) {
      setErrorMessage('Merchant Name is required');
    }else if (merchant.length < 3) {
      setErrorMessage('Merchant Name should be more than 3 characters');
    }else if (merchant.length > 500) {
      setErrorMessage('Only 500 characters are allowed');
    } else if (!letterRegex.test(merchant)) {
      setErrorMessage('Merchant Name should contain only characters');
    } else {
      setErrorMessage('');
    }
  };

  const validatePhoneNo = (event) => {
    const numberRegex = /^[0-9]{0,12}$/;
    if (!phoneNo) {
      setPhoneNoErrorMessage('Phone Number is required');
    } else if (!numberRegex.test(phoneNo)) {
      setPhoneNoErrorMessage('Invalid Phone Number');
    } else {
      setPhoneNoErrorMessage('');
    }
  };
  const handleRoleChange = (status) => {


        setRegisterFormData(({
          ...registerFormData,
          user_role_id: status.value
        }))
  
  };


  const validateConfirmPassword = () => {
     if (password !== confirmPassword) {
      setConformPasswordErrorMessage('Passwords do not match');
      return;
    }else{
    setConformPasswordErrorMessage('');
  }
  };

  useEffect(async ()=>{
    
   // console.log("userData>>>",userData)
    setErrorMsg('')
    setFieldErrorMsg([])
    setErrorStatus(false)
    await getUserRoles()
  },[])

  const getUserRoles=async()=>{
 
    let data={
      "type":"open"
    }
   let signature=generateSignature(data)

    let response=await apiRequest('get',`get-user-roles?type=open`,signature)
   console.log("responsesetPaymentProcessorData>>>",response.data.data.data)
    if(response.status==200 || response.status==201){

       if(response.data.data.data.length>0){
        //  let filterData=response.data.data.data.map((item)=>{

        // //  console.log("item.user_role.toLowerCase()>>>,",item.user_role.toLowerCase())
        //    // if(item.user_role.toLowerCase()!='superadmin')
        //       return {'value':item.id,'label':item.user_role.toUpperCase()}
        //  })
        //  setUserRolesData(filterData)
        setUserRolesData(response.data.data.data)
       }
         

        else
        setUserRolesData([])
   
    }
    else{
      setUserRolesData([])
      return []
      
    }
  
  }
  const handleFormSubmit = async () => {
    console.log("registerFormData>>>>",registerFormData)
   setLoading(true);
    setErrorMsg('')
    setFieldErrorMsg([])
    setErrorStatus(false)

   // console.log("registerFormData>>",registerFormData)
    let signature=generateSignature(registerFormData)
    
    let data={
      ...registerFormData,
      userid:userData.user_id
    }
    // console.log("userData>>",userData)
    // console.log("data>>",data)
    // console.log("registerFormData>>",registerFormData)
    let response=await apiRequest('post','add-sub-user',signature,data)
    // console.log("registerFormDataresponse>>",response)
    if(response.status==200 || response.status==201){
      setErrorStatus(false)
      setErrorMsg('')
      setFieldErrorMsg([])
       setTimeout(() => history.push(`${process.env.PUBLIC_URL}/user-list`), 2000);
    }
    else if(response.status==500){
      setLoading(false);
      setErrorStatus(true)
      setErrorMsg("Something went wrong,Kindly try again later")
    }
    else if (response.status==400){
      setLoading(false);
      setErrorStatus(false)
      setErrorStatus(true)
    //  console.log("response.data.data.data>>>",response.data.data)
      if(Array.isArray(response.data.data)){
        let errorMsgList=[]
        response.data.data.map((data)=>{
          errorMsgList.push(data.message)
        })
        setFieldErrorMsg(errorMsgList)
       // console.log("fieldErrorMsg>>>",errorMsgList)
      }
      else{
        setErrorMsg(response.data.data.data)
      }
    }
    else if (response.status==401){
      setLoading(false);
      setErrorStatus(true)
      setErrorMsg(response.data.message)
    }
   else{
    setLoading(false);
    setErrorStatus(true)
    setErrorMsg('Something went wrong,Kindly try again later')
   }

  };

  const isFormValid = !confimPasswordErrorMessage && !passwordErrorMessage

  
  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <React.Fragment>
      <Head title="User Register" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs" >
          <div className="brand-logo pb-4 text-center">
            <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={Logo} alt="logo-dark" />
            </Link>
            {/* <h1>Evvopay Admin</h1> */}
            </div>
    
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg" style={{
                      backgroundColor: '#f0f0f0',
                      padding: '20px',
                      borderRadius: '40px',
                      height: '800px',
                      width: '600px'
                      // Add more CSS properties as needed
                    }}>
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4"> User Register</BlockTitle>
                <BlockDes>
                  <p>Create New Evvopay user Account</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            <form className="is-alter row" onSubmit={handleSubmit(handleFormSubmit)}>
              <div className="form-group ">
                <label className="form-label" htmlFor="name">
                  Name
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter your name"
                    tabIndex="1"
                    ref={register({ required: true,
                    maxLength: 500,
                    //message: "Only 500 characters are allowed"
                  })}
                      // ref={register('merchant_name',{required:true,maxLength:5})}
                      // {...register( { required: true, maxLength: 2 })}
                    className="form-control-lg form-control"
                    // onChange={(e)=>setRegisterFormData(currValue=>({
                    //   ...currValue,
                    //   merchant_name:e.target.value
                    // }))}
                    // onChange={handleMerchantChange}
                    // onBlur={validateMerchant}
                    onChange={(e)=>setRegisterFormData(currValue=>({
                      ...currValue,
                      name:e.target.value.toLowerCase()
                    }))}
                  />
                    {/* {errorMessage && <p>{errorMessage}</p>} */}
                    {/* {console.log(errors)} */}
                  {/* {errors.merchant_name && <p className="invalid">{errors.merchant_name.message}</p>} */}
                  {errors.name && errors.name.type==='required' && <p className="invalid">The field is required</p>}
                  {errors.name && errors.name.type==='maxLength' && <p className="invalid">Only 500 characters are allowed</p>}

                </div>
              </div>
              <div className="form-group ">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Email
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    bssize="lg"
                    id="default-01"
                    name="email_id"
                    tabIndex="3"
                    ref={register({ required: 'The field is required',
                      pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'Please enter a valid email',
                     }
                      
                    })}
                    className="form-control-lg form-control"
                    placeholder="Enter your email"
                    onChange={(e)=>setRegisterFormData(currValue=>({
                      ...currValue,
                      email_id:e.target.value.toLowerCase()
                    }))}
                  />
                  {errors.email_id && <p className="invalid">{errors.email_id.message}</p>}
                  
                </div>
              </div>
              <div className="form-group ">
                <label className="form-label" htmlFor="name">
                  Phone No
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="telephone_no"
                    name="telephone_no"
                    placeholder="Enter your no"
                    tabIndex="2"
                    ref={register({ required: 'The field is required',
                    pattern: {
                      value: /^(0|[1-9]\d*)(\.\d+)?$/,
                      message: 'Field value is invalid',
                   }
                    
                  })}
                    className="form-control-lg form-control"
                    // onChange={(e)=>setRegisterFormData(currValue=>({
                    //   ...currValue,
                    //   telephone_no:e.target.value
                    // }))}
                    // onChange={handlePhoneNoChange}
                    // onBlur={validatePhoneNo}
                    onChange={(e)=>setRegisterFormData(currValue=>({
                      ...currValue,
                      telephone_no:e.target.value
                    }))}
                  />
                  {errors.telephone_no && <p className="invalid">{errors.telephone_no.message}</p>}
                  {/* {phoneNoErrorMessage && <p>{phoneNoErrorMessage}</p>} */}
                  </div>
              </div>
             
           <div className="form-group ">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    User Role
                  </label>
                </div>
                <div className="form-control-wrap">
                {/* <RSelect
                  options={userRolesData}
                  placeholder="Any Status"
                  // onChange={handleStatusChange}
                  onChange={(status) =>
                    handleRoleChange(status)
                  } */}
              <select
               
                className="form-control form-select"
                name="user_role_id"
                // onChange={e => handleRoleChange(e)}
                onChange={(e)=>setRegisterFormData(currValue=>({
                  ...currValue,
                  user_role_id:e.target.value
                }))}
                // value={paymentProcStatusValue}
                ref={register({ required: "This field is required" })}
                style={{backgroundPosition: 'right 10px center',backgroundSize:'8px 9px'}}
              >
                <option value="">SELECT</option>
                {userRolesData.map((data, key) => (
                  <option key={key} value={data.id}>
                    {data.user_role}
                  </option>
                ))}
              </select>
                
                  {errors.user_role_id && <p className="invalid">{errors.user_role_id.message}</p>}
                  
                </div>
              </div>
    
              
              {
                errorStatus==true?
                 errorMsg!=''?<span style={{color:'red'}} className="invalid">{`* `+errorMsg}</span>
                 :null
                :null
              }
              {
                fieldErrorMsg.length>0?
                fieldErrorMsg.map((msg,key)=>{
                  return <div  key ={key} style={{color:'red'}}><span  className="invalid">{`* `+msg}</span></div>
                })
                :null
              }
              {/* {
                console.log("fieldErrorMsg>>>>",fieldErrorMsg.length)
              } */}
              <div className="form-group">
                <Button  style={errorStatus?{marginTop:'10px'}:{}} type="submit" disabled={!isFormValid} color="primary" size="lg" className="btn-block" tabIndex="5">
                  {loading ? <Spinner size="sm" color="light" /> : "Register"}
                </Button>
              </div>
            </form>
            {/* <div className="form-note-s2 text-center pt-4">
              {" "}
              Already have an account?{" "}
              <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
                <strong>Sign in instead</strong>
              </Link>
            </div> */}
          </PreviewCard>

        </Block>
        {/* <AuthFooter /> */}
      </PageContainer>

     
    </React.Fragment>
  );
};
export default Register;
