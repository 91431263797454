
import React, { useRef, useEffect, useState } from 'react';
import { CardTitle } from 'reactstrap';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import TransactionTable from '../../../partials/default/transaction/Transaction';
import { BarChart } from "../../charts/default/Charts";

const ActiveSubscription = (props) => {
  const { getMerchantCount, message, activeMerchant, sum, showBarChat, paymentTransactions, user_role} = props;
  const defaultMessage = 'Default Message';
  const displayMessage = message || defaultMessage;
  const [showPopover, setShowPopover] = useState(false);
  const popoverRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setShowPopover(false);
      }
    };

    document.body.addEventListener('click', handleClickOutside);

    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handlePopoverClick = () => {
    setShowPopover(!showPopover);
  };

  const popoverStyle = {
    minWidth: '700px', 
  };


  const popover = (
    // <Popover id="message-popover" ref={popoverRef} style={popoverStyle}>
    //   {/* {
    //     // console.log("paymentTransactions.>>",paymentTransactions)
    //     paymentTransactions==undefined?null
    //     :
    //     <Popover.Body>
    //     <TransactionTable paymentTransactions={paymentTransactions} />
    //   </Popover.Body>
    //   } */}
      
    //   <Popover.Body>
    //     <TransactionTable paymentTransactions={paymentTransactions} />
    //   </Popover.Body>
    // </Popover>
      <></>
  );



  return (
    <React.Fragment>
      <div className="card-title-group align-start mb-2">
        <CardTitle>
          <OverlayTrigger trigger="click" placement="bottom" overlay={popover} show={showPopover}>
            <h6 className="title" onClick={handlePopoverClick}>
              {displayMessage}
            </h6>
          </OverlayTrigger>
        </CardTitle>
      </div>
      <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
        <div className="nk-sale-data">
        {/* {user_role.user_role === 'ADMIN' ? ( */}
        {user_role.user_role === 'OWNER' ? (
          <span className="amount">$ {sum}</span>
        ) : (
          <span className="amount">{sum}</span>
        )}

        </div>
        {/* {
          console.log("bar")
        } */}
        {showBarChat === 'true' && (
          <div className="nk-sales-ck">
            <BarChart apiRequest={activeMerchant} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ActiveSubscription;
