import React, { useEffect, useState } from "react";
import PageContainer from "../../../layout/page-container/PageContainer";
import Head from "../../../layout/head/Head";
import AuthFooter from "../../../pages/auth/AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
  Col,
  RSelect,
} from "../../../components/Component";
import {
  DropdownMenu,
  DropdownToggle,
  
  UncontrolledDropdown,
  Modal,
  ModalBody,
  DropdownItem,
  Form,
} from "reactstrap";
import { Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link,useParams,useLocation } from "react-router-dom";
import { filterRole, filterStatus, userData } from "../../../pages/pre-built/user-manage/UserData";
import { generateSignature, decryptToken } from "../../../helpers/signature";
import { apiRequest } from "../../../helpers/api";
import { computeSmallestCellWidth } from "@fullcalendar/react";
import Logo from "../../../images/evvopay_logo.png";
import Loader from "../../../helpers/Loader";
import { getAccessStatus } from "../../../helpers/common";
import { USER_MODULE } from "../../../constants";

const MerchantProfile = ({history}) => {

  const userDetails = JSON.parse(decryptToken(localStorage.getItem('accessToken')))

  const updateFormFormat={
    name:'',
    email_id:'',
    telephone_no:'',
    user_role_id:'',
    is_active:'',
    userid:''

  //   symbol:'',
  //   merchant_name:'',
  //   mcc_code:'',
  //  // business_website:'',
  //   business_scope:'',
  //   company_legal_name:'',
  //   company_registration_no:'',
  //   company_address:'',
  //   callback_url:'',
  //   business_email:'',
  //   payment_mode:'',
  //   processing_currency:'',
  //   settlement_currency:'',
  //   telephone_no:'',
  //   merchant_userid:'',
  //   merchant_password:'',
  //   connection_string:'',
  //   director_name:'',
  //   id_no:'',
  //   identity_type:'',
  //   nationality:'',
  //   residential_address:'',
  //   director_dob:'',
  //   is_active:'',
  //   payment_processor_id:'',
  //   postal_code:'',
  //   private_key:'',
  //   public_key:''

 //   merchantid:'',

    // email_id:'',
    // payment_mode:'',
    // processing_currency:'',
    // settlement_currency:'',
    // password:''
  }
  const userStatusValue=[
    {'value':'1','label':'Activate'},{'value':'0','label':'Suspend'}
  ]
    
  
  const [updateFormData,setUpdateFormData]=useState(updateFormFormat)
  const [statusValue,setStatusValue]=useState(null)
  const [paymentProcStatusValue,setPaymentProcStatusValue]=useState("")
  const [paymentProcessorData, setPaymentProcessorData] = useState([]);
  const [paymentProcessorStatus, setPaymentProcessorStatus] = useState(false);
  const [userStatus, setUserStatus] = useState(userStatusValue);
  const [passState, setPassState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [fieldErrorMsg, setFieldErrorMsg] = useState([]);
  const [userData, setUserData] = useState(userDetails || null);

  const { errors, register, handleSubmit,setError } = useForm();

  const location = useLocation();
//   const [userUid, setUid] = useState(location.state.userid);
  // const [userUid, setUid] = useState(29);
  const [userUid, setUid] = useState(location.state.userid);
  const [successMsg,setSuccessMsg]=useState(null);
  const [userRoleVal, setUserRoleVal] = useState("");

  const [files, setFiles] = useState(null);
  const [uploadKeyStatus, setUploadKeyStatus] = useState(false);
  const [uploadKeyStatus2, setUploadKeyStatus2] = useState(false);
  const [fileErrorStatus, setFileErrorStatus] = useState(false);
  const [fileErrorStatus2, setFileErrorStatus2] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userRolesData, setUserRolesData] = useState([]);
  const [writeStatus, setWriteStatus] = useState(true);

  // const accessToken=JSON.parse(localStorage.getItem('accessToken'))
  // const decrypt = decryptToken(localStorage.getItem('accessToken'))

  //const accessToken = decrypt;
  useEffect(()=>{
    // console.log("location.state.userid>>",location.state)
    // console.log("userUid>>",userUid)
    let {readStatus,writeStatus,deleteStatus}=getAccessStatus(userData,USER_MODULE)
    if(!readStatus){
     history.push(`${process.env.PUBLIC_URL}/errors/504-classic`);
    }
    setWriteStatus(writeStatus)
    
     getUsersData()
     getUserRoles()
    // getPaymentProcessData()
  },[])

  const getUserRoles=async()=>{
 
    let data={
      "type":"open"
    }
   let signature=generateSignature(data)

    let response=await apiRequest('get',`get-user-roles?type=open`,signature)
   //console.log("responsesetPaymentProcessorData>>>",response.data.data.data)
    if(response.status==200 || response.status==201){

    //  console.log("flag-1")
       if(response.data.data.data.length>0){
      //  console.log("flag-2",response.data.data.data)
        setUserRolesData(response.data.data.data)
       }
         

        else
        setUserRolesData([])
   
    }
    else{
      setUserRolesData([])
      return []
      
    }
  
  }
    // fetching merchant record
    const getUsersData=async()=>{
     
       let data={
         "userid":userUid
       }
      let signature=generateSignature(data)
   
       let response=await apiRequest('post',`get-sub-user-record`,signature,data)
       
      
       let responseArr = response.data.data.data;
      //  console.log(responseArr, 'responseArr')
      //  console.log(userRolesData, 'userRolesData')
       //let paymentProcessorName:any;
      //  let paymentProcessorName = responseArr.map((el) => el.paymentProcessorData?.[0]?.processor_name);
      // let paymentProcessorName = responseArr.map((el) => el.paymentProcessorData?.[0]?.processor_name)
      //                                       .filter((name) => typeof name === 'string').join(', ').toUpperCase()
      
       //let userRoleData=userRolesData.find((item)=>{return item.id==responseArr.user_role_id})
    //   console.log(responseArr.user_role_name, 'responseArr.user_role_name')
        //setUserRoleVal(userRoleData.user_role);
        setUserRoleVal(responseArr.user_role_id);
       if(response.status==200 || response.status==201){

          let data=response.data.data.data

        //  console.log("responseData>>",data)
          updateFormData.name=data.name
          updateFormData.email_id=data.email_id
          updateFormData.telephone_no=data.phone_no
          updateFormData.is_active=data.is_active
          updateFormData.userid=data.id
          updateFormData.user_role_id =data.user_role_id
          
          
          // if(data[1].merchantDirectorData!=null){
          // //  console.log("data[1].merchantDirectorData>>",data[1].merchantDirectorData)
          //   updateFormData.director_name=data[1].merchantDirectorData.director_name
          //   updateFormData.id_no=data[1].merchantDirectorData.id_no
          //   updateFormData.identity_type=data[1].merchantDirectorData.identity_type
          //   updateFormData.residential_address=data[1].merchantDirectorData.residential_address
          //   updateFormData.director_dob=data[1].merchantDirectorData.director_dob
          //   updateFormData.nationality=data[1].merchantDirectorData.nationality
          // }
          // if(data[2].merchantCountryData!=null){
          //  // console.log("data[3].merchantCountryData>>",data[2].merchantCountryData)
          //   updateFormData.country_code=data[2].merchantCountryData[0].country_code
          //   updateFormData.country_name=data[2].merchantCountryData[0].country_name
          //   updateFormData.currency=data[2].merchantCountryData[0].currency
          //   updateFormData.symbol=data[2].merchantCountryData[0].symbol
          //   updateFormData.postal_code=data[2].merchantCountryData[0].postal_code
          // }
          // // console.log("updateFormData>>>",updateFormData)
          // if(data[3].paymentProcessorData!=null){
          // //  console.log("data[3].paymentProcessorData>>",data[3].paymentProcessorData[0])
          //   updateFormData.payment_processor_id=data[3].paymentProcessorData[0].payment_processor_id
          //   updateFormData.public_key = data[3].paymentProcessorData[0].publicKey == 0 ? null : data[3].paymentProcessorData[0].publicKey
          //   updateFormData.private_key = data[3].paymentProcessorData[0].privateKey == 0 ? null : data[3].paymentProcessorData[0].privateKey
          // }
         // console.log("updateFormDatapaymentProcessorData>>>",updateFormData)
        //  let paymentProcStatusValue= data[3].paymentProcessorData!=null?
        //  {'value': data[3].paymentProcessorData[0].payment_processor_id, 'label': data[3].paymentProcessorData[0].processor_name}
        //    :
        //  {'value': '', 'label': 'Select'}

        // let paymentProcStatusValue= data[3].paymentProcessorData!=null?data[3].paymentProcessorData[0].payment_processor_id:""
    


        // updateFormData.is_active=updateFormData.is_active?'1':'0'
      
         let statusValue=updateFormData.is_active?{value: '1', label: 'Active'}:{value: '0', label: 'Suspend'}

       //  setPaymentProcStatusValue(paymentProcStatusValue)
      // console.log("updateFormData>>>",updateFormData,statusValue)
         setStatusValue(statusValue)
          setUpdateFormData(updateFormData)
          setIsLoading(false);
          //console.log("updateFormData>>>",updateFormData)
        //  setUpdateFormData(updateFormData)

       }
       else{

       // console.log("responseError>>",response.data)
         setIsLoading(false);
         return []
       }
     
     }

      // fetching payment processor record
    const getPaymentProcessData=async()=>{
 
       let data={
         "type":"open"
       }
      let signature=generateSignature(data)
   
       let response=await apiRequest('get',`get-payment-processor?type=open`,signature)
   //    console.log("responsesetPaymentProcessorData>>>",response.data.data.data)
       if(response.status==200 || response.status==201){

          if(response.data.data.data.length>0){
            let filterData=response.data.data.data.map((item)=>{
                return {'value':item.payment_processor_id,'label':item.name.toUpperCase()}
            })
            setPaymentProcessorData(filterData)
          }
            

           else
             setPaymentProcessorData([])
      
       }
       else{
         return []
       }
     
     }

  const handleFormSubmit = async() => {




    setLoading(true);
     setErrorMsg('')
     setFieldErrorMsg([])
     setErrorStatus(false)
     setSuccessMsg(null)

    let formData=null
    // let userid=null
    // userid=userData.user_id
    //console.log("location.state.user_merchant_mapping_id>>>",location.state.user_merchant_mapping_id)
    formData={
      ...updateFormData,
      user_merchant_mapping_id:location.state.user_merchant_mapping_id || ""
    }
   //  console.log("formDatabefore>>>",formData)
    // console.log("userUid>>>",userUid)

    //formData.is_active=='1'?true:false
  //   if(userData.user_role=='SUPERADMIN'){
  //     formData.u_id=userUid
  //   }
  //   else{
  //     delete formData.mcc_code
  //     delete formData.merchant_userid
  //     delete formData.merchant_password
  //     delete formData.is_active
  //     delete formData.payment_processor_id
  //     delete formData.private_key
  //     delete formData.public_key
  //  //   delete formData.userid
  //     //userid=userUid
  //   }

    //formData.userid=
    let signature=generateSignature(formData)
    
   
    //console.log("formDataforapi>>",formData)
    let response=await apiRequest('post',`update-sub-user`,signature,formData)
 //  console.log("responseData>>>>",response)
    if(response.status==200 || response.status==201){
      
      setLoading(false)
      setErrorStatus(false)
      setErrorMsg('')
      setFieldErrorMsg([])
      
      getUsersData()
      getUserRoles()
     
    //   if( userData.user_role=='SUPERADMIN'){
    //  //   console.log("formDataresponse>>",formData)
    //     getPaymentProcessData()
    //   //  setSuccessMsg('Records Updated successfully ')
    //     // if(formData.is_active=='1' && formData.payment_processor_id!=''  && formData.mcc_code!='' && formData.merchant_userid!='' && formData.merchant_password!=''){
    //     //   setSuccessMsg('Records Updated successfully and mail has been sent to your registered user mail ')
    //     // }
    //     // else{
    //     //   setSuccessMsg('Records Updated successfully ')

    //     // }
    //   }
      setSuccessMsg('Records Updated successfully ')
      // else{
      //   setSuccessMsg('Records Updated successfully ')
      // }
     //  setTimeout(() => history.push(`${process.env.PUBLIC_URL}/auth-success`), 2000);
    }
    else if(response.status==500){
      setSuccessMsg(null)
      setLoading(false);
      setErrorStatus(true)
      setErrorMsg("Something went wrong,Kindly try again later")
    }
    else if (response.status==400){
      setSuccessMsg(null)
      setLoading(false);
      setErrorStatus(false)
      setErrorStatus(true)
    //  console.log("response.data.data.data>>>",response.data.data)
      if(Array.isArray(response.data.data)){
        let errorMsgList=[]
        response.data.data.map((data)=>{
          errorMsgList.push(data.message)
        })
        setFieldErrorMsg(errorMsgList)
      }
      else{
        setErrorMsg(response.data.data.data)
      }
    }
    else if (response.status==401){
      setSuccessMsg(null)
      setLoading(false);
      setErrorStatus(true)
      setErrorMsg(response.data.message)
    }
   else{
    setSuccessMsg(null)
    setLoading(false);
    setErrorStatus(true)
    setErrorMsg('Something went wrong,Kindly try again later')
   }
   };


   const readFile=(file,type)=>{

    const fileReader = new FileReader();
    fileReader.readAsText(file.target.files[0], "UTF-8");
       

    fileReader.onload = e => {
     // setFiles(e.target.result);

      //console.log("fileReader>>>",type)

      if(type=='pr_k'){
       // updateFormData.private_key=e.target.result

        if(file.target.files[0].type!='text/plain'){
          setError("private_key",{
            type:"manual",
            message:"Only text file allowed"
          })
          updateFormData.private_key=''
         setFileErrorStatus2(true)
        
        }
        else{
          updateFormData.private_key=e.target.result
          setFileErrorStatus2(false)
         
        }

        setUpdateFormData(updateFormData)
      }
      
      else{

        if(file.target.files[0].type!='text/plain'){
          setError("public_key",{
            type:"manual",
            message:"Only text file allowed"
          })
          updateFormData.public_key=''
         setFileErrorStatus(true)
        }
        else{
          updateFormData.public_key=e.target.result
          setFileErrorStatus(false)
        
        }
        setUpdateFormData(updateFormData)
        
      }
       
      

      //console.log("loading>>",loading)
     // setUpdateFormData(updateFormData)
    };
   
   }

   const setKeyField=(type)=>{

    // setUploadKeyStatus(true)
    
    if(type=='pr_k'){
      setUploadKeyStatus(true)
      updateFormData.private_key=''
      setUpdateFormData(updateFormData)
    }
     
    else{
      setUploadKeyStatus2(true)
      updateFormData.public_key=''
      setUpdateFormData(updateFormData)
    }
      
   }

   const setPaymentProcessorOption=(e)=>{

    let index = e.nativeEvent.target.selectedIndex;
    let label = e.nativeEvent.target[index].text;
    setPaymentProcessor(label)
    setUpdateFormData({...updateFormData,payment_processor_id:e.target.value})
        // onChange={(e) => {
                            //  setPaymentProcessor(e.label)
                            //  console.log("e>>>",e)
                            // // setPaymentProcessorStatus(false)
                            // setUpdateFormData((prevFormData) => ({
                            // ...prevFormData,
                            // payment_processor_id: e.target.value,
                            // }));
                            // }}
   }

   const handleUserRoleSelect=(e)=>{
    let index = e.nativeEvent.target.selectedIndex;
    let label = e.nativeEvent.target[index].text;
  //  console.log("data>>>",e.target.value)
  //  console.log("data>>>",label)
    setUserRoleVal(e.target.value);
   

  //  setPaymentProcStatusValue({'value': null, 'label': null})
 // setPaymentProcStatusValue(e.target.value)
    setUpdateFormData((prevFormData) => ({
      ...prevFormData,
      user_role_id: e.target.value,
    }));
   }
  return (


    <React.Fragment>
      <Head title="Register" />
    
     
        <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center" style ={{marginTop:'60px'}}>
            <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg"  src={Logo} alt="logo-dark" />
            </Link>
          </div>
          {
               isLoading ?(
                <Loader />
              )
              :
              <PreviewCard  className="card-bordered" bodyClass="card-inner-lg">
              <BlockHead>
                <BlockContent>
                  <BlockTitle tag="h4">Update User</BlockTitle>
                </BlockContent>
              </BlockHead>
              <Form className="row gy-4" onSubmit={handleSubmit(handleFormSubmit)}>
            
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          defaultValue={updateFormData.name}
                          placeholder="Enter name"
                          onChange={(e)=>setUpdateFormData(currValue=>({
                            ...currValue,
                            name:e.target.value
                          }))}
                          ref={register({ required: true,
                            maxLength: 500,
                            //message: "Only 500 characters are allowed"
                          })}
                        />
                         {/* {console.log(errors)} */}
                         {errors.name && errors.name.type==='required' && <p className="invalid">The field is required</p>}
                      {errors.name && errors.name.type==='maxLength' && <p className="invalid">Only 500 characters are allowed</p>}
                      </div>
                    </Col>
                                       
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Email</label>
                        <input
                          className="form-control"
                          type="text"
                          name="email_id"
                          defaultValue={updateFormData.email_id}
                          onChange={(e)=>setUpdateFormData(currValue=>({
                           ...currValue,
                           email_id:e.target.value.toLowerCase()
                         }))}

                         ref={register({ required: 'The field is required',
                         pattern: {
                           value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                           message: 'Enter a valid email',
                        }
                         
                       })}
                      //    defaultValue={formData.email}
                          placeholder="Enter email"
                          // ref={register({
                          //   required: "This field is required",
                          //   pattern: {
                          //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          //     message: "invalid email address",
                          //   },
                          // })}
                        />
                           {errors.email_id && <p className="invalid">{errors.email_id.message}</p>}
                      </div>
                    </Col>
                
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Phone Number</label>
                        <input
                          className="form-control"
                          type="text"
                          name="telephone_no"
                          defaultValue={updateFormData.telephone_no}
                       //  defaultValue= "90000000000"
                         onChange={(e)=>setUpdateFormData(currValue=>({
                          ...currValue,
                          telephone_no:e.target.value
                        }))}
                        ref={register({ required: 'The field is required',
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                          message: 'Field value is invalid',
                       }
                        
                      })}
                          placeholder="Enter telephone no"
                          // ref={register({ required: "This field is required" })}
                        />
                         {errors.telephone_no && <p className="invalid">{errors.telephone_no.message}</p>}
                      </div>
                    </Col>
                    {/* {statusValue != null ? ( */}
                            <Col md="6">
                              <div className="form-group">
                                <label className="form-label">Role</label>
                                <div className="form-control-wrap">
                                   
                                    <select
                                    // disabled={userData.user_role !== 'SUPERADMIN'}    
                                    className="form-control form-select"
                                    name="payment_processor"
                                    onChange={e => handleUserRoleSelect(e)}
                                    // onChange={(e) => {
                                    //   setPaymentProcessor(e.label);
                                    //   setUpdateFormData((prevFormData) => ({
                                    //     ...prevFormData,
                                    //     payment_processor_id: e.value,
                                    //   }));
                                    // }}
                                    // value={paymentProcStatusValue.value}
                                    value={userRoleVal}
                                    ref={register({ required: "This field is required" })}
                                    style={{backgroundPosition: 'right 10px center',backgroundSize:'8px 9px'}}
                                  >
                                    <option value="">SELECT</option>
                                    {userRolesData.map((data, key) => (
                                      <option key={key} value={data.id}>
                                        {data.user_role}
                                      </option>
                                    ))}
                                  </select>
                                  {/* {errors.payment_processor && <span className="invalid">{errors.payment_processor.message}</span>} */}
  
                                </div>
                              </div>
                            </Col>
                          {/* ) : null} */}
                    
                      {/* // userData != null && userData.user_role === 'SUPERADMIN' ? (
                      //   <>
                      //     {statusValue != null ? ( */}
                            <Col md="6">
                              <div className="form-group">
                                <label className="form-label">Status</label>
                                <div className="form-control-wrap">
                                  <RSelect
                                    options={userStatus}
                                    defaultValue={{
                                      value: statusValue.value,
                                      label: statusValue.label,
                                    }}
                                    name="is_active"
                                    onChange={(e) =>
                                      setUpdateFormData((currValue) => ({
                                        ...currValue,
                                        is_active: e.value,
                                      }))
                                    }
                                  />
                                </div>
                              </div>
                            </Col>
                      {/* //     ) : null}
                      //   </>
                      // ) : null
                    } */}
                    {
                      errorStatus==true?
                      errorMsg!=''?<span style={{color:'red'}} className="invalid">{`* `+errorMsg}</span>
                      :null
                      :null
                    }
  
                  {
                  fieldErrorMsg.length>0?
                  fieldErrorMsg.map((msg,key)=>{
                    return <div  key ={key} style={{color:'red'}}><span  className="invalid">{`* `+msg}</span></div>
                  })
                  :null
                }
                {successMsg && 
               <div  style={{color:'green'}}><span  >{successMsg}</span></div>
               }
  
         
                    {
                      writeStatus && (
                      <Col size="12">
                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                          <li>
                            <Button  style={errorStatus?{marginTop:'10px'}:{}} color="primary" size="md" type="submit"
                            >
                                { loading ? <Spinner size="sm" color="light" /> : "Update User"}
                            </Button>
                          </li>
                    
                        </ul>
                      </Col>
                      )
                    }
                    
                  </Form>
         
            </PreviewCard>
          }
         
        </Block>
        {/* <AuthFooter /> */}
      </PageContainer>
      
    
    </React.Fragment>
  );
};
export default MerchantProfile;
