import React, {useState, useEffect} from "react";
import { CardTitle } from "reactstrap";
import { Icon, TooltipComponent } from "../../../Component";
import { BarChart } from "../../charts/default/Charts";
import { generateSignature } from '../../../../helpers/signature';
import { apiRequest } from '../../../../helpers/api';

const AvgSubscription = () => {
  const [totalMerchant, setTotalMerchant] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      console.log('here in ')
      
 let getMerchantCount = {
    status : 'active'
  }

  let signature=generateSignature(getMerchantCount)

  let response= await apiRequest('post', 'get-merchant-count', signature, getMerchantCount)
  if(response?.data?.data?.data){
    setTotalMerchant(response?.data?.data?.data)
  }

    };

    fetchData(); 
  }, []);

  return (
    <React.Fragment>
      {" "}
      <div className="card-title-group align-start mb-2">
        <CardTitle>
          <h6 className="title">Active Merchant Count</h6>
        </CardTitle>
        <div className="card-tools">
          <TooltipComponent
            icon="help-fill"
            iconClass="card-hint"
            direction="left"
            id="Tooltip-3"
            text="Daily Avg. subscription"
          />
        </div>
      </div>
      <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
        <div className="nk-sale-data">
          <span className="amount">{totalMerchant}</span>
          {/* <span className="sub-title">
            <span className="change up text-success">
              <Icon name="arrow-long-up" />
              2.45%
            </span>
            since last week
          </span> */}
        </div>
        <div className="nk-sales-ck">
          <BarChart />
        </div>
      </div>
    </React.Fragment>
  );
};
export default AvgSubscription;
