import axios from "axios";
import { EVVOPAY_API } from "../constants";

export  const apiRequest=async(method,url,signature=null,payload=null)=>{
    var config = {
        method: method,
        url: `${EVVOPAY_API}/${url}`,
        headers: { 
          signature:signature,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data : payload
      };

      let apiResponse=await callApi(config)
      return apiResponse
}

export const callApi = async(reqConfig)=> {
    return  await axios(reqConfig)
    .then(function (response) {
      return response
    })
    .catch(function (error) {
      
      console.log('error>>>',error);
      return error.response
    });

};

export const extractKey = async(response, key) => {
  let reqKey;
  switch (key) {
    case 'privateKey':
      reqKey = 'evvopay_private_key';
      break;
    case 'publicKey':
      reqKey = 'evvopay_public_key';
      break;
    default:
      reqKey = null; 
      break;
  }

  return response?.data?.data?.[reqKey];
}