import { DASHBOARD_MODULE, DELETE, MERCHANT_MODULE, READ, REPORTS_MODULE, SCREEN_TYPE, USER_MODULE, WRITE } from "../constants";


export const restructerObj = async(payload)=> {

    const filteredData = payload.filter((obj) => !obj.collection.includes("merchant_logs") 
    &&  !obj.collection.includes("user_logs") 
    &&  !obj.collection.includes("payment_processor_logs") 
    && !obj.collection.includes("exception_logs"));

    const renamedData = filteredData.map((obj) => {
        const collectionName = obj.collection
          .replace("_logs", "")
          .replace(/_/g, " ")
          .toLowerCase()
          .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase());
        
        return { ...obj, collection: collectionName };
      });

        function filterObjectsByCollection(objects, collectionValues) {
            return objects.filter((obj) => collectionValues.includes(obj.collection));
          }

          // allow only specific fields only 
        const filteredApiCallCount = filterObjectsByCollection(renamedData, ["Capture", "Refunds", "Transaction Details", "Preauth", "Transaction Cancels", "Payment Result"]);
        // const modifiedArray = filteredApiCallCount.map(obj => obj.collection === "Transaction Cancels" ? { ...obj, collection: "Payment Cancel" } : obj);
        const modifiedArray = filteredApiCallCount.map(obj => obj.collection === "Transaction Cancels" ? { ...obj, collection: "Payment Cancel" } : (obj.collection === "Payment Result" ? { ...obj, collection: "Payment" } : obj));

      return modifiedArray
};


export const restructerObjForPayment = async(payload)=> {

  const modifiedData = payload.map((obj, index) => ({ ...obj, 'sl.no': index + 1 }));
  return modifiedData;
};


export const modifyDateFormat = async (payload) => {
  const modifiedData = payload.map((el) => {
    const modifiedDate = el.date ? new Date(parseInt(el.date) * 1000).toLocaleDateString('en-GB') : '';

    let modifiedAmount = el.amount ? parseFloat(el.amount) : 0;
    if (modifiedAmount % 1 === 0) {
      modifiedAmount = modifiedAmount.toFixed(2);
    } else {
      modifiedAmount = modifiedAmount.toString();
    }

    return {
      ...el,
      date: modifiedDate,
      amount: modifiedAmount
    };
  });

  return modifiedData;
};

export const convertDateFormate = async (data) => {
  return data.map((obj) => {
    const registerDate = obj.register_date ? new Date(obj.register_date).toLocaleDateString('en-GB') : '';
    return {
      ...obj,
      register_date: registerDate
    };
  });
}


export const currentYear = new Date().getFullYear();

export const calculateTotalSumAmount = async (data) => {
  let paymentSumAmount = 0;
  let modifiedData = data.map(obj => {
    if (obj.collection === "capture_logs" || obj.collection === "payment_result_logs") {
      paymentSumAmount += obj.successSumAmount;
      return null; // Remove the capture_logs and payment_result_logs objects from the array
    } else if (obj.collection === "transaction_cancels") {
      obj.collection = "Payment Cancel"; // Rename the collection to "Payment Cancel"
    } else if (obj.collection === "refunds") {
      obj.collection = "Refunds"; // Capitalize the word "refunds"
    }
    return obj;
  });

  modifiedData = modifiedData.filter(obj => obj !== null); // Filter out the null values

  const formattedPaymentSumAmount = paymentSumAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  const paymentObject = {
    collection: "Payment",
    successSumAmount: formattedPaymentSumAmount,
    failedSumAmount: 0,
    errorSumAmount: 0,
    sumAmount: formattedPaymentSumAmount
  };

  const paymentCancelIndex = modifiedData.findIndex(obj => obj.collection === "Payment Cancel");
  const refundIndex = modifiedData.findIndex(obj => obj.collection === "Refunds");

  if (paymentCancelIndex > -1) {
    const paymentCancelObject = modifiedData.splice(paymentCancelIndex, 1)[0];
    modifiedData.unshift(paymentCancelObject); // Add the Payment Cancel object to the beginning of the array
  }

  if (refundIndex > -1) {
    const refundObject = modifiedData.splice(refundIndex, 1)[0];
    modifiedData.unshift(refundObject); // Add the Refunds object to the beginning of the array
  }

  // Format successSumAmount for all collections with two decimal places
  modifiedData.forEach(obj => {
    if (obj.hasOwnProperty('successSumAmount')) {
      obj.successSumAmount = Number(obj.successSumAmount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }
  });

  modifiedData.unshift(paymentObject); // Add the Payment object to the beginning of the array
//console.log(modifiedData, '-,<< modifiedData')
  return modifiedData;
}


export const getMenuAccess= (payload,userData)=>{

    // console.log("payloadstart>>>",payload)
    // console.log("userData>>>",userData)
  if(userData.user_role=='SUPERADMIN'  || userData.user_role=='OWNER')
  {
    if(userData.user_role=='SUPERADMIN'){
      return payload
    }
    else{

      payload.filter((data)=>{
           if(data.screenName==MERCHANT_MODULE){

          //console.log()
          let newSubMenu={...data.subMenu[1],text:'Merchant Profile'}
      //    console.log("newSubMenu>>",newSubMenu)
          data.subMenu=[]
          data.subMenu.splice(1,data.subMenu.length)
          data.subMenu.push(newSubMenu)
          //console.log("data.subMenu>>>",data.subMenu)
          // finalMenu.push(data)
          // return true
        }
      })

    //  console.log("payloadafter>>>",payload)
      return payload
    }
   
  }
  else if(userData.acl==null){
   // console.log("")
    payload.splice(1,payload.length)
  //  console.log("payloadafter>>>",payload)
    // return [{}]
    return payload
  }
  else{



    let finalMenu=[]
    payload.filter((data)=>{
      // return SCREEN_TYPE.includes(data.screenName)
      //console.log("databefore>>>",data)


        if(data.screenName==DASHBOARD_MODULE){
          finalMenu.push(data)
        }
        else if(data.screenName==MERCHANT_MODULE){

          //console.log()
          let newSubMenu={...data.subMenu[1],text:'Merchant Profile'}
         // console.log("newSubMenu>>",newSubMenu)
          data.subMenu=[]
          data.subMenu.push(newSubMenu)
        //  console.log("data.subMenu>>>",data.subMenu)
          // finalMenu.push(data)
        //  return true
        }
       
       
         userData.acl.map((item)=>{
         
           if(data.screenName==item.resource_name){
            finalMenu.push({...data,permission:item.permission})
            return true
          }
        })

         // console.log("finalMenu>>>",finalMenu)

    })
  //  console.log("finalMenubefore>>>",finalMenu)




    finalMenu.map((data)=>{
     // console.log("data>>>",data)

    //   if(data.subMenu.length!=0){
    //     // delete data.subMenu
    //     let subMenuData=[]
    //     data.subMenu.map((subMenuItem)=>{
    //     // console.log("subMenuItem>>>",subMenuItem)
    //       // console.log('subMenuItem.actionNam>>>',subMenuItem.actionNam)
    //     //  return data.permission.includes(subMenuItem.actionName)
    //       if(data.permission.includes(subMenuItem.actionName)){
    //       //    console.log("subMenuItemexist>>>",subMenuItem)
    //           subMenuData.push(subMenuItem)
    //       }
  
  
    //     })
    //      data.subMenu=subMenuData
    //  //   console.log("resp>>>",resp)
    //     return {
    //       ...data,
    //       // data.subMenu=subMenuData
    //     }
    //   }
      let subMenuData=[]

      // if(data.subMenu!=null){
     //  console.log("data.subMenu.length>>>",data.subMenu.length)
        if(data.subMenu!=null && data.subMenu.length!=0){
       //   console.log("flag-1>>>",data)
        data.subMenu.map((subMenuItem)=>{
         // console.log("subMenuItem>>>",subMenuItem)
           // console.log('subMenuItem.actionNam>>>',subMenuItem.actionNam)
         //  return data.permission.includes(subMenuItem.actionName)
           if(data.permission.includes(subMenuItem.actionName)){
              // console.log("subMenuItemexist>>>",subMenuItem)
               subMenuData.push(subMenuItem)
           }
          //  else
          //   console.log("flag-2")
   
   
         })
      }
      
      //  data.subMenu2=subMenuData
      data.subMenu=subMenuData
       
      return {
        ...data,
        // data.subMenu=subMenuData
      }

    })


 //   console.log("finalRespafter1>>>",finalMenu)
    let finalResp=finalMenu.filter((data)=>{
      
      return data.subMenu.length>0 || data.screenName==DASHBOARD_MODULE
      
      
        
    })

    //  delete finalMenu[0].subMenu
     delete finalResp[0].subMenu

    //  delete finalMenu[0].subMenu
    //  console.log("finalRespafter2>>>",finalMenu)
    //  return finalMenu

  //  console.log("finalResp>>>",finalResp)
    return finalResp
    
   
    // return [{}]
  }
}

export const getAccessStatus=(payload,type,actionType=null)=>{
 // let status
  let readStatus;
  let writeStatus;
  let deleteStatus;
  if(payload.user_role=='SUPERADMIN'  || payload.user_role=='OWNER')
  {
    //eturn true
    return {readStatus:true,writeStatus:true,deleteStatus:true}
  }
  else if(payload.acl==null){
    // return false
    return {readStatus:false,writeStatus:false,deleteStatus:false}
  
  }
  else{
    if(type==MERCHANT_MODULE){
      let permissionData=payload.acl.filter((data)=>{
        return data.resource_name==MERCHANT_MODULE
      })
    //  console.log("permissionData>>>",permissionData)

      if(permissionData.length==0){
        return {readStatus:false,writeStatus:false,deleteStatus:false}
      }
      else{
        readStatus=permissionData[0].permission.includes(READ)?true:false
        writeStatus=permissionData[0].permission.includes(WRITE)?true:false
        deleteStatus=permissionData[0].permission.includes(DELETE)?true:false
 
     //  console.log("writeStatus>>>",writeStatus)
       return {readStatus:readStatus,writeStatus:writeStatus,deleteStatus:deleteStatus}
      }
     
    }
    else if(type==USER_MODULE){
      let permissionData=payload.acl.filter((data)=>{
        return data.resource_name==USER_MODULE
      })
   //   console.log("permissionData>>>",permissionData[0].permission)

        if(permissionData.length==0){
          return {readStatus:false,writeStatus:false,deleteStatus:false}
        }
        else{
              readStatus=permissionData[0].permission.includes(READ)?true:false
          writeStatus=permissionData[0].permission.includes(WRITE)?true:false
          deleteStatus=permissionData[0].permission.includes(DELETE)?true:false

        //  console.log("writeStatus>>>",writeStatus)
          return {readStatus:readStatus,writeStatus:writeStatus,deleteStatus:deleteStatus}
        }
      

    }
    else if(type==REPORTS_MODULE){
      let permissionData=payload.acl.filter((data)=>{
        return data.resource_name==REPORTS_MODULE
      })


      if(permissionData.length==0){
        return {readStatus:false,writeStatus:false,deleteStatus:false}
      }
      else{
        readStatus=permissionData[0].permission.includes(READ)?true:false
        return {readStatus:readStatus,writeStatus:true,deleteStatus:true}
      }
      // readStatus=permissionData[0].permission.includes(READ)?true:false
      // return {readStatus:readStatus,writeStatus:true,deleteStatus:true}

      

    }
  }
}


