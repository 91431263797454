import React, { useEffect, useState } from "react";
import PageContainer from "../../../layout/page-container/PageContainer";
import Head from "../../../layout/head/Head";
import AuthFooter from "../../../pages/auth/AuthFooter";
import {
Block,
BlockContent,
BlockDes,
BlockHead,
BlockTitle,
Button,
Icon,
PreviewCard,
Col,
RSelect,
} from "../../../components/Component";
import {
DropdownMenu,
DropdownToggle,
UncontrolledDropdown,
Modal,
ModalBody,
DropdownItem,
Form,
} from "reactstrap";
import { Spinner } from "reactstrap";
import { useForm,Controller } from "react-hook-form";
import { Link,useParams,useLocation } from "react-router-dom";
import { filterRole, filterStatus, permissionOption, userData } from "../../../pages/pre-built/user-manage/UserData";
import { generateSignature, decryptToken } from "../../../helpers/signature";
import { apiRequest } from "../../../helpers/api";
import { computeSmallestCellWidth } from "@fullcalendar/react";
import Logo from "../../../images/evvopay_logo.png";
import Loader from "../../../helpers/Loader";
import Select from 'react-select';
const AccessControlProfile = () => {
const userDetails = JSON.parse(decryptToken(localStorage.getItem('accessToken')))
const updateFormFormat={
resource_id:'',
role_id:'',
permission:'',
acl_id:'',
is_active:'',
userid:''
}
const userStatusValue=[
{'value':'1','label':'Activate'},{'value':'0','label':'Suspend'}
]
const [updateFormData,setUpdateFormData]=useState(updateFormFormat)
const [statusValue,setStatusValue]=useState(null)
const [paymentProcStatusValue,setPaymentProcStatusValue]=useState("")
const [paymentProcessorData, setPaymentProcessorData] = useState([]);
const [paymentProcessorStatus, setPaymentProcessorStatus] = useState(false);
const [userStatus, setUserStatus] = useState(userStatusValue);
const [passState, setPassState] = useState(false);
const [loading, setLoading] = useState(false);
const [errorStatus, setErrorStatus] = useState(false);
const [errorMsg, setErrorMsg] = useState('');
const [fieldErrorMsg, setFieldErrorMsg] = useState([]);
const [userData, setUserData] = useState(userDetails || null);
const { errors, register, handleSubmit,setError,control } = useForm();
const location = useLocation();
//   const [aclId, setAclId] = useState(location.state.userid);
// const [aclId, setAclId] = useState(29);
const [aclId, setAclId] = useState(location.state.acl_id);
const [successMsg,setSuccessMsg]=useState(null);
const [userRoleVal, setUserRoleVal] = useState("");
const [resourceVal, setResourceVal] = useState("");
const [selectedPermission, setSelectedPermission] = useState();
const [files, setFiles] = useState(null);
const [uploadKeyStatus, setUploadKeyStatus] = useState(false);
const [uploadKeyStatus2, setUploadKeyStatus2] = useState(false);
const [fileErrorStatus, setFileErrorStatus] = useState(false);
const [fileErrorStatus2, setFileErrorStatus2] = useState(false);
const [isLoading, setIsLoading] = useState(true);
const [userRolesData, setUserRolesData] = useState([]);
const [resourceList, setResourceList] = useState([]);
const [permissionErrorMsg, setPermissionErrMsg] = useState(false);
// const accessToken=JSON.parse(localStorage.getItem('accessToken'))
// const decrypt = decryptToken(localStorage.getItem('accessToken'))
//const accessToken = decrypt;
useEffect(()=>{
console.log("location.state.userid>>",location.state)
console.log("aclId>>",aclId)
getUserRoles()
getResourceList()
getAccessRecord()
// getPaymentProcessData()
},[])
const getUserRoles=async()=>{
let data={
"type":"open"
}
let signature=generateSignature(data)
let response=await apiRequest('get',`get-user-roles?type=open`,signature)
console.log("responsesetPaymentProcessorData>>>",response.data.data.data)
if(response.status==200 || response.status==201){
console.log("flag-1")
if(response.data.data.data.length>0){
console.log("flag-2",response.data.data.data)
setUserRolesData(response.data.data.data)
}
else
setUserRolesData([])
}
else{
setUserRolesData([])
return []
}
}
const getResourceList=async()=>{
let data={
"type":"open"
}
let signature=generateSignature(data)
let response=await apiRequest('get',`get-resource-list?type=open`,signature)
console.log("getResourceList>>>",response.data.data.data)
if(response.status==200 || response.status==201){
if(response.data.data.data.length>0){
setResourceList(response.data.data.data)
}
else
setResourceList([])
}
else{
setResourceList([])
return []
}
}
// fetching merchant record
const getAccessRecord=async()=>{
let data={
"userid":userData.user_id,   
"acl_id":aclId
}
let signature=generateSignature(data)
let response=await apiRequest('post',`view-access-control-record`,signature,data)
let responseArr = response.data.data.data;
console.log(responseArr[0].role_id, 'responseArr')
console.log(userRolesData, 'userRolesData')
//let paymentProcessorName:any;
//  let paymentProcessorName = responseArr.map((el) => el.paymentProcessorData?.[0]?.processor_name);
// let paymentProcessorName = responseArr.map((el) => el.paymentProcessorData?.[0]?.processor_name)
//                                       .filter((name) => typeof name === 'string').join(', ').toUpperCase()
//let userRoleData=userRolesData.find((item)=>{return item.id==responseArr.user_role_id})
//console.log(responseArr.user_role_name, 'responseArr.user_role_name')
//setUserRoleVal(userRoleData.user_role);
let dataArray=responseArr[0].permission.split(",")
console.log("dataArray>>",dataArray)
let revisedData=[]
dataArray.map(item=>revisedData.push({value: item, label: item}))
console.log("revisedData>>",revisedData)
setSelectedPermission(revisedData)
setUserRoleVal(responseArr[0].role_id);
setResourceVal(responseArr[0].resource_id);
if(response.status==200 || response.status==201){
let data=responseArr[0]
console.log("responseData>>",typeof data.resource_id)
let statusValue=data.is_active?{value: '1', label: 'Active'}:{value: '0', label: 'Suspend'}
updateFormData.resource_id=data.resource_id.toString()
updateFormData.role_id=data.role_id.toString()
updateFormData.permission=data.permission
updateFormData.is_active=statusValue.value
updateFormData.userid=userData.user_id
updateFormData.acl_id =data.acl_id
//  setPaymentProcStatusValue(paymentProcStatusValue)
console.log("updateFormData>>>",updateFormData)
setStatusValue(statusValue)
setUpdateFormData(updateFormData)
setIsLoading(false);
//console.log("updateFormData>>>",updateFormData)
//  setUpdateFormData(updateFormData)
}
else{
// console.log("responseError>>",response.data)
setIsLoading(false);
return []
}
}
const handleFormSubmit = async(e) => {
console.log("updateFormData.payment_processor_id>>",e)
let status=e.permission.length==0?true:false
if(status){
setPermissionErrMsg(true)
return 
}
// if(userData.user_role=='SUPERADMIN' && updateFormData.payment_processor_id==''){
//   console.log("s1")
//   setPaymentProcessorStatus(true)
//   return
// }
// if(userData.user_role=='SUPERADMIN' && fileErrorStatus || fileErrorStatus2){
//   //setLoading(false);
// //  console.log("s2")
//   setPaymentProcessorStatus(true)
//   return
// }
// setLoading(true);
setLoading(true);
setErrorMsg('')
setFieldErrorMsg([])
setErrorStatus(false)
setSuccessMsg(null)
setPermissionErrMsg(false)
//  console.log("updateFormData>>>",updateFormData)
let dataArray=e.permission.map(data=>data.value)
let formData={
...updateFormData,
permission:dataArray.toString(",")
}
//  setUploadKeyStatus(false)
//  setUploadKeyStatus2(false)
//console.log(files)
// let formData=null
// // let userid=null
// // userid=userData.user_id
// console.log("location.state.user_merchant_mapping_id>>>",location.state.acl_id)
// formData={
//   ...updateFormData,
//   acl_id:location.state.acl_id || ""
// }
console.log("formDatabefore>>>",formData)
console.log(selectedPermission)
// console.log("aclId>>>",aclId)
//formData.is_active=='1'?true:false
//   if(userData.user_role=='SUPERADMIN'){
//     formData.u_id=aclId
//   }
//   else{
//     delete formData.mcc_code
//     delete formData.merchant_userid
//     delete formData.merchant_password
//     delete formData.is_active
//     delete formData.payment_processor_id
//     delete formData.private_key
//     delete formData.public_key
//  //   delete formData.userid
//     //userid=aclId
//   }
//formData.userid=
let signature=generateSignature(formData)
//console.log("formDataforapi>>",formData)
let response=await apiRequest('post',`update-access-control-record`,signature,formData)
//  console.log("responseData>>>>",response)
if(response.status==200 || response.status==201){
setLoading(false)
setErrorStatus(false)
setErrorMsg('')
setFieldErrorMsg([])
getAccessRecord()
getUserRoles()
//   if( userData.user_role=='SUPERADMIN'){
//  //   console.log("formDataresponse>>",formData)
//     getPaymentProcessData()
//   //  setSuccessMsg('Records Updated successfully ')
//     // if(formData.is_active=='1' && formData.payment_processor_id!=''  && formData.mcc_code!='' && formData.merchant_userid!='' && formData.merchant_password!=''){
//     //   setSuccessMsg('Records Updated successfully and mail has been sent to your registered user mail ')
//     // }
//     // else{
//     //   setSuccessMsg('Records Updated successfully ')
//     // }
//   }
setSuccessMsg('Records Updated successfully ')
// else{
//   setSuccessMsg('Records Updated successfully ')
// }
//  setTimeout(() => history.push(`${process.env.PUBLIC_URL}/auth-success`), 2000);
}
else if(response.status==500){
setSuccessMsg(null)
setLoading(false);
setErrorStatus(true)
setErrorMsg("Something went wrong,Kindly try again later")
}
else if (response.status==400){
setSuccessMsg(null)
setLoading(false);
setErrorStatus(false)
setErrorStatus(true)
//  console.log("response.data.data.data>>>",response.data.data)
if(Array.isArray(response.data.data)){
let errorMsgList=[]
response.data.data.map((data)=>{
errorMsgList.push(data.message)
})
setFieldErrorMsg(errorMsgList)
}
else{
setErrorMsg(response.data.data.data)
}
}
else if (response.status==401){
setSuccessMsg(null)
setLoading(false);
setErrorStatus(true)
setErrorMsg(response.data.message)
}
else{
setSuccessMsg(null)
setLoading(false);
setErrorStatus(true)
setErrorMsg('Something went wrong,Kindly try again later')
}
};
const handleUserRoleSelect=(e,keyName)=>{
// console.log(e)
// console.log(keyName)
let index = e.nativeEvent.target.selectedIndex;
let label = e.nativeEvent.target[index].text;
//    console.log("data>>>",e.target.value)
//    console.log("data>>>",label)
//     setUserRoleVal(e.target.value);
//  setPaymentProcStatusValue({'value': null, 'label': null})
// setPaymentProcStatusValue(e.target.value)
setUpdateFormData((prevFormData) => ({
...prevFormData,
"resource_id": e.target.value,
}));
}
const handlePermissionChange = (selectedOption) => {
console.log("selectedOption>>>",selectedOption)
let dataArray=selectedOption.map(data=>data.value)
console.log("dataArray>>>",dataArray.toString(","))
setSelectedPermission(selectedOption)
setUpdateFormData((data)=>({
...data,
permission: dataArray.toString(",")
}))
//   setMerchant(event.target.value);
//   setRegisterFormData(currValue => ({
//     ...currValue,
//     merchant_name: event.target.value
//   }))
};
return (
<React.Fragment>
   <Head title="Register" />
      <PageContainer>
         <Block className="nk-block-middle nk-auth-body  wide-xs">
            <div className="brand-logo pb-4 text-center" style ={{marginTop:'60px'}}>
               <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
               <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
               <img className="logo-dark logo-img logo-img-lg"  src={Logo} alt="logo-dark" />
               </Link>
            </div>
            {
            isLoading ?(
            <Loader />
            )
            :
            <PreviewCard  className="card-bordered" bodyClass="card-inner-lg">
               <BlockHead>
                  <BlockContent>
                     <BlockTitle tag="h4">Update Access Control</BlockTitle>
                  </BlockContent>
               </BlockHead>
               <Form className="row gy-4" onSubmit={handleSubmit(handleFormSubmit)}>
                  <Col md="12">
                  <div className="form-group">
                     <label className="form-label">Resource name</label>
                     <div className="form-control-wrap">
                        <select
                        // disabled={userData.user_role !== 'SUPERADMIN'}    
                        className="form-control form-select"
                        name="resource_name"
                        //  onChange={e => handleUserRoleSelect(e,'resource_id')}
                        onChange={(e)=>setUpdateFormData(prevFormData=>({
                        ...prevFormData,
                        resource_id:e.target.value
                        }))}
                        value={updateFormData.resource_id}
                        ref={register({ required: "This field is required" })}
                        style={{backgroundPosition: 'right 10px center',backgroundSize:'8px 9px'}}
                        >
                        {/* 
                        <option value="">SELECT</option>
                        */}
                        {resourceList.map((data, key) => (
                        <option key={key} value={data.resource_id}>
                           {data.resource_name}
                        </option>
                        ))}
                        </select>
                        {errors.resource_name && <span className="invalid">{errors.resource_name.message}</span>}
                     </div>
                  </div>
                  </Col>          
                  <Col md="12">
                  <div className="form-group">
                     <label className="form-label">Role</label>
                     <div className="form-control-wrap">
                        <select
                        // disabled={userData.user_role !== 'SUPERADMIN'}    
                        className="form-control form-select"
                        name="user_role"
                        // onChange={e => handleUserRoleSelect(e,'role_id')}
                        onChange={(e)=>setUpdateFormData(prevFormData=>({
                        ...prevFormData,
                        role_id:e.target.value
                        }))}
                        // value={paymentProcStatusValue.value}
                        value={updateFormData.role_id}
                        ref={register({ required: "This field is required" })}
                        style={{backgroundPosition: 'right 10px center',backgroundSize:'8px 9px'}}
                        >
                        {/* 
                        <option value="">SELECT</option>
                        */}
                        {userRolesData.map((data, key) => (
                        <option key={key} value={data.id}>
                           {data.user_role}
                        </option>
                        ))}
                        </select>
                        {errors.user_role && <span className="invalid">{errors.user_role.message}</span>}
                     </div>
                  </div>
                  </Col>
                  <Col md="12">
                  <div className="form-group">
                     <label className="form-label">Permission</label>
                     <div className="form-control-wrap">
                        {/* <Select
                        classNamePrefix="react-select"
                        className={`react-select-container`}
                        // value={selectedOption}
                        onChange={handlePermissionChange}
                        value={selectedPermission}
                        options={permissionOption}
                        isMulti
                        name="permission"
                        ref={register({ required: "This field is required" })}
                        /> */}
                        <Controller
                        name="permission"
                        as={Select}
                        defaultValue={selectedPermission}
                        options={[ ...permissionOption]}
                        control={control}
                        rules={{ required: true }}
                        //  onChange={(data)=>{console.log("hellow")}}
                        isMulti
                        />
                        {/* {console.log("errors>>",errors)} */}
                        {/* {errors.permission && <span className="invalid">The field is required</span>} */}
                        {permissionErrorMsg?  <span style={{color:"red"}}className="invalid">The field is required</span>: null}
                     </div>
                  </div>
                  </Col>
                  <Col md="12">
                  <div className="form-group">
                     <label className="form-label">Status</label>
                     <div className="form-control-wrap">
                        <RSelect
                        options={userStatus}
                        defaultValue={{
                        value: statusValue.value,
                        label: statusValue.label,
                        }}
                        name="is_active"
                        onChange={(e) =>
                        setUpdateFormData((currValue) => ({
                        ...currValue,
                        is_active: e.value,
                        }))
                        }
                        />
                     </div>
                  </div>
                  </Col>
                  {/* //     ) : null}
                  //   </>
                  // ) : null
                  } */}
                  {
                  errorStatus==true?
                  errorMsg!=''?<span style={{color:'red'}} className="invalid">{`* `+errorMsg}</span>
                  :null
                  :null
                  }
                  {
                  fieldErrorMsg.length>0?
                  fieldErrorMsg.map((msg,key)=>{
                  return 
                  <div  key ={key} style={{color:'red'}}><span  className="invalid">{`* `+msg}</span></div>
                  })
                  :null
                  }
                  {successMsg && 
                  <div  style={{color:'green'}}><span  >{successMsg}</span></div>
                  }
                  <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                     <li>
                        <Button  style={errorStatus?{marginTop:'10px'}:{}} color="primary" size="md" type="submit"
                           >
                           { loading ? 
                           <Spinner size="sm" color="light" />
                           : "Update User"}
                        </Button>
                     </li>
                  </ul>
                  </Col>
               </Form>
            </PreviewCard>
            }
         </Block>
         {/* 
         <AuthFooter />
         */}
      </PageContainer>
</React.Fragment>
);
};
export default AccessControlProfile;