import React, { useEffect, useState } from "react";
import PageContainer from "../../../layout/page-container/PageContainer";
import Head from "../../../layout/head/Head";
import AuthFooter from "../../../pages/auth/AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
  Col,
  RSelect,
} from "../../../components/Component";
import {
  DropdownMenu,
  DropdownToggle,
  
  UncontrolledDropdown,
  Modal,
  ModalBody,
  DropdownItem,
  Form,
} from "reactstrap";
import { Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link,useParams,useLocation } from "react-router-dom";
import { filterRole, filterStatus, userData } from "../../../pages/pre-built/user-manage/UserData";
import { generateSignature, decryptToken } from "../../../helpers/signature";
import { apiRequest } from "../../../helpers/api";
import { computeSmallestCellWidth } from "@fullcalendar/react";
import Logo from "../../../images/evvopay_logo.png";
import Loader from "../../../helpers/Loader";
import { OWNER, SUPERADMIN } from "../../../constants";

const MerchantProfile = () => {

  const userDetails = JSON.parse(decryptToken(localStorage.getItem('accessToken')))

  const updateFormFormat={
    country_code:'',
    country_name:'',
    currency:'',
    symbol:'',
    merchant_name:'',
    mcc_code:'',
   // business_website:'',
    business_scope:'',
    company_legal_name:'',
    company_registration_no:'',
    company_address:'',
    callback_url:'',
    business_email:'',
    payment_mode:'',
    processing_currency:'',
    settlement_currency:'',
    telephone_no:'',
    merchant_userid:'',
    merchant_password:'',
    connection_string:'',
    director_name:'',
    id_no:'',
    identity_type:'',
    nationality:'',
    residential_address:'',
    director_dob:'',
    is_active:'',
    payment_processor_id:'',
    postal_code:'',
    private_key:'',
    public_key:'',
    gateway_fee:'',
    mdr_percent:'',
    settlement_day:''
  }
  const userStatusValue=[
    {'value':'1','label':'Activate'},{'value':'0','label':'Suspend'}
  ]
    
  
  const [updateFormData,setUpdateFormData]=useState(updateFormFormat)
  const [statusValue,setStatusValue]=useState(null)
  const [paymentProcStatusValue,setPaymentProcStatusValue]=useState("")
  const [paymentProcessorData, setPaymentProcessorData] = useState([]);
  const [paymentProcessorStatus, setPaymentProcessorStatus] = useState(false);
  const [userStatus, setUserStatus] = useState(userStatusValue);
  const [passState, setPassState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [fieldErrorMsg, setFieldErrorMsg] = useState([]);
  const [userData, setUserData] = useState(userDetails || null);

  const { errors, register, handleSubmit,setError } = useForm();
  
  const location = useLocation();
  const [merchantidVal, setMerchantId] = useState(location.state.merchantid);
  const [successMsg,setSuccessMsg]=useState(null);
  const [paymentProcessor, setPaymentProcessor] = useState("");

  const [files, setFiles] = useState(null);
  const [uploadKeyStatus, setUploadKeyStatus] = useState(false);
  const [uploadKeyStatus2, setUploadKeyStatus2] = useState(false);
  const [fileErrorStatus, setFileErrorStatus] = useState(false);
  const [fileErrorStatus2, setFileErrorStatus2] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // const accessToken=JSON.parse(localStorage.getItem('accessToken'))
  // const decrypt = decryptToken(localStorage.getItem('accessToken'))

  //const accessToken = decrypt;
  useEffect(()=>{
    //console.log("errors.public_key.type>>",errors.public_key)
    // setErrorMsg('')
    // setFieldErrorMsg([])
    // setErrorStatus(false)

    //setUserStatus[{value: '1', label: 'Active'},{value: '0', label: 'Inactive'}]

    //console.log("accessToken>>",accessToken)
   //   setUserData(accessToken)
   //   setUserData({'userType':'SUPERADMIN','userid':25})
      getMerchantsData()
    // setUid(location.state.userid)
     getPaymentProcessData()
  },[])

    // fetching merchant record
    const getMerchantsData=async()=>{
      
     // console.log("userUid>>>",userUid)
       let data={
         "merchantid":merchantidVal
       }
      let signature=generateSignature(data)
   
       let response=await apiRequest('post',`get-merchant-record`,signature,data)
       
      //  console.log(response.data.data.data, '-<, response ??')
       let responseArr = response.data.data.data;

      //  let paymentProcessorName = responseArr.map((el) => el.paymentProcessorData?.[0]?.processor_name);
      let paymentProcessorName = responseArr.map((el) => el.paymentProcessorData?.[0]?.processor_name)
                                            .filter((name) => typeof name === 'string').join(', ').toUpperCase()
      //  console.log(paymentProcessorName, '-<< paymentProcessorName')
        setPaymentProcessor(paymentProcessorName);

       if(response.status==200 || response.status==201){

          let data=response.data.data.data

       //   console.log("responseData>>",data)
          updateFormData.merchant_name=data[0].merchantData.merchant_name
          updateFormData.merchantid=data[0].merchantData.merchantid
          updateFormData.mcc_code=data[0].merchantData.mcc_code
          updateFormData.business_scope=data[0].merchantData.business_scope
          updateFormData.company_legal_name=data[0].merchantData.company_legal_name
          updateFormData.company_registration_no=data[0].merchantData.company_registration_no
          updateFormData.company_address=data[0].merchantData.company_address
          updateFormData.callback_url=data[0].merchantData.callback_url
          updateFormData.business_email=data[0].merchantData.business_email
          updateFormData.processing_currency=data[0].merchantData.processing_currency
          updateFormData.settlement_currency=data[0].merchantData.settlement_currency
          updateFormData.telephone_no=data[0].merchantData.telephone_no
          updateFormData.merchant_userid=data[0].merchantData.merchant_userid
          updateFormData.merchant_password=data[0].merchantData.merchant_password
          updateFormData.connection_string=data[0].merchantData.connection_string
          updateFormData.is_active=data[0].merchantData.is_active
          
          
          if(data[1].merchantDirectorData!=null){
          //  console.log("data[1].merchantDirectorData>>",data[1].merchantDirectorData)
            updateFormData.director_name=data[1].merchantDirectorData.director_name
            updateFormData.id_no=data[1].merchantDirectorData.id_no
            updateFormData.identity_type=data[1].merchantDirectorData.identity_type
            updateFormData.residential_address=data[1].merchantDirectorData.residential_address
            updateFormData.director_dob=data[1].merchantDirectorData.director_dob
            updateFormData.nationality=data[1].merchantDirectorData.nationality
          }
          if(data[2].merchantCountryData!=null){
           // console.log("data[3].merchantCountryData>>",data[2].merchantCountryData)
            updateFormData.country_code=data[2].merchantCountryData[0].country_code
            updateFormData.country_name=data[2].merchantCountryData[0].country_name
            updateFormData.currency=data[2].merchantCountryData[0].currency
            updateFormData.symbol=data[2].merchantCountryData[0].symbol
            updateFormData.postal_code=data[2].merchantCountryData[0].postal_code
          }
          if(data[3].paymentProcessorData!=null){
            updateFormData.payment_processor_id=data[3].paymentProcessorData[0].payment_processor_id
            updateFormData.public_key = data[3].paymentProcessorData[0].publicKey == 0 ? null : data[3].paymentProcessorData[0].publicKey
            updateFormData.private_key = data[3].paymentProcessorData[0].privateKey == 0 ? null : data[3].paymentProcessorData[0].privateKey
          }
          if(data[4].commissionData!=null){
            updateFormData.gateway_fee=data[4].commissionData.gateway_fee
            updateFormData.mdr_percent = data[4].commissionData.mdr_fee
            updateFormData.settlement_day = data[4].commissionData.settlement_day
          }

        let paymentProcStatusValue= data[3].paymentProcessorData!=null?data[3].paymentProcessorData[0].payment_processor_id:""
    


         let statusValue=updateFormData.is_active?{value: '1', label: 'Activate'}:{value: '0', label: 'Suspend'}
         updateFormData.is_active=updateFormData.is_active?'1':'0'
         setPaymentProcStatusValue(paymentProcStatusValue)
         setStatusValue(statusValue)
   //       console.log("updateFormData>>>",updateFormData)
          setUpdateFormData(updateFormData)
          setIsLoading(false);
          //console.log("updateFormData>>>",updateFormData)
        //  setUpdateFormData(updateFormData)

       }
       else{

       // console.log("responseError>>",response.data)
         setIsLoading(false);
         return []
       }
     
     }

      // fetching payment processor record
    const getPaymentProcessData=async()=>{
 
       let data={
         "type":"open"
       }
      let signature=generateSignature(data)
   
       let response=await apiRequest('get',`get-payment-processor?type=open`,signature)
   //    console.log("responsesetPaymentProcessorData>>>",response.data.data.data)
       if(response.status==200 || response.status==201){

          if(response.data.data.data.length>0){
            let filterData=response.data.data.data.map((item)=>{
                return {'value':item.payment_processor_id,'label':item.name.toUpperCase()}
            })
            setPaymentProcessorData(filterData)
          }
            

           else
             setPaymentProcessorData([])
      
       }
       else{
         return []
       }
     
     }

  const handleFormSubmit = async() => {

    if(userData.user_role=='SUPERADMIN' && fileErrorStatus || fileErrorStatus2){
      //setLoading(false);
    //  console.log("s2")
      setPaymentProcessorStatus(true)
      return
    }
    
   // setLoading(true);


    setLoading(true);
     setErrorMsg('')
     setFieldErrorMsg([])
     setErrorStatus(false)
     setSuccessMsg(null)
     setUploadKeyStatus(false)
     setUploadKeyStatus2(false)
     //console.log(files)

    let formData=null
    let userid=null
    userid=userData.user_id
    formData={
      ...updateFormData,
    //  userid:userid
    }
    // console.log("formDatabefore>>>",formData)
    // console.log("userUid>>>",userUid)

    //formData.is_active=='1'?true:false
    if(userData.user_role=='SUPERADMIN'){
   //   formData.u_id=userUid
      formData.user_type=userData.user_role
    }
    else{
      delete formData.mcc_code
      delete formData.merchant_userid
      delete formData.merchant_password
      delete formData.is_active
      delete formData.payment_processor_id
      delete formData.private_key
      delete formData.public_key
      formData.user_type=userData.user_role
   //   delete formData.userid
      //userid=userUid
    }

    //formData.userid=
    let signature=generateSignature(formData)
    
   
    //console.log("formDataforapi>>",formData)
    let response=await apiRequest('put',`update-merchant-records`,signature,formData)
 //  console.log("responseData>>>>",response)
    if(response.status==200 || response.status==201){
      
      setLoading(false)
      setErrorStatus(false)
      setErrorMsg('')
      setFieldErrorMsg([])
      
      getMerchantsData()
     
      if( userData.user_role=='SUPERADMIN'){
     //   console.log("formDataresponse>>",formData)
        getPaymentProcessData()
      //  setSuccessMsg('Records Updated successfully ')
        // if(formData.is_active=='1' && formData.payment_processor_id!=''  && formData.mcc_code!='' && formData.merchant_userid!='' && formData.merchant_password!=''){
        //   setSuccessMsg('Records Updated successfully and mail has been sent to your registered user mail ')
        // }
        // else{
        //   setSuccessMsg('Records Updated successfully ')

        // }
      }
      setSuccessMsg('Records Updated successfully ')
      // else{
      //   setSuccessMsg('Records Updated successfully ')
      // }
     //  setTimeout(() => history.push(`${process.env.PUBLIC_URL}/auth-success`), 2000);
    }
    else if(response.status==500){
      setSuccessMsg(null)
      setLoading(false);
      setErrorStatus(true)
      setErrorMsg("Something went wrong,Kindly try again later")
    }
    else if (response.status==400){
      setSuccessMsg(null)
      setLoading(false);
      setErrorStatus(false)
      setErrorStatus(true)
    //  console.log("response.data.data.data>>>",response.data.data)
      if(Array.isArray(response.data.data)){
        let errorMsgList=[]
        response.data.data.map((data)=>{
          errorMsgList.push(data.message)
        })
        setFieldErrorMsg(errorMsgList)
      }
      else{
        setErrorMsg(response.data.data.data)
      }
    }
    else if (response.status==401){
      setSuccessMsg(null)
      setLoading(false);
      setErrorStatus(true)
      setErrorMsg(response.data.message)
    }
   else{
    setSuccessMsg(null)
    setLoading(false);
    setErrorStatus(true)
    setErrorMsg('Something went wrong,Kindly try again later')
   }

   
    //   console.log("registerFormData>>>",registerFormData)
 
     // registerFormData.
    //let signature="AorFkeqCC4827Q5slcmjx6ZxU15/j3GFhPMTt4xFYi/M7ahNIFHajyH6tQ+MeuTotH+qjwldqB8PVbCav4BbIH8Y5EJC+FLwIpWCpDXjhzAHZoRt6X2w84svIrIMssP+kEyk60PKfQX3CDXO5/YBlZM1zcrviiFlIejvH1EOCOg67MZwD/1W5/I5wNLq1X5C2pwgCKmAwlg5RLI9wfwUCIVJpBbDtx9x8PnPPYTe9GQkwcj6lndeKvRgqkr+SDNz3jcC99U5RcXjFVOe5bVcm+10+JY3SZfeThpJSWf/WaJV9c+VClj3dRJ+FZ5hKBmtWM35oA209h128aFT4NXOJXtLIr8GBvFCq9I1zr/n6UwN99+42Y18Jm/qR+Qq/5vbUPjHYt0GN2xb8fNtdmvsYMj0Wjz9TPbhEmNSEwbW3F3VXEu8bTUEU/aNJcnSeY8zawAwH12a6plEtOVKv3lgbPTwXHiGVVluRthc7TL3Qq5m11XUBvrmIPMkR5kvBPvgCaK7vvPW5d9nfwdLKfqT9Gg2tN6EwVFSCNvfhJjF6GSOmMwYuyHiWLmlpCupkiQeVHLDV7Q9ieVIs/fMh0oGAxeV57KsCbdlh7Zv4x5ra0R76EibGunZFa2Q6vsi5TVUSqvmaETu/UlzDHQOLhZsP/ID1W08MnnSVjvbHK5oWRI="
 
     // let data={
     //   'merchant_name': 'testing_add_merchant',
     //   // 'business_website': 'abc.com',
     //   'business_scope': 'abc',
     //   'company_legal_name': 'abc',
     //   'company_registration_no': '#1234',
     //   'company_address': '12345',
     //   'email_id': 'sasikumar@evvoiot.com',
     //   'payment_mode': 'debit',
     //   'processing_currency': 'SGD',
     //   'settlement_currency': 'SGD',
     //   'telephone_no': '7702083557',
     //   'password': 'Sasi@123' 
     // }
    // console.log("EVVOPAY_PRIVATE_KEY>>",EVVOPAY_PRIVATE_KEY)
 
   //  let data={
   //   merchant_name: 'Malik',
   //   business_website: 'abc.com',
   //   business_scope: 'vcbcbc',
   //   company_legal_name: 'ed',
   //   company_address: 'ddd',
   //   email_id: 'danieldsouza627@gmail.com',
   //   payment_mode: 'debit',
   //   processing_currency: 'debit',
   //   settlement_currency: 'debit',
   //   telephone_no: '342',
   //   password: 'Daniel@123' 
   //  }
 
    //   let signature=generateSignature(registerFormData)
     
    //  console.log("signature>>",signature)
    //  console.log("registerFormData>>",registerFormData)
    //  let response=await apiRequest('post','add-merchant',signature,registerFormData)
    
    //  if(response.status==200 || response.status==201){
    //    setErrorStatus(false)
    //    setErrorMsg('')
    //    setFieldErrorMsg([])
    //     setTimeout(() => history.push(`${process.env.PUBLIC_URL}/auth-success`), 2000);
    //  }
    //  else if(response.status==500){
    //    setLoading(false);
    //    setErrorStatus(true)
    //    setErrorMsg("Something went wrong,Kindly try again later")
    //  }
    //  else if (response.status==400){
    //    setLoading(false);
    //    setErrorStatus(false)
    //    setErrorStatus(true)
    //    console.log("response.data.data.data>>>",response.data.data)
    //    if(Array.isArray(response.data.data)){
    //      let errorMsgList=[]
    //      response.data.data.map((data)=>{
    //        errorMsgList.push(data.message)
    //      })
    //      setFieldErrorMsg(errorMsgList)
    //      console.log("fieldErrorMsg>>>",errorMsgList)
    //    }
    //    else{
    //      setErrorMsg(response.data.data.data)
    //    }
    //  }
    //  else if (response.status==401){
    //    setLoading(false);
    //    setErrorStatus(true)
    //    setErrorMsg(response.data.message)
    //  }
    // else{
    //  setLoading(false);
    //  setErrorStatus(true)
    //  setErrorMsg('Something went wrong,Kindly try again later')
    // }
   
 
     
 
     // setTimeout(() => history.push(`${process.env.PUBLIC_URL}/auth-success`), 2000);
   };


   const readFile=(file,type)=>{

    const fileReader = new FileReader();
    fileReader.readAsText(file.target.files[0], "UTF-8");
       

    fileReader.onload = e => {
     // setFiles(e.target.result);

      //console.log("fileReader>>>",type)

      if(type=='pr_k'){
       // updateFormData.private_key=e.target.result

        if(file.target.files[0].type!='text/plain'){
          setError("private_key",{
            type:"manual",
            message:"Only text file allowed"
          })
          updateFormData.private_key=''
         setFileErrorStatus2(true)
        
        }
        else{
          updateFormData.private_key=e.target.result
          setFileErrorStatus2(false)
         
        }

        setUpdateFormData(updateFormData)
      }
      
      else{

        if(file.target.files[0].type!='text/plain'){
          setError("public_key",{
            type:"manual",
            message:"Only text file allowed"
          })
          updateFormData.public_key=''
         setFileErrorStatus(true)
        }
        else{
          updateFormData.public_key=e.target.result
          setFileErrorStatus(false)
        
        }
        setUpdateFormData(updateFormData)
        
      }
       
      

      //console.log("loading>>",loading)
     // setUpdateFormData(updateFormData)
    };
   
   }

   const setKeyField=(type)=>{

    // setUploadKeyStatus(true)
    
    if(type=='pr_k'){
      setUploadKeyStatus(true)
      updateFormData.private_key=''
      setUpdateFormData(updateFormData)
    }
     
    else{
      setUploadKeyStatus2(true)
      updateFormData.public_key=''
      setUpdateFormData(updateFormData)
    }
      
   }

   const setPaymentProcessorOption=(e)=>{

    let index = e.nativeEvent.target.selectedIndex;
    let label = e.nativeEvent.target[index].text;
    setPaymentProcessor(label)
    setUpdateFormData({...updateFormData,payment_processor_id:e.target.value})
        // onChange={(e) => {
                            //  setPaymentProcessor(e.label)
                            //  console.log("e>>>",e)
                            // // setPaymentProcessorStatus(false)
                            // setUpdateFormData((prevFormData) => ({
                            // ...prevFormData,
                            // payment_processor_id: e.target.value,
                            // }));
                            // }}
   }

   const handlePaymentProcessorSelect=(e)=>{
    let index = e.nativeEvent.target.selectedIndex;
    let label = e.nativeEvent.target[index].text;
   // console.log("data>>>",e.target.value)
   // console.log("data>>>",label)
    setPaymentProcessor(label);
   

  //  setPaymentProcStatusValue({'value': null, 'label': null})
  setPaymentProcStatusValue(e.target.value)
    setUpdateFormData((prevFormData) => ({
      ...prevFormData,
      payment_processor_id: e.target.value,
    }));
   }
  return (


    <React.Fragment>
      <Head title="Register" />
    
     
        <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">

          {
            !isLoading ?(
              <div className="brand-logo pb-4 text-center" style ={{marginTop:'60px'}}>
              <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
                <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
                <img className="logo-dark logo-img logo-img-lg"  src={Logo} alt="logo-dark" />
              </Link>
            </div>
            )
            :
            null
          }
         {/* <div className="brand-logo pb-4 text-center" style ={{marginTop:'60px'}}>
             <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
             <img className="logo-dark logo-img logo-img-lg"  src={Logo} alt="logo-dark" />
             </Link>
           </div> */}
          {
               isLoading ?(
                <Loader />
              )
              :
              <PreviewCard  className="card-bordered" bodyClass="card-inner-lg">
              <BlockHead>
                <BlockContent>
                  <BlockTitle tag="h4">Update</BlockTitle>
                  {/* <BlockDes>
                    <p>Create New  Merchant Account</p>
                  
                  </BlockDes> */}
                  {
                       //  console.log("updateFormData>>>",updateFormData)
                  }
                </BlockContent>
              </BlockHead>
              <Form className="row gy-4" onSubmit={handleSubmit(handleFormSubmit)}>
              {
                      userData != null && userData.user_role === 'SUPERADMIN' ? (
                        <>
                          {
                          // paymentProcStatusValue != null ? (
                            <Col md="6">
                              <div className="form-group">
                                <label className="form-label">Payment Processor</label>
                                <div className="form-control-wrap">
                                  {/* <RSelect
                                    options={paymentProcessorData}
                                    defaultValue={{
                                      value: String(paymentProcStatusValue.value).toUpperCase(),
                                      label: String(paymentProcStatusValue.label).toUpperCase()
                                    }}
                                    name="payment_processor"
                                    onChange={(e) => {
                                      setPaymentProcessor(e.label);
                                      setUpdateFormData((prevFormData) => ({
                                        ...prevFormData,
                                        payment_processor_id: e.value,
                                      }));
                                    }}
                                    isDisabled={userData.user_role !== 'SUPERADMIN'} // Disable for non-SUPERADMIN users
                                  /> */}
                                  
                                  <select
                                    disabled={userData.user_role !== 'SUPERADMIN'}    
                                    className="form-control form-select"
                                    name="payment_processor"
                                    onChange={e => handlePaymentProcessorSelect(e)}
                                    // onChange={(e) => {
                                    //   setPaymentProcessor(e.label);
                                    //   setUpdateFormData((prevFormData) => ({
                                    //     ...prevFormData,
                                    //     payment_processor_id: e.value,
                                    //   }));
                                    // }}
                                    // value={paymentProcStatusValue.value}
                                    value={paymentProcStatusValue}
                                    ref={register({ required: "This field is required" })}
                                    style={{backgroundPosition: 'right 10px center',backgroundSize:'8px 9px'}}
                                  >
                                    <option value="">SELECT</option>
                                    {paymentProcessorData.map((data, key) => (
                                      <option key={key} value={data.value}>
                                        {data.label}
                                      </option>
                                    ))}
                                  </select>
                                  {errors.payment_processor && <span className="invalid">{errors.payment_processor.message}</span>}
  
  
                                </div>
  
                              </div>
                            </Col>
                            
                          // ) : null
                          }
  
                          {statusValue != null ? (
                            <Col md="6">
                              <div className="form-group">
                                <label className="form-label">Status</label>
                                <div className="form-control-wrap">
                                  <RSelect
                                    options={userStatus}
                                    defaultValue={{
                                      value: statusValue.value,
                                      label: statusValue.label,
                                    }}
                                    name="is_active"
                                    onChange={(e) =>
                                      setUpdateFormData((currValue) => ({
                                        ...currValue,
                                        is_active: e.value,
                                      }))
                                    }
                                    isDisabled={userData.user_role !== 'SUPERADMIN'} // Disable for non-SUPERADMIN users
                                  />
                                </div>
                              </div>
                            </Col>
                          ) : null}
                        </>
                      ) : null
                    }
  
              {/* {
                      userData!=null && userData.user_role=='SUPERADMIN'?
                      <>
                      {
                        paymentProcStatusValue!=null?
                        <Col md="6">
                        <div className="form-group">
                          <label className="form-label">Payment Processor</label>
                          <div className="form-control-wrap">
  
                          {/* <RSelect
                            options={paymentProcessorData}
                            defaultValue={{
                              value: String(paymentProcStatusValue.value).toUpperCase(),
                              label: String(paymentProcStatusValue.label).toUpperCase()
                            }}
                           
                            name="payment_processor"
                            // onChange={(e)=>setUpdateFormData(currValue=>({
                            //   ...currValue,
                            //   payment_processor_id:e.value
                            // }))}
                            onChange={(e) => {
                              setPaymentProcessor(e.label)
                              setPaymentProcessorStatus(false)
                              setUpdateFormData((prevFormData) => ({
                                ...prevFormData,
                                payment_processor_id: e.value,
                              }));
                            }}
                          
                            // onChange={(e) => setFormData({ ...formData, status: e.value })}
                              />
  
                            </div>
                          </div>
                        </Col>
                        :
                        null
                        }  
  
                        {
                            statusValue!=null?
                            
                            <Col md="6">
                            <div className="form-group">
                              <label className="form-label">Status</label>
                              <div className="form-control-wrap">
                                {
                                // console.log("statusValueNow>>>",statusValue)
                                }
                                <RSelect
                                  options={userStatus}
                                  defaultValue={{
                                    value: statusValue.value,
                                    label: statusValue.label,
                                  }}
                                  name="is_active"
                                  onChange={(e)=>setUpdateFormData(currValue=>({
                                    ...currValue,
                                    is_active:e.value
                                  }))}
                                
                                  // onChange={(e) => setFormData({ ...formData, status: e.value })}
                                />
  
                              </div>
                            </div>
                          </Col>
                          :
                          null
                          }
  
  
  
                    
                      
                          </>
                        
  
                          :
                          null
              }  */}
  
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Merchant Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="merchant_name"
                          defaultValue={updateFormData.merchant_name}
                          placeholder="Enter name"
                          // ref={register({ required: "This field is required" })}
                          onChange={(e)=>setUpdateFormData(currValue=>({
                            ...currValue,
                            merchant_name:e.target.value
                          }))}
                        />
                     
                        {/* {errors.merchant_name && <span className="invalid">{errors.merchant_name.message}</span>} */}
                      </div>
                    </Col>
                    {/* {
                      userData!=null && userData.user_role=='SUPERADMIN'? */}
                      <Col md="6">
                      <div className="form-group">
                        <label className="form-label">MCC Code</label>
                        <input
                            className="form-control"
                            type="text"
                            name="mcc_code"
                            defaultValue={updateFormData.mcc_code}
                            onChange={(e) =>
                              setUpdateFormData((currValue) => ({
                                ...currValue,
                                mcc_code: e.target.value,
                              }))
                            }
                            placeholder="Enter MCC Code"
                         //   placeholder="Enter MCC Code"
                               ref={register({ required: "This field is required" })}
                            disabled={userData.user_role !== 'SUPERADMIN'} // Disable for non-SUPERADMIN users
                          />
                         {errors.mcc_code && <span className="invalid">{errors.mcc_code.message}</span>}
  
  
                        {/* <input
                          className="form-control"
                          type="text"
                          name="mcc_code"
                         defaultValue={updateFormData.mcc_code}
                         onChange={(e)=>setUpdateFormData(currValue=>({
                          ...currValue,
                          mcc_code:e.target.value
                        }))}
                          placeholder="Enter MCC Code"
                      //    ref={register({ required: "This field is required" })}
                        /> */}
                        {/* {errors.mcc_code && <span className="invalid">{errors.mcc_code.message}</span>} */}
                      </div>
                    </Col>

                   
                      {/* :
                      null
                    }       */}
                
                {
                      userData!=null && userData.user_role=='SUPERADMIN' &&   paymentProcessor === "OXPAY" ?
                      <>
                    
                    <Col md="6">
  
                        <>
                        {/* {console.log(paymentProcessor, '-<, paymentProcessor')} */}
                        {/* {paymentProcessor === "OXPAY" && ( */}
                      <div className="form-group">
                        <label className="form-label">Merchant Userid</label>
  
                        <input
                          className="form-control"
                          type="text"
                          name="merchant_userid"
                         defaultValue={updateFormData.merchant_userid}
                         onChange={(e)=>setUpdateFormData(currValue=>({
                          ...currValue,
                          merchant_userid:e.target.value
                        }))}
                          placeholder="Enter merchant userid"
                          ref={register({ required: "This field is required" })}
                        />
                        {errors.merchant_userid && <span className="invalid">{errors.merchant_userid.message}</span>}
                      </div> 
                      {/* )} */}
                      </>
                    </Col>
  
                    
                      <Col md="6">
                      {paymentProcessor === "OXPAY" && (
                      <div className="form-group">
                        <label className="form-label">Merchant Password</label>
                      
                        <a
                          href="#password"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setPassState(!passState);
                          }}
                          style={{marginTop:'26px'}}
                          className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                        >
                          <Icon name="eye" className="passcode-icon icon-show"></Icon>
  
                          <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                        </a>
                        <input
                          className={`form-control ${passState?  "is-hidden" : "is-shown"}`}
                          type={passState ? "text" : "password"}
                          name="merchant_password"
                        defaultValue={updateFormData.merchant_password}
                        onChange={(e)=>setUpdateFormData(currValue=>({
                          ...currValue,
                          merchant_password: e.target.value
                        }))}
                          placeholder="Enter merchant password"
                          ref={register({ required: "This field is required" })}
                        />
                        {errors.merchant_password && <span className="invalid">{errors.merchant_password.message}</span>}
                      </div> )}
                      </Col>
                      </>
                    
  
                      :
                      null
                    }
  
                    
                    {
                        userData!=null && userData.user_role=='SUPERADMIN' && paymentProcessor === "UQPAY" ?
  
                        <>
                            <Col md="6">
  
                           
                            {
  
  
                            // paymentProcessor === "UQPAY"  && updateFormData.public_key=='' ||  loading ? 
                            paymentProcessor === "UQPAY"  && (updateFormData.public_key=='' || updateFormData.public_key==null) ?
                            <div className="form-group">
                                <label className="form-label">Public Key</label>
                                {/* {paymentProcessor}{updateFormData.public_key}{!loading} */}
                                <input
                                className="form-control"
                                type="file"
                                name="public_key"
                            //    defaultValue={updateFormData.private_key}
                              //  onChange={(e)=>setUpdateFormData(currValue=>({
                              //   ...currValue,
                              //   private_key:e.target.value
                              // }))}
                                onChange={(event)=>readFile(event,'pub_k')}
                                placeholder="Enter Public Key" 
                              //   ref={register({ required: true })}
                                  ref={register({ required: "This field is required" })}
                              />
                              
                              {errors.public_key   && <span className="invalid">{errors.public_key.message}</span>}
  
                            </div>
                            :
  
                            paymentProcessor=="UQPAY" && !loading &&(
                              <>
                              <div  style={{color:'green'}}><span  >The public key is updated .Do you want to upload again then click yes</span></div>
                              <Button type="button"  onClick={()=>setKeyField('pub_k')} style={errorStatus?{marginTop:'10px'}:{}} color="primary" size="md"
                              >
                              Yes
                              </Button>
  
                              </>
                            )
  
  
                            }
                            
                            </Col>
  
                            <Col md="6">
  
                                            
                            {
  
                            // paymentProcessor === "UQPAY" &&  updateFormData.private_key=='' ||  loading ?
                            paymentProcessor === "UQPAY"  && (updateFormData.private_key=='' || updateFormData.private_key==null) ?
  
                            <div className="form-group">
                              <label className="form-label">Private Key</label>
                              <input
                              className="form-control"
                              type="file"
                              name="private_key"
                            //    defaultValue={updateFormData.private_key}
                            //  onChange={(e)=>setUpdateFormData(currValue=>({
                            //   ...currValue,
                            //   private_key:e.target.value
                            // }))}
                            ref={register({ required: "This field is required" })}
                              onChange={(event)=>readFile(event,'pr_k')}
                              placeholder="Enter Private Key" 
                              // ref={register({ required: "This field is required" })}
                            />
                            {/* {errors.merchant_userid && <span className="invalid">{errors.merchant_userid.message}</span>} */}
                            {errors.private_key   && <span className="invalid">{errors.private_key.message}</span>}
  
                            </div>
                            :
  
                            paymentProcessor=="UQPAY" && !loading &&(
                            <>
                            <div  style={{color:'green'}}><span  >The private key is updated .Do you want  to upload again then click yes</span></div>
                            <Button type="button"  onClick={()=>setKeyField('pr_k')} style={errorStatus?{marginTop:'10px'}:{}} color="primary" size="md"
                            >
                            Yes
                            </Button>
  
                            </>
                            )
  
  
                            }
  
                            </Col>
                        </>
                        :
                        null
  
                    }
                 
         
                    {
                      updateFormData.gateway_fee!='' &&(
                        <Col md="6">
                        <div className="form-group">
                          <label className="form-label">Gateway fee</label>
                          <input
  
                              disabled
                              className="form-control"
                              type="text"
                              name="gateway_fee"
                              defaultValue={updateFormData.gateway_fee}
                              placeholder="Gateway fee"
                         
                            />
                          
                        </div>
                      </Col>
                      )
                    }
                    {
                      updateFormData.mdr_percent!='' &&(
                        <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Mdr percentage</label>
                        <input

                            disabled
                            className="form-control"
                            type="text"
                            name="mdr_percent"
                            defaultValue={updateFormData.mdr_percent}
                            placeholder="Enter Mdr perecntage"
                       
                          />
                        
                      </div>
                    </Col>
                      )
                    }
                    

                    {
                      updateFormData.settlement_day!='' &&(
                        <Col md="6">
                        <div className="form-group">
                          <label className="form-label">Settlement day</label>
                          <input
  
                              disabled
                              className="form-control"
                              type="text"
                              name="settlement_day"
                              defaultValue={updateFormData.settlement_day}
                              placeholder="Enter Settlement day"
                         
                            />
                          
                        </div>
                      </Col>
                      )
                    }
                 
                   
  
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Business Scope</label>
                        <input
                          className="form-control"
                          type="text"
                          name="business_scope"
                         defaultValue={updateFormData.business_scope}
                         onChange={(e)=>setUpdateFormData(currValue=>({
                          ...currValue,
                          business_scope:e.target.value
                        }))}
                          placeholder="Enter business scope"
                          // ref={register({ required: "This field is required" })}
                        />
                        {/* {errors.business_scope && <span className="invalid">{errors.business_scope.message}</span>} */}
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Company Legal Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="company_legal_name"
                         defaultValue={updateFormData.company_legal_name}
                         onChange={(e)=>setUpdateFormData(currValue=>({
                          ...currValue,
                          company_legal_name:e.target.value
                        }))}
                          placeholder="Enter company legal name"
                          // ref={register({ required: "This field is required" })}
                        />
                        {/* {errors.company_legal_name && <span className="invalid">{errors.company_legal_name.message}</span>} */}
                      
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Company Registration NO</label>
                        <input
                          className="form-control"
                          type="text"
                          name="company_registration_no"
                         defaultValue={updateFormData.company_registration_no}
                         onChange={(e)=>setUpdateFormData(currValue=>({
                          ...currValue,
                          company_registration_no:e.target.value
                        }))}
                          placeholder="Enter company registration no"
                        // ref={register({ required: "This field is required" })}
                        />
                        {/* {errors.company_legal_name && <span className="invalid">{errors.company_legal_name.message}</span>} */}
                      
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Company Address</label>
                        <input
                          className="form-control"
                          type="text"
                          name="company_address"
                         defaultValue={updateFormData.company_address}
                         onChange={(e)=>setUpdateFormData(currValue=>({
                          ...currValue,
                          company_address:e.target.value
                        }))}
                          placeholder="Enter company address"
                       // ref={register({ required: "This field is required" })}
                       />
                       {/* {errors.company_legal_name && <span className="invalid">{errors.company_legal_name.message}</span>} */}
                     
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Callbackurl</label>
                        <input
                          className="form-control"
                          type="text"
                          name="callbackurl"
                         defaultValue={updateFormData.callback_url}
                         onChange={(e)=>setUpdateFormData(currValue=>({
                          ...currValue,
                          callback_url:e.target.value
                        }))}
                          placeholder="Enter callbackurl"
                       // ref={register({ required: "This field is required" })}
                       />
                       {/* {errors.company_legal_name && <span className="invalid">{errors.company_legal_name.message}</span>} */}
                     
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Business Email</label>
                        <input
                          className="form-control"
                          type="text"
                          name="email"
                          defaultValue={updateFormData.business_email}
                          onChange={(e)=>setUpdateFormData(currValue=>({
                           ...currValue,
                           business_email:e.target.value.toLowerCase()
                         }))}
                      //    defaultValue={formData.email}
                          placeholder="Enter email"
                          // ref={register({
                          //   required: "This field is required",
                          //   pattern: {
                          //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          //     message: "invalid email address",
                          //   },
                          // })}
                        />
                        {/* {errors.email && <span className="invalid">{errors.email.message}</span>} */}
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Payment Mode</label>
                        <input
                          className="form-control"
                          type="text"
                          name="payment_mode"
                         defaultValue={updateFormData.payment_mode}
                         onChange={(e)=>setUpdateFormData(currValue=>({
                          ...currValue,
                          payment_mode:e.target.value
                        }))}
                          placeholder="Enter payment mode"
                          // ref={register({ required: "This field is required" })}
                        />
                        {/* {errors.callbackurl && <span className="invalid">{errors.callbackurl.message}</span>} */}
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Payment Currency</label>
                        <input
                          className="form-control"
                          type="text"
                          name="processing_currency"
                         defaultValue={updateFormData.processing_currency}
                         onChange={(e)=>setUpdateFormData(currValue=>({
                          ...currValue,
                          processing_currency:e.target.value
                        }))}
                          placeholder="Enter payment currency"
                          // ref={register({ required: "This field is required" })}
                        />
                        {/* {errors.callbackurl && <span className="invalid">{errors.callbackurl.message}</span>} */}
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Settlement Currency</label>
                        <input
                          className="form-control"
                          type="text"
                          name="settlement_currency"
                         defaultValue={updateFormData.processing_currency}
                         onChange={(e)=>setUpdateFormData(currValue=>({
                          ...currValue,
                          settlement_currency:e.target.value
                        }))}
                          placeholder="Enter settlement  currency"
                          // ref={register({ required: "This field is required" })}
                        />
                        {/* {errors.callbackurl && <span className="invalid">{errors.callbackurl.message}</span>} */}
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Telephone NO</label>
                        <input
                          className="form-control"
                          type="text"
                          name="telephone_no"
                         defaultValue={updateFormData.telephone_no}
                         onChange={(e)=>setUpdateFormData(currValue=>({
                          ...currValue,
                          telephone_no:e.target.value
                        }))}
                          placeholder="Enter telephone no"
                          // ref={register({ required: "This field is required" })}
                        />
                        {/* {errors.callbackurl && <span className="invalid">{errors.callbackurl.message}</span>} */}
                      </div>
                    </Col>
                        {
                         // console.log("updateFormData>>>",updateFormData)
                        }
                    {
                      userData.user_role=='SUPERADMIN' ?(
                        <Col md="6">
                        <div className="form-group">
                          <label className="form-label">Connection String</label>
                          <input
                            className="form-control"
                            type="text"
                            name="connection_string"
                           defaultValue={updateFormData.connection_string}
                           onChange={(e)=>setUpdateFormData(currValue=>({
                            ...currValue,
                            connection_string:e.target.value
                          }))}
                            placeholder="Enter connection string"
                            // ref={register({ required: "This field is required" })}
                          />
                          {/* {errors.callbackurl && <span className="invalid">{errors.callbackurl.message}</span>} */}
                        </div>
                      </Col>
                      )
                   
                    :
                    null
                    }
                
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Director Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="director_name"
                         defaultValue={updateFormData.director_name}
                         onChange={(e)=>setUpdateFormData(currValue=>({
                          ...currValue,
                          director_name:e.target.value
                        }))}
                          placeholder="Enter director name"
                          // ref={register({ required: "This field is required" })}
                        />
                        {/* {errors.callbackurl && <span className="invalid">{errors.callbackurl.message}</span>} */}
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">ID NO</label>
                        <input
                          className="form-control"
                          type="text"
                          name="id_no"
                         defaultValue={updateFormData.id_no}
                         onChange={(e)=>setUpdateFormData(currValue=>({
                          ...currValue,
                          id_no:e.target.value
                        }))}
                          placeholder="Enter ID NO"
                          // ref={register({ required: "This field is required" })}
                        />
                        {/* {errors.callbackurl && <span className="invalid">{errors.callbackurl.message}</span>} */}
                      </div>
                    </Col>
  
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Identity Type</label>
                        <input
                          className="form-control"
                          type="text"
                          name="identity_type"
                         defaultValue={updateFormData.identity_type}
                         onChange={(e)=>setUpdateFormData(currValue=>({
                          ...currValue,
                          identity_type:e.target.value
                        }))}
                          placeholder="Enter identity type"
                          // ref={register({ required: "This field is required" })}
                        />
                        {/* {errors.callbackurl && <span className="invalid">{errors.callbackurl.message}</span>} */}
                      </div>
                    </Col>
  
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Nationality</label>
                        <input
                          className="form-control"
                          type="text"
                          name="nationality"
                         defaultValue={updateFormData.nationality}
                         onChange={(e)=>setUpdateFormData(currValue=>({
                          ...currValue,
                          nationality:e.target.value
                        }))}
                          placeholder="Enter nationality"
                          // ref={register({ required: "This field is required" })}
                        />
                        {/* {errors.callbackurl && <span className="invalid">{errors.callbackurl.message}</span>} */}
                      </div>
                    </Col>
  
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Residential Address</label>
                        <input
                          className="form-control"
                          type="text"
                          name="residential_address"
                         defaultValue={updateFormData.residential_address}
                         onChange={(e)=>setUpdateFormData(currValue=>({
                          ...currValue,
                          residential_address:e.target.value
                        }))}
                          placeholder="Enter residential addresss"
                          // ref={register({ required: "This field is required" })}
                        />
                        {/* {errors.callbackurl && <span className="invalid">{errors.callbackurl.message}</span>} */}
                      </div>
                    </Col>
  
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Director DOB</label>
                        <input
                          className="form-control"
                          type="text"
                          name="director_dob"
                         defaultValue={updateFormData.director_dob}
                         onChange={(e)=>setUpdateFormData(currValue=>({
                          ...currValue,
                          director_dob:e.target.value
                        }))}
                          placeholder="Enter director DOB"
                          // ref={register({ required: "This field is required" })}
                        />
                        {/* {errors.callbackurl && <span className="invalid">{errors.callbackurl.message}</span>} */}
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Country Code</label>
                        <input
                          className="form-control"
                          type="text"
                          name="country_code"
                         defaultValue={updateFormData.country_code}
                         onChange={(e)=>setUpdateFormData(currValue=>({
                          ...currValue,
                          country_code:e.target.value
                        }))}
                          placeholder="Enter country code "
                          // ref={register({ required: "This field is required" })}
                        />
                        {/* {errors.callbackurl && <span className="invalid">{errors.callbackurl.message}</span>} */}
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Country Name</label>
                        <input
                          className="form-control"
                          type="text"
                          name="country_name"
                         defaultValue={updateFormData.country_name}
                         onChange={(e)=>setUpdateFormData(currValue=>({
                          ...currValue,
                          country_name:e.target.value
                        }))}
                          placeholder="Enter country name"
                          // ref={register({ required: "This field is required" })}
                        />
                        {/* {errors.callbackurl && <span className="invalid">{errors.callbackurl.message}</span>} */}
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Currency</label>
                        <input
                          className="form-control"
                          type="text"
                          name="currency"
                         defaultValue={updateFormData.currency}
                         onChange={(e)=>setUpdateFormData(currValue=>({
                          ...currValue,
                          currency:e.target.value
                        }))}
                          placeholder="Enter currency "
                          // ref={register({ required: "This field is required" })}
                        />
                        {/* {errors.callbackurl && <span className="invalid">{errors.callbackurl.message}</span>} */}
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Symbol</label>
                        <input
                          className="form-control"
                          type="text"
                          name="symbol"
                         defaultValue={updateFormData.symbol}
                         onChange={(e)=>setUpdateFormData(currValue=>({
                          ...currValue,
                          symbol:e.target.value
                        }))}
                          placeholder="Enter symbol"
                          // ref={register({ required: "This field is required" })}
                        />
                        {/* {errors.callbackurl && <span className="invalid">{errors.callbackurl.message}</span>} */}
                      </div>
                    </Col>
                    {
                   //   console.log("pdateFormData.postal_code????",updateFormData.postal_code)
                    }
                    
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Postal Code</label>
                        <input
                          className="form-control"
                          type="text"
                          name="postal_code"
                         defaultValue={updateFormData.postal_code}
                         onChange={(e)=>setUpdateFormData(currValue=>({
                          ...currValue,
                          postal_code:e.target.value
                        }))}
                          placeholder="Enter postal_code "
                          // ref={register({ required: "This field is required" })}
                        />
                        {/* {errors.callbackurl && <span className="invalid">{errors.callbackurl.message}</span>} */}
                      </div>
                    </Col>
                    
                    
  
                   
  
                    {/* <Col md="6">
                      <div className="form-group">
                        <label className="form-label">Phone</label>
                        <input
                          className="form-control"
                          type="number"
                          name="phone"
                         // defaultValue={Number(formData.phone)}
                          ref={register({ required: "This field is required" })}
                        />
                        {errors.phone && <span className="invalid">{errors.phone.message}</span>}
                      </div>
                    </Col> */}
                    {/* {console.log("paymentProcStatusValue>>>",paymentProcStatusValue)} */}
                    
  
  
                    {
                      errorStatus==true?
                      errorMsg!=''?<span style={{color:'red'}} className="invalid">{`* `+errorMsg}</span>
                      :null
                      :null
                    }
  
                  {
                  fieldErrorMsg.length>0?
                  fieldErrorMsg.map((msg,key)=>{
                    return <div  key ={key} style={{color:'red'}}><span  className="invalid">{`* `+msg}</span></div>
                  })
                  :null
                }
                {successMsg && 
               <div  style={{color:'green'}}><span  >{successMsg}</span></div>
               }
  
         

                    {
                       userData !=null && (userData.user_role==SUPERADMIN || userData.user_role==OWNER) &&(
                        <Col size="12">
                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                          <li>
                            <Button  style={errorStatus?{marginTop:'10px'}:{}} color="primary" size="md" type="submit"
                            >
                                 { loading ? <Spinner size="sm" color="light" /> : "Update Merchant"}
                            </Button>
                          </li>
                     
                        </ul>
                      </Col>
                       )
                    }
                    {/* <Col size="12">
                      <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                          <Button  style={errorStatus?{marginTop:'10px'}:{}} color="primary" size="md" type="submit"
                          >
                               { loading ? <Spinner size="sm" color="light" /> : "Update Merchant"}
                          </Button>
                        </li>
                   
                      </ul>
                    </Col> */}
                  </Form>
         
            </PreviewCard>
          }
         
        </Block>
        {/* <AuthFooter /> */}
      </PageContainer>
      
    
    </React.Fragment>
  );
};
export default MerchantProfile;
