import React, { useEffect, useRef, useState } from "react";
import Logo from "../../images/evvopay_logo.png";
import LogoDark from "../../images/logo-dark.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import axios from "axios";
import qs from "qs"
import { decryptToken, generateSignature } from "../../helpers/signature";
import { ALGORITHM_TYPE, EVVOPAY_API, EVVOPAY_PRIVATE_KEY, MERCHANT_MODULE, SUPERADMIN } from "../../constants";
import { apiRequest } from "../../helpers/api";
import { getAccessStatus } from "../../helpers/common";

const Register = ({ history }) => {
  
  const userDetails = JSON.parse(decryptToken(localStorage.getItem('accessToken')))
//  console.log("userDetails>>>",userDetails)
  const registerForm={
    merchant_name:'',
    // business_website:'',
    // business_scope:'',
    // company_legal_name:'',
    // company_registration_no:'',
    // company_address:'',
    email_id:'',
    // payment_mode:'',
    // processing_currency:'',
    // settlement_currency:'',
    telephone_no:'',
    password:''
  }
  const [registerFormData,setRegisterFormData]=useState(registerForm)
  const [passState, setPassState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [fieldErrorMsg, setFieldErrorMsg] = useState([]);
  const { watch,errors, register, handleSubmit } = useForm();
  const [merchant, setMerchant] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confimPasswordErrorMessage, setConformPasswordErrorMessage] = useState('');
  const [phoneNoErrorMessage, setPhoneNoErrorMessage] = useState('');
  const [userData, setUserData] = useState(userDetails || null);
  const [userType, setUserType]=useState("");

   const password_val =useRef()
   password_val.current=watch('password_val')

const handleMerchantChange = (event) => {
    setMerchant(event.target.value);
    setRegisterFormData(currValue => ({
      ...currValue,
      merchant_name: event.target.value
    }))
  };

  const handlePhoneNoChange = (event) => {
    setPhoneNo(event.target.value);
    setRegisterFormData(currValue => ({
      ...currValue,
      telephone_no: event.target.value
    }))
  };

const handlePasswordChange = (event) => {
  setPassword(event.target.value);
  setRegisterFormData(currValue => ({
    ...currValue,
    password: event.target.value
  }))
  };

const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

const validateMerchant = (event) => {
    const letterRegex = /^[A-Za-z ]+$/i;

    if (!merchant) {
      setErrorMessage('Merchant Name is required');
    }else if (merchant.length < 3) {
      setErrorMessage('Merchant Name should be more than 3 characters');
    }else if (merchant.length > 500) {
      setErrorMessage('Only 500 characters are allowed');
    } else if (!letterRegex.test(merchant)) {
      setErrorMessage('Merchant Name should contain only characters');
    } else {
      setErrorMessage('');
    }
  };

  const validatePhoneNo = (event) => {
    const numberRegex = /^[0-9]{0,12}$/;
    if (!phoneNo) {
      setPhoneNoErrorMessage('Phone Number is required');
    } else if (!numberRegex.test(phoneNo)) {
      setPhoneNoErrorMessage('Invalid Phone Number');
    } else {
      setPhoneNoErrorMessage('');
    }
  };
  

const validatePassword = () => {

    if (!password) {
      setPasswordErrorMessage('Password is required');
    }else if (password.length < 6) {
      setPasswordErrorMessage('Password should contain at least 6 characters');
    }else if (!/[A-Z]/.test(password)) {
      setPasswordErrorMessage('Password should contain at least one capital letter');
    } else if (!/[a-z]/.test(password)) {
      setPasswordErrorMessage('Password should contain at least one lowercase letter.');
    }else if (!/\d/.test(password)) {
      setPasswordErrorMessage('Password should contain at least one number.');
    }else if (!/[^A-Za-z0-9]/.test(password)) {
      setPasswordErrorMessage('Password should contain at least one special character.');
    } else {
      setPasswordErrorMessage('');
    }
  };

  const validateConfirmPassword = () => {
     if (password !== confirmPassword) {
      setConformPasswordErrorMessage('Passwords do not match');
      return;
    }else{
    setConformPasswordErrorMessage('');
  }
  };

  useEffect(()=>{

  //  console.log("userData>>>",userData)
   //   let {readStatus,writeStatus,deleteStatus}=getAccessStatus(userData,MERCHANT_MODULE)
      if(userData.user_role!=SUPERADMIN){
        history.push(`${process.env.PUBLIC_URL}/errors/504-classic`);
      }
    if(userData==null){
        setUserType("")
    }
    else {
      setUserType(userData.user_role)
     
    }
    setErrorMsg('')
    setFieldErrorMsg([])
    setErrorStatus(false)
  },[])

  const handleFormSubmit = async () => {
   setLoading(true);
    setErrorMsg('')
    setFieldErrorMsg([])
    setErrorStatus(false)
     
    // registerFormData.
   //let signature="AorFkeqCC4827Q5slcmjx6ZxU15/j3GFhPMTt4xFYi/M7ahNIFHajyH6tQ+MeuTotH+qjwldqB8PVbCav4BbIH8Y5EJC+FLwIpWCpDXjhzAHZoRt6X2w84svIrIMssP+kEyk60PKfQX3CDXO5/YBlZM1zcrviiFlIejvH1EOCOg67MZwD/1W5/I5wNLq1X5C2pwgCKmAwlg5RLI9wfwUCIVJpBbDtx9x8PnPPYTe9GQkwcj6lndeKvRgqkr+SDNz3jcC99U5RcXjFVOe5bVcm+10+JY3SZfeThpJSWf/WaJV9c+VClj3dRJ+FZ5hKBmtWM35oA209h128aFT4NXOJXtLIr8GBvFCq9I1zr/n6UwN99+42Y18Jm/qR+Qq/5vbUPjHYt0GN2xb8fNtdmvsYMj0Wjz9TPbhEmNSEwbW3F3VXEu8bTUEU/aNJcnSeY8zawAwH12a6plEtOVKv3lgbPTwXHiGVVluRthc7TL3Qq5m11XUBvrmIPMkR5kvBPvgCaK7vvPW5d9nfwdLKfqT9Gg2tN6EwVFSCNvfhJjF6GSOmMwYuyHiWLmlpCupkiQeVHLDV7Q9ieVIs/fMh0oGAxeV57KsCbdlh7Zv4x5ra0R76EibGunZFa2Q6vsi5TVUSqvmaETu/UlzDHQOLhZsP/ID1W08MnnSVjvbHK5oWRI="

    // let data={
    //   'merchant_name': 'testing_add_merchant',
    //   // 'business_website': 'abc.com',
    //   'business_scope': 'abc',
    //   'company_legal_name': 'abc',
    //   'company_registration_no': '#1234',
    //   'company_address': '12345',
    //   'email_id': 'sasikumar@evvoiot.com',
    //   'payment_mode': 'debit',
    //   'processing_currency': 'SGD',
    //   'settlement_currency': 'SGD',
    //   'telephone_no': '7702083557',
    //   'password': 'Sasi@123' 
    // }
   // console.log("EVVOPAY_PRIVATE_KEY>>",EVVOPAY_PRIVATE_KEY)

  //  let data={
  //   merchant_name: 'Malik',
  //   business_website: 'abc.com',
  //   business_scope: 'vcbcbc',
  //   company_legal_name: 'ed',
  //   company_address: 'ddd',
  //   email_id: 'danieldsouza627@gmail.com',
  //   payment_mode: 'debit',
  //   processing_currency: 'debit',
  //   settlement_currency: 'debit',
  //   telephone_no: '342',
  //   password: 'Daniel@123' 
  //  }

     let signature=generateSignature(registerFormData)
    
    //console.log("signature>>",signature)
    // console.log("registerFormData>>",registerFormData)
    let response=await apiRequest('post','add-merchant',signature,registerFormData)
    // console.log("registerFormDataresponse>>",response)
    if(response.status==200 || response.status==201){
      setErrorStatus(false)
      setErrorMsg('')
      setFieldErrorMsg([])
       setTimeout(() => history.push(`${process.env.PUBLIC_URL}/auth-success`), 2000);
    }
    else if(response.status==500){
      setLoading(false);
      setErrorStatus(true)
      setErrorMsg("Something went wrong,Kindly try again later")
    }
    else if (response.status==400){
      setLoading(false);
      setErrorStatus(false)
      setErrorStatus(true)
    //  console.log("response.data.data.data>>>",response.data.data)
      if(Array.isArray(response.data.data)){
        let errorMsgList=[]
        response.data.data.map((data)=>{
          errorMsgList.push(data.message)
        })
        setFieldErrorMsg(errorMsgList)
       // console.log("fieldErrorMsg>>>",errorMsgList)
      }
      else{
        setErrorMsg(response.data.data.data)
      }
    }
    else if (response.status==401){
      setLoading(false);
      setErrorStatus(true)
      setErrorMsg(response.data.message)
    }
   else{
    setLoading(false);
    setErrorStatus(true)
    setErrorMsg('Something went wrong,Kindly try again later')
   }

  };

  const isFormValid = !confimPasswordErrorMessage && !passwordErrorMessage

  
  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <React.Fragment>
      <Head title="Register" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs" >
          <div className="brand-logo pb-4 text-center">
            <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={Logo} alt="logo-dark" />
            </Link>
            {/* <h1>Evvopay Admin</h1> */}
            </div>
          {/* <div className="brand-logo pb-4 text-center"> */}
          
          {
            userType=="SUPERADMIN" && (
              <div>
              <button
                  onClick={handleGoBack}
                  style={{
                    padding: '10px 20px',
                    background: '#f0f0f0',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: '#333',
                    cursor: 'pointer',
                    float: 'left', // Align button to the left
                    marginRight: '10px', // Add some spacing between the button and the content
                  }}
                >
                  Back
                </button>
            </div>
            )
            
          }
          {/* <div>
            <button
                onClick={handleGoBack}
                style={{
                  padding: '10px 20px',
                  background: '#f0f0f0',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  color: '#333',
                  cursor: 'pointer',
                  float: 'left', // Align button to the left
                  marginRight: '10px', // Add some spacing between the button and the content
                }}
              >
                Back
              </button>
          </div> */}
          
          {/* <PreviewCard className="card-bordered" bodyClass="card-inner-lg" style={{ */}
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg" style={{
                      backgroundColor: '#f0f0f0',
                      padding: '20px',
                      borderRadius: '40px',
                      height: '800px',
                      width: '600px'
                      // Add more CSS properties as needed
                    }}>
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Register</BlockTitle>
                <BlockDes>
                  <p>Create New Evvopay user Account</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            <form className="is-alter row" onSubmit={handleSubmit(handleFormSubmit)}>
              <div className="form-group ">
                <label className="form-label" htmlFor="name">
                  Merchant Name
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="merchant_name"
                    name="merchant_name"
                    placeholder="Enter your name"
                    tabIndex="1"
                    ref={register({ required: true,
                    maxLength: 500,
                    //message: "Only 500 characters are allowed"
                  })}
                      // ref={register('merchant_name',{required:true,maxLength:5})}
                      // {...register( { required: true, maxLength: 2 })}
                    className="form-control-lg form-control"
                    // onChange={(e)=>setRegisterFormData(currValue=>({
                    //   ...currValue,
                    //   merchant_name:e.target.value
                    // }))}
                    onChange={handleMerchantChange}
                    onBlur={validateMerchant}
                  />
                    {/* {errorMessage && <p>{errorMessage}</p>} */}
                    {/* {console.log(errors)} */}
                  {/* {errors.merchant_name && <p className="invalid">{errors.merchant_name.message}</p>} */}
                  {errors.merchant_name && errors.merchant_name.type==='required' && <p className="invalid">The field is required</p>}
                  {errors.merchant_name && errors.merchant_name.type==='maxLength' && <p className="invalid">Only 500 characters are allowed</p>}

                </div>
              </div>
              
              <div className="form-group ">
                <label className="form-label" htmlFor="name">
                  Phone No
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="phone_no"
                    name="phone_no"
                    placeholder="Enter your no"
                    tabIndex="2"
                    ref={register({ required: 'The field is required',
                    pattern: {
                      value: /^(0|[1-9]\d*)(\.\d+)?$/,
                      message: 'Field value is invalid',
                   }
                    
                  })}
                    className="form-control-lg form-control"
                    // onChange={(e)=>setRegisterFormData(currValue=>({
                    //   ...currValue,
                    //   telephone_no:e.target.value
                    // }))}
                    onChange={handlePhoneNoChange}
                    onBlur={validatePhoneNo}
                  />
                  {errors.phone_no && <p className="invalid">{errors.phone_no.message}</p>}
                  {/* {phoneNoErrorMessage && <p>{phoneNoErrorMessage}</p>} */}
                  </div>
              </div>
              
              <div className="form-group ">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Email
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    bssize="lg"
                    id="default-01"
                    name="email_id"
                    tabIndex="3"
                    ref={register({ required: 'The field is required',
                      pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'Please enter a valid email',
                     }
                      
                    })}
                    className="form-control-lg form-control"
                    placeholder="Enter your email"
                    onChange={(e)=>setRegisterFormData(currValue=>({
                      ...currValue,
                      email_id:e.target.value.toLowerCase()
                    }))}
                  />
                  {errors.email_id && <p className="invalid">{errors.email_id.message}</p>}
                  
                </div>
              </div>
              <div className="form-group ">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                    Password
                  </label>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassState(!passState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                    <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                  </a>
                  <input
                    type={passState ? "text" : "password"}
                    id="password"
                    name="password_val"
                    tabIndex="3"
                    // ref={register({ required: "This field is required" })}
                    // ref={register({ required: 'The field is required',
                    ref={register({ required: true,
                    pattern: {
                      value: /^(?=.*[0-9])(?=.*[!@#$%^&*.,])[a-zA-Z0-9!@#$%^&*.,]{6,16}$/,
                      message: 'Password must contain atleast 6 characters,One Uppercase,One Lowercase,One Number and One Special Character',
                    }
                    // validate:(value)=>
                    // value==password.current
                    
                  })}
                    placeholder="Enter your password"
                    className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                    // onChange={(e)=>setRegisterFormData(currValue=>({
                    //   ...currValue,
                    //   password:e.target.value
                    // }))}
                    onChange={handlePasswordChange}
                    onBlur={validatePassword}
                  />
                  {/* {passwordErrorMessage && <p>{passwordErrorMessage}</p>} */}
                  {errors.password_val && errors.password_val.type==='required' && <p className="invalid">The field is required</p>}
                  {errors.password_val && errors.password_val.type==='pattern' && <p className="invalid">{errors.password_val.message}</p>}

                </div>
                {
                  Object.keys(errors).length==0 && !errors.password_val ?<p >(Password should be 6 digits and alpha numerical)</p> 
                  :null
                }
                
              </div>
              <div className="form-group ">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                    Confirm Password
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder="Enter your password"
                    tabIndex="4"
                    className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                    // onChange={(e)=>setRegisterFormData(currValue=>({
                    //   ...currValue,
                    //   password:e.target.value
                    // }))}
                    // ref={register({ required: true
                    //   // pattern: {
                    //   //   value: /^(?=.*[0-9])(?=.*[!@#$%^&*.,])[a-zA-Z0-9!@#$%^&*.,]{6,16}$/,
                    //   //   message: 'Password must contain atleast 6 characters,One Uppercase,One Lowercase,One Number and One Special Character',
                    //   // }
                    //   // validate:(value)=>
                    //   // value==password.current
                      
                    // })}
                    ref={register({
                      required:true,
                      validate: value =>
                        value === password_val.current || ""
                    })}
                    onChange={handleConfirmPasswordChange}
                    onBlur={validateConfirmPassword}
                  />
                  {/* {errors.confirmPassword && <p>{errors.confirmPassword.message}</p>} */}
                  {errors.confirmPassword && errors.confirmPassword.type==='required' && <p className="invalid">The field is required</p>}

                  {errors.confirmPassword && errors.confirmPassword.type==='validate' && <p className="invalid">The passoword and confirm password should be same</p>}

                  {/* {errors.confirmPassword && errors.confirmPassword.type==='required' && <p className="invalid">The field is required</p>} */}

                  {/* {confimPasswordErrorMessage && <p>{confimPasswordErrorMessage}</p>} */}
                </div>
              </div>
              {
                errorStatus==true?
                 errorMsg!=''?<span style={{color:'red'}} className="invalid">{`* `+errorMsg}</span>
                 :null
                :null
              }
              {
                fieldErrorMsg.length>0?
                fieldErrorMsg.map((msg,key)=>{
                  return <div  key ={key} style={{color:'red'}}><span  className="invalid">{`* `+msg}</span></div>
                })
                :null
              }
              {/* {
                console.log("fieldErrorMsg>>>>",fieldErrorMsg.length)
              } */}
              <div className="form-group">
                <Button  style={errorStatus?{marginTop:'10px'}:{}} type="submit" disabled={!isFormValid} color="primary" size="lg" className="btn-block" tabIndex="5">
                  {loading ? <Spinner size="sm" color="light" /> : "Register"}
                </Button>
              </div>
            </form>

            {
              userType!="SUPERADMIN" && (
                <div className="form-note-s2 text-center pt-4">
                {" "}
                Already have an account?{" "}
                <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
                  <strong>Sign in instead</strong>
                </Link>
              </div>
              )
            }
          
          </PreviewCard>

        </Block>
        <AuthFooter />
      </PageContainer>

     
    </React.Fragment>
  );
};
export default Register;







// import React, { useEffect, useRef, useState } from "react";
// import Logo from "../../images/logo.png";
// import LogoDark from "../../images/logo-dark.png";
// import PageContainer from "../../layout/page-container/PageContainer";
// import Head from "../../layout/head/Head";
// import AuthFooter from "./AuthFooter";
// import {
//   Block,
//   BlockContent,
//   BlockDes,
//   BlockHead,
//   BlockTitle,
//   Button,
//   Icon,
//   PreviewCard,
// } from "../../components/Component";
// import { Spinner } from "reactstrap";
// import { useForm } from "react-hook-form";
// import { Link } from "react-router-dom";
// import axios from "axios";
// import qs from "qs"
// import { generateSignature } from "../../helpers/signature";
// import { ALGORITHM_TYPE, EVVOPAY_API, EVVOPAY_PRIVATE_KEY } from "../../constants";
// import { apiRequest } from "../../helpers/api";

// const Register = ({ history }) => {

//   const registerForm={
//     merchant_name:'',
//     // business_website:'',
//     // business_scope:'',
//     // company_legal_name:'',
//     // company_registration_no:'',
//     // company_address:'',
//     email_id:'',
//     // payment_mode:'',
//     // processing_currency:'',
//     // settlement_currency:'',
//     telephone_no:'',
//     password:''
//   }
//   const [registerFormData,setRegisterFormData]=useState(registerForm)
//   const [passState, setPassState] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [errorStatus, setErrorStatus] = useState(false);
//   const [errorMsg, setErrorMsg] = useState('');
//   const [fieldErrorMsg, setFieldErrorMsg] = useState([]);
//   const { watch,errors, register, handleSubmit } = useForm();
//   const [merchant, setMerchant] = useState('');
//   const [password, setPassword] = useState('');
//   const [errorMessage, setErrorMessage] = useState('');
//   const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');
//   const [confimPasswordErrorMessage, setConformPasswordErrorMessage] = useState('');

//   // const password =useRef()
//   // password.current=watch('password')

// const handleMerchantChange = (event) => {
//     setMerchant(event.target.value);
//     setRegisterFormData(currValue => ({
//       ...currValue,
//       merchant_name: event.target.value
//     }))
//   };

// const handlePasswordChange = (event) => {
//   setPassword(event.target.value);
//   setRegisterFormData(currValue => ({
//     ...currValue,
//     password: event.target.value
//   }))
//   };

// const handleConfirmPasswordChange = (e) => {
//     setConfirmPassword(e.target.value);
//   };

// const validateMerchant = () => {
//     const letterRegex = /^[A-Za-z ]+$/i;

//     if (merchant.length < 3) {
//       setErrorMessage('Merchant Name should be more than 3 characters');
//     }else if (merchant.length > 50) {
//       setErrorMessage('Only 50 characters are allowed');
//     } else if (!letterRegex.test(merchant)) {
//       setErrorMessage('Merchant Name should contain only characters');
//     } else {
//       setErrorMessage('');
//     }
//   };

// const validatePassword = () => {
//     if (password.length < 6) {
//       setPasswordErrorMessage('Password should contain at least 6 characters');
//     }else if (!/[A-Z]/.test(password)) {
//       setPasswordErrorMessage('Password should contain at least one capital letter');
//     } else if (!/[a-z]/.test(password)) {
//       setPasswordErrorMessage('Password should contain at least one lowercase letter.');
//     }else if (!/\d/.test(password)) {
//       setPasswordErrorMessage('Password should contain at least one number.');
//     }else if (!/[^A-Za-z0-9]/.test(password)) {
//       setPasswordErrorMessage('Password should contain at least one special character.');
//     } else {
//       setPasswordErrorMessage('');
//     }
//   };

//   const validateConfirmPassword = () => {
//      if (password !== confirmPassword) {
//       setConformPasswordErrorMessage('Passwords do not match');
//       return;
//     }else{
//     setConformPasswordErrorMessage('');
//     }
//   };

//   console.log('here in outside')
//   useEffect(()=>{
//     setErrorMsg('')
//     setFieldErrorMsg([])
//     setErrorStatus(false)
//   },[])
//   console.log('here in outside12')
//   console.log("registerFormData>>>",registerFormData)

//   const handleFormSubmit = async () => {
//    setLoading(true);
//     setErrorMsg('')
//     setFieldErrorMsg([])
//     setErrorStatus(false)
     
//     console.log("registerFormData>>>",registerFormData)
// console.log('here in on submit???? <>>>')
//     // registerFormData.
//    //let signature="AorFkeqCC4827Q5slcmjx6ZxU15/j3GFhPMTt4xFYi/M7ahNIFHajyH6tQ+MeuTotH+qjwldqB8PVbCav4BbIH8Y5EJC+FLwIpWCpDXjhzAHZoRt6X2w84svIrIMssP+kEyk60PKfQX3CDXO5/YBlZM1zcrviiFlIejvH1EOCOg67MZwD/1W5/I5wNLq1X5C2pwgCKmAwlg5RLI9wfwUCIVJpBbDtx9x8PnPPYTe9GQkwcj6lndeKvRgqkr+SDNz3jcC99U5RcXjFVOe5bVcm+10+JY3SZfeThpJSWf/WaJV9c+VClj3dRJ+FZ5hKBmtWM35oA209h128aFT4NXOJXtLIr8GBvFCq9I1zr/n6UwN99+42Y18Jm/qR+Qq/5vbUPjHYt0GN2xb8fNtdmvsYMj0Wjz9TPbhEmNSEwbW3F3VXEu8bTUEU/aNJcnSeY8zawAwH12a6plEtOVKv3lgbPTwXHiGVVluRthc7TL3Qq5m11XUBvrmIPMkR5kvBPvgCaK7vvPW5d9nfwdLKfqT9Gg2tN6EwVFSCNvfhJjF6GSOmMwYuyHiWLmlpCupkiQeVHLDV7Q9ieVIs/fMh0oGAxeV57KsCbdlh7Zv4x5ra0R76EibGunZFa2Q6vsi5TVUSqvmaETu/UlzDHQOLhZsP/ID1W08MnnSVjvbHK5oWRI="

//     // let data={
//     //   'merchant_name': 'testing_add_merchant',
//     //   // 'business_website': 'abc.com',
//     //   'business_scope': 'abc',
//     //   'company_legal_name': 'abc',
//     //   'company_registration_no': '#1234',
//     //   'company_address': '12345',
//     //   'email_id': 'sasikumar@evvoiot.com',
//     //   'payment_mode': 'debit',
//     //   'processing_currency': 'SGD',
//     //   'settlement_currency': 'SGD',
//     //   'telephone_no': '7702083557',
//     //   'password': 'Sasi@123' 
//     // }
//    // console.log("EVVOPAY_PRIVATE_KEY>>",EVVOPAY_PRIVATE_KEY)

//   //  let data={
//   //   merchant_name: 'Malik',
//   //   business_website: 'abc.com',
//   //   business_scope: 'vcbcbc',
//   //   company_legal_name: 'ed',
//   //   company_address: 'ddd',
//   //   email_id: 'danieldsouza627@gmail.com',
//   //   payment_mode: 'debit',
//   //   processing_currency: 'debit',
//   //   settlement_currency: 'debit',
//   //   telephone_no: '342',
//   //   password: 'Daniel@123' 
//   //  }

//      let signature=generateSignature(registerFormData)
    
//     //console.log("signature>>",signature)
//     console.log("registerFormData>>",registerFormData)
//     let response=await apiRequest('post','add-merchant',signature,registerFormData)
//     console.log("registerFormDataresponse>>",response)
//     if(response.status==200 || response.status==201){
//       setErrorStatus(false)
//       setErrorMsg('')
//       setFieldErrorMsg([])
//        setTimeout(() => history.push(`${process.env.PUBLIC_URL}/auth-success`), 2000);
//     }
//     else if(response.status==500){
//       setLoading(false);
//       setErrorStatus(true)
//       setErrorMsg("Something went wrong,Kindly try again later")
//     }
//     else if (response.status==400){
//       setLoading(false);
//       setErrorStatus(false)
//       setErrorStatus(true)
//       console.log("response.data.data.data>>>",response.data.data)
//       if(Array.isArray(response.data.data)){
//         let errorMsgList=[]
//         response.data.data.map((data)=>{
//           errorMsgList.push(data.message)
//         })
//         setFieldErrorMsg(errorMsgList)
//         console.log("fieldErrorMsg>>>",errorMsgList)
//       }
//       else{
//         setErrorMsg(response.data.data.data)
//       }
//     }
//     else if (response.status==401){
//       setLoading(false);
//       setErrorStatus(true)
//       setErrorMsg(response.data.message)
//     }
//    else{
//     setLoading(false);
//     setErrorStatus(true)
//     setErrorMsg('Something went wrong,Kindly try again later')
//    }
    

//     // setTimeout(() => history.push(`${process.env.PUBLIC_URL}/auth-success`), 2000);


//   };
//   return (
//     <React.Fragment>
//       <Head title="Register" />
//       <PageContainer>
//         <Block className="nk-block-middle nk-auth-body  wide-xs">
//           <div className="brand-logo pb-4 text-center">
//             {/* <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
//               <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
//               <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
//             </Link> */}
//             <h1>Evvopay Admin</h1>
//           </div>
//           <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
//             <BlockHead>
//               <BlockContent>
//                 <BlockTitle tag="h4">Register</BlockTitle>
//                 <BlockDes>
//                   <p>Create New Evvopay user Account</p>
//                 </BlockDes>
//               </BlockContent>
//             </BlockHead>
//             <form className="is-alter row" onSubmit={handleSubmit(handleFormSubmit)}>


//                {/* <div className="form-group">
//                 <label className="form-label" htmlFor="name">
//                   Name
//                 </label>
//                 <div className="form-control-wrap">
//                   <input
//                     type="text"
//                     id="name"
//                     name="name"
//                     placeholder="Enter your name"
//                     ref={register({ required: true })}
//                     className="form-control-lg form-control"
//                   />
//                   {errors.name && <p className="invalid">This field is required</p>}
//                 </div>
//               </div> */}

         
//               <div className="form-group ">
//                 <label className="form-label" htmlFor="name">
//                   Merchant Name
//                 </label>
//                 <div className="form-control-wrap">
//                   <input
//                     type="text"
//                     id="merchant_name"
//                     name="merchant_name"
//                     placeholder="Enter your name"
//                     ref={register({ required: 'The field is required',
//                   //   pattern: {
//                   //     // value: /^[A-Za-z]+$/i,
//                   //     // value: /^[A-Za-z ]+$/i,
//                   //     value: /^[A-Za-z ]{3,50}$/i,
//                   //     message: 'Field value is invalid',
//                   //  }
                    
//                   })}
//                     className="form-control-lg form-control"
//                     // onChange={(e)=>setRegisterFormData(currValue=>({
//                     //   ...currValue,
//                     //   merchant_name:e.target.value
//                     // }))}
//                     onChange={handleMerchantChange}
//                     onBlur={validateMerchant}
//                   />
//                     {errorMessage && <p>{errorMessage}</p>}
//                   {/* {errors.merchant_name && <p className="invalid">{errors.merchant_name.message}</p>} */}
//                 </div>
//               </div>
              
//               <div className="form-group ">
//                 <label className="form-label" htmlFor="name">
//                   Phone No
//                 </label>
//                 <div className="form-control-wrap">
//                   <input
//                     type="text"
//                     id="phone_no"
//                     name="phone_no"
//                     placeholder="Enter your no"
//                     ref={register({ required: 'The field is required',
//                     pattern: {
//                       value: /^(0|[1-9]\d*)(\.\d+)?$/,
//                       message: 'Field value is invalid',
//                    }
                    
//                   })}
//                     className="form-control-lg form-control"
//                     onChange={(e)=>setRegisterFormData(currValue=>({
//                       ...currValue,
//                       telephone_no:e.target.value
//                     }))}
//                   />
//                   {errors.phone_no && <p className="invalid">{errors.phone_no.message}</p>}
//                 </div>
//               </div>
              
// {/* 
//               <div className="form-group">
//                 <label className="form-label" htmlFor="name">
//                   Business Website
//                 </label>
//                 <div className="form-control-wrap">
//                   <input
//                     type="text"
//                     id="website"
//                     name="website"
//                     placeholder="Enter your website"
                  
//                     ref={register({ required: 'The field is required',
            
                    
//                   })}
//                     className="form-control-lg form-control"
//                     onChange={(e)=>setRegisterFormData(currValue=>({
//                       ...currValue,
//                       business_website:e.target.value
//                     }))}
//                   />
               
//                   {errors.website && <p className="invalid">{errors.website.message}</p>}
//                 </div>
//               </div> */}

//               {/* <div className="form-group">
//                 <label className="form-label" htmlFor="name">
//                   Business scope
//                 </label>
//                 <div className="form-control-wrap">
//                   <input
//                     type="text"
//                     id="business_scope"
//                     name="business_scope"
//                     placeholder="Enter your scope of business"
//                     ref={register({ required: 'The field is required',
//                     pattern: {
//                       value: /^[A-Za-z]+$/i,
//                       message: 'Field value is invalid',
//                    }
                    
//                   })}
//                     className="form-control-lg form-control"

//                      onChange={(e)=>setRegisterFormData(currValue=>({
//                       ...currValue,
//                       business_scope:e.target.value
//                     }))}
//                   />
//                   {errors.business_scope && <p className="invalid">{errors.business_scope.message}</p>}
//                 </div>
//               </div> */}
//               {/* <div className="form-group">
//                 <label className="form-label" htmlFor="name">
//                   Company Name
//                 </label>
//                 <div className="form-control-wrap">
//                   <input
//                     type="text"
//                     id="company_legal_name"
//                     name="company_legal_name"
//                     placeholder="Enter your company name"
//                     ref={register({ required: true })}
//                     className="form-control-lg form-control"
//                     onChange={(e)=>setRegisterFormData(currValue=>({
//                       ...currValue,
//                       company_legal_name:e.target.value
//                     }))}
//                   />
//                   {errors.company_legal_name && <p className="invalid">This field is required</p>}
//                 </div>
//               </div> */}
//               {/* <div className="form-group">
//                 <label className="form-label" htmlFor="name">
//                   Company Registration No
//                 </label>
//                 <div className="form-control-wrap">
//                   <input
//                     type="text"
//                     id="company_registration_no"
//                     name="company_registration_no"
//                     placeholder="Enter company registration no"
//                     ref={register({ required: true })}
//                     className="form-control-lg form-control"
//                     onChange={(e)=>setRegisterFormData(currValue=>({
//                       ...currValue,
//                       company_registration_no:e.target.value
//                     }))}
//                   />
//                   {errors.company_registration_no && <p className="invalid">This field is required</p>}
//                 </div>
//               </div> */}
//               {/* <div className="form-group">
//                 <label className="form-label" htmlFor="name">
//                   Company Address
//                 </label>
//                 <div className="form-control-wrap">
//                   <input
//                     type="text"
//                     id="company_address"
//                     name="company_address"
//                     placeholder="Enter your company address"
//                     ref={register({ required: true })}
//                     className="form-control-lg form-control"
//                     onChange={(e)=>setRegisterFormData(currValue=>({
//                       ...currValue,
//                       company_address:e.target.value
//                     }))}
//                   />
//                   {errors.company_address && <p className="invalid">This field is required</p>}
//                 </div>
//               </div> */}
//               <div className="form-group ">
//                 <div className="form-label-group">
//                   <label className="form-label" htmlFor="default-01">
//                     Email
//                   </label>
//                 </div>
//                 <div className="form-control-wrap">
//                   <input
//                     type="text"
//                     bssize="lg"
//                     id="default-01"
//                     name="email_id"
//                     ref={register({ required: 'The field is required',
//                       pattern: {
//                         value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
//                         message: 'Please enter a valid email',
//                      }
                      
//                     })}
//                     className="form-control-lg form-control"
//                     placeholder="Enter your email"
//                     onChange={(e)=>setRegisterFormData(currValue=>({
//                       ...currValue,
//                       email_id:e.target.value
//                     }))}
//                   />
//                   {errors.email_id && <p className="invalid">{errors.email_id.message}</p>}
                  
//                 </div>
//               </div>
//               <div className="form-group ">
//                 <div className="form-label-group">
//                   <label className="form-label" htmlFor="password">
//                     Password
//                   </label>
//                 </div>
//                 <div className="form-control-wrap">
//                   <a
//                     href="#password"
//                     onClick={(ev) => {
//                       ev.preventDefault();
//                       setPassState(!passState);
//                     }}
//                     className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
//                   >
//                     <Icon name="eye" className="passcode-icon icon-show"></Icon>

//                     <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
//                   </a>
//                   <input
//                     type={passState ? "text" : "password"}
//                     id="password"
//                     name="password"
//                     // ref={register({ required: "This field is required" })}
//                     ref={register({ required: 'The field is required',
//                     // pattern: {
//                     //   value: /^(?=.*[0-9])(?=.*[!@#$%^&*.,])[a-zA-Z0-9!@#$%^&*.,]{6,16}$/,
//                     //   message: 'Password must contain atleast 6 characters,One Uppercase,One Lowercase,One Number and One Special Character',
//                     // }
//                     // validate:(value)=>
//                     // value==password.current
                    
//                   })}
//                     placeholder="Enter your password"
//                     className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
//                     // onChange={(e)=>setRegisterFormData(currValue=>({
//                     //   ...currValue,
//                     //   password:e.target.value
//                     // }))}
//                     onChange={handlePasswordChange}
//                     onBlur={validatePassword}
//                   />
//                   {passwordErrorMessage && <p>{passwordErrorMessage}</p>}
//                   {/* {errors.password && <p className="invalid">{errors.password.message}</p>} */}
//                 </div>
//               </div>
//               <div className="form-group ">
//                 <div className="form-label-group">
//                   <label className="form-label" htmlFor="password">
//                     Confirm Password
//                   </label>
//                 </div>
//                 <div className="form-control-wrap">
//                   {/* <a
//                     href="#password"
//                     onClick={(ev) => {
//                       ev.preventDefault();
//                       setPassState(!passState);
//                     }}
//                     className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
//                   >
//                     <Icon name="eye" className="passcode-icon icon-show"></Icon>

//                     <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
//                   </a> */}
//                   <input
//                     // type={passState ? "text" : "password"}
//                     type="text"
//                     id="confirmPassword"
//                     name="confirmPassword"
//                     // ref={register({ required: "This field is required" })}
//                     ref={register({
//                     // pattern: {
//                     //   value: /^(?=.*[0-9])(?=.*[!@#$%^&*.,])[a-zA-Z0-9!@#$%^&*.,]{6,16}$/,
//                     //   // message: 'Password must contain atleast 6 characters,One Uppercase,One Lowercase,One Number and One Special Character',
//                     //   message: 'Password must be string,should be alphanumeric',
//                     // }

//                     required:true,
//                     // pattern: {
//                     //   value: /^(?=.*[0-9])(?=.*[!@#$%^&*.,])[a-zA-Z0-9!@#$%^&*.,]{6,16}$/,
//                     //   // message: 'Password must contain atleast 6 characters,One Uppercase,One Lowercase,One Number and One Special Character',
//                     //   message: 'Password must be string,should be alphanumeric',
//                     // },
//                     // validate:(value)=>
//                     //   value==password.current
                    
//                   })}

//                     placeholder="Enter your password"
//                     className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
//                     // onChange={(e)=>setRegisterFormData(currValue=>({
//                     //   ...currValue,
//                     //   password:e.target.value
//                     // }))}
//                     onChange={handleConfirmPasswordChange}
//                     onBlur={validateConfirmPassword}
//                   />
//                   {/* {
//                     console.log("errors.confirmPassword.type>>>",registerFormData.password)
//                   } */}
//                   {confimPasswordErrorMessage && <p>{confimPasswordErrorMessage}</p>}

//                   {/* {errors.confirmPassword && errors.confirmPassword.type==="required" && <p className="invalid">This field is required</p>}
//                   {errors.confirmPassword && errors.confirmPassword.type==="pattern" && <p className="invalid">Password must be string,should be alphanumeric</p>}
//                   {errors.confirmPassword && errors.confirmPassword.type==="validate" && <p className="invalid">Confirm Password do not match</p>} */}
//                 </div>
//               </div>
//               {/* <div className="form-group">
//                 <label className="form-label" htmlFor="name">
//                   Payment Mode
//                 </label>
//                 <div className="form-control-wrap">
//                   <input
//                     type="text"
//                     id="payment_mode"
//                     name="payment_mode"
//                     placeholder="Enter payment mode"
//                     ref={register({ required: 'The field is required',
//                     pattern: {
//                       value: /^[A-Za-z]+$/i,
//                       message: 'Field value is invalid',
//                    }
//                   })}
//                     className="form-control-lg form-control"
//                     onChange={(e)=>setRegisterFormData(currValue=>({
//                       ...currValue,
//                       payment_mode:e.target.value
//                     }))}
//                   />
//                   {errors.payment_mode && <p className="invalid">{errors.payment_mode.message}</p>}
//                 </div>
//               </div> */}
//               {/* <div className="form-group">
//                 <label className="form-label" htmlFor="name">
//                   Processing Currency
//                 </label>
//                 <div className="form-control-wrap">
//                   <input
//                     type="text"
//                     id="processing_currency"
//                     name="processing_currency"
//                     placeholder="Enter processing currency"
//                     ref={register({ required: 'The field is required',
//                     pattern: {
//                       value: /^[A-Za-z]+$/i,
//                       message: 'Field value is invalid',
//                    }
//                   })}
//                     className="form-control-lg form-control"
//                     onChange={(e)=>setRegisterFormData(currValue=>({
//                       ...currValue,
//                       processing_currency:e.target.value
//                     }))}
//                   />
//                   {errors.processing_currency && <p className="invalid">{errors.processing_currency.message}</p>}
//                 </div>
//               </div> */}

//               {/* <div className="form-group">
//                 <label className="form-label" htmlFor="name">
//                   Settlement Currency
//                 </label>
//                 <div className="form-control-wrap">
//                   <input
//                     type="text"
//                     id="settlement_currency"
//                     name="settlement_currency"
//                     placeholder="Enter processing currency"
//                     ref={register({ required: 'The field is required',
//                     pattern: {
//                       value: /^[A-Za-z]+$/i,
//                       message: 'Field value is invalid',
//                    }
//                   })}
//                     className="form-control-lg form-control"
//                     onChange={(e)=>setRegisterFormData(currValue=>({
//                       ...currValue,
//                       settlement_currency:e.target.value
//                     }))}
//                   />
//                   {errors.settlement_currency && <p className="invalid">{errors.settlement_currency.message}</p>}
//                 </div>
//               </div> */}

              
//               {
//                 errorStatus==true?
//                  errorMsg!=''?<span style={{color:'red'}} className="invalid">{`* `+errorMsg}</span>
//                  :null
//                 :null
//               }
//               {
//                 fieldErrorMsg.length>0?
//                 fieldErrorMsg.map((msg,key)=>{
//                   return <div  key ={key} style={{color:'red'}}><span  className="invalid">{`* `+msg}</span></div>
//                 })
//                 :null
//               }
//               {
//                 console.log("fieldErrorMsg>>>>",fieldErrorMsg.length)
//               }
//               <div className="form-group">
//                 <Button  style={errorStatus?{marginTop:'10px'}:{}} type="submit" color="primary" size="lg" className="btn-block">
//                   {loading ? <Spinner size="sm" color="light" /> : "Register"}
//                 </Button>
//               </div>
//             </form>
//             <div className="form-note-s2 text-center pt-4">
//               {" "}
//               Already have an account?{" "}
//               <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
//                 <strong>Sign in instead</strong>
//               </Link>
//             </div>
//             {/* <div className="text-center pt-4 pb-3">
//               <h6 className="overline-title overline-title-sap">
//                 <span>OR</span>
//               </h6>
//             </div> */}
//             {/* <ul className="nav justify-center gx-8">
//               <li className="nav-item">
//                 <a
//                   className="nav-link"
//                   href="#socials"
//                   onClick={(ev) => {
//                     ev.preventDefault();
//                   }}
//                 >
//                   Facebook
//                 </a>
//               </li>
//               <li className="nav-item">
//                 <a
//                   className="nav-link"
//                   href="#socials"
//                   onClick={(ev) => {
//                     ev.preventDefault();
//                   }}
//                 >
//                   Google
//                 </a>
//               </li>
//             </ul> */}
//           </PreviewCard>
//         </Block>
//         <AuthFooter />
//       </PageContainer>
//     </React.Fragment>
//   );
// };
// export default Register;
