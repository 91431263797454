// import crypto from "crypto";
// import * as crypto from "crypto"
// import { sign } from "crypto" 
// import sha256 from 'crypto-js/sha256';
// import hmacSHA512 from 'crypto-js/hmac-sha256';
// import Base64 from 'crypto-js/enc-base64';

//  import {Buffer} from 'buffer';


import { JSEncrypt } from "jsencrypt";
import CryptoJS from "crypto-js";
import { ALGORITHM_TYPE, EVVOPAY_PRIVATE_KEY, EVVOPAY_PUBLIC_KEY } from "../constants";

 const sign = new JSEncrypt();
 const verify = new JSEncrypt();
 const encrypt = new JSEncrypt();
 const decrypt = new JSEncrypt();

export  const generateSignature=(payload)=>{
  
    const signStr = getVerifiableData(payload);
    sign.setPrivateKey(EVVOPAY_PRIVATE_KEY);
    return sign.sign(signStr, CryptoJS.SHA256, ALGORITHM_TYPE);
}

export  const verifySignature=(payload,signature)=>{
    
    const signStr = getVerifiableData(payload);
    verify.setPublicKey(EVVOPAY_PUBLIC_KEY);
    return verify.verify(signStr, signature, CryptoJS.SHA256);
}
export const getVerifiableData = (payload)=> {
    let filteredKeys;
    let keys = Object.keys(payload);
   
    filteredKeys = keys.filter(
        (key) => payload[key] != null && payload[key].toString().trim() != ""
    );

  
    return filteredKeys
        .sort()
        .map((key) => `${key}=${payload[key]}`)
        .join("&");


};

export  const encryptToken=(payload)=>{
  
    encrypt.setPublicKey(EVVOPAY_PUBLIC_KEY);
    return  encrypt.encrypt(payload);
}

export  const decryptToken=(payload)=>{
  
    decrypt.setPrivateKey(EVVOPAY_PRIVATE_KEY);
    return decrypt.decrypt(payload);
  
}