import React from "react";

const Loader = () => (
  <div className="loaderWrap">
    <div className="loderImg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        style={{
          margin: "auto",
          background: "rgb(255, 255, 255)",
          display: "block",
          shapeRendering: "auto"
        }}
        width="94px"
        height="94px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <g transform="rotate(0 50 50)">
          <rect
            x="47.5"
            y="22.5"
            rx="2.5"
            ry="3.6"
            width="5"
            height="15"
            fill="#6875f6"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.8571428571428571s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(51.42857142857143 50 50)">
          <rect
            x="47.5"
            y="22.5"
            rx="2.5"
            ry="3.6"
            width="5"
            height="15"
            fill="#6875f6"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.7142857142857143s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(102.85714285714286 50 50)">
          <rect
            x="47.5"
            y="22.5"
            rx="2.5"
            ry="3.6"
            width="5"
            height="15"
            fill="#6875f6"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.5714285714285714s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(154.28571428571428 50 50)">
          <rect
            x="47.5"
            y="22.5"
            rx="2.5"
            ry="3.6"
            width="5"
            height="15"
            fill="#6875f6"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.42857142857142855s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(205.71428571428572 50 50)">
          <rect
            x="47.5"
            y="22.5"
            rx="2.5"
            ry="3.6"
            width="5"
            height="15"
            fill="#6875f6"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.2857142857142857s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(257.14285714285717 50 50)">
          <rect
            x="47.5"
            y="22.5"
            rx="2.5"
            ry="3.6"
            width="5"
            height="15"
            fill="#6875f6"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.14285714285714285s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
        <g transform="rotate(308.57142857142856 50 50)">
          <rect
            x="47.5"
            y="22.5"
            rx="2.5"
            ry="3.6"
            width="5"
            height="15"
            fill="#6875f6"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="0s"
              repeatCount="indefinite"
            />
          </rect>
        </g>
      </svg>
    </div>
    <h5 className="text-center" >Processing</h5>
  </div>
);

export default Loader;
